import React from 'react';

import { Box, Flex, Text } from '@primer/components'

import { useProduct } from '../hooks/useProduct'

const circleStyle = {borderRadius: 50, padding: 5, background: 'white'}

const clients = [
  {src: '/brand-chrome.png', name: 'Chrome'},
  {src: '/brand-pytorch.svg', name: 'PyTorch', style: {height: '100px'}},
  {src: '/brand-sentry.svg', name: 'Sentry'},
  {src: '/brand-tensorflow.svg', name: 'Tensorflow'},
  {src: '/brand-firefox.svg', name: 'Firefox', style: {height: '100px'}},
]

export default function(props) {
  const productDetails = useProduct()

  return (
      <Box className="container-lg text-black" id="clients">
        <Box className="m-md-0 ml-2 mr-2 ml-md-4 mr-md-4 mt-8">
          <Text as="p" className="text-center f1">We've improved thousands of codebases including</Text>
          <Flex as="ul" className="list-style-none pt-6" justifyContent='space-between'>
            { clients.map((item, i) => (
              <li className="text-center">
                <img alt={item.name} title={item.name} src={item.src} style={{...item.style, maxHeight: '75px'}} />
                <Text as="p" className="f3">{item.name}</Text>
              </li>
            ))}
          </Flex>
        </Box>
      </Box>
  )
}
