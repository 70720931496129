import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import BaseWebsite from './BaseWebsite'
import Loading from '../components/Loading'
import { useCorpus, useCorpusRoutes } from '../hooks/useCorpus'

import LandingPage from '../views/python/LandingPage'
const AbstractRule = React.lazy(() => import('../views/AbstractRule'))
const BestPracticeListBugs = React.lazy(() => import('../views/python/BestPracticeListBugs'))
const BestPracticeListTesting = React.lazy(() => import('../views/python/BestPracticeListTesting'))
const BestPracticeListMaintainability = React.lazy(() => import('../views/python/BestPracticeListMaintainability'))
const name = "python"


export default function App(props) {
  const corpus = useCorpus(name)
  const routes = useCorpusRoutes(name)

  const buildUrl = url => props.match.url + url

  return (
    <BaseWebsite name={name} appPath={props.match.url}>
      <Switch>
        <Route exact path={buildUrl("/")} component={LandingPage} />
      </Switch>
      <React.Suspense fallback={<Loading /> } >
        <Switch>
          {
            Object.entries(routes).map(([code, route]) =>
              <Route
                key={route}
                exact
                path={buildUrl(route)}
                render={props => <AbstractRule {...props} {...corpus[code]} />}
              />
            )
          }
          <Route exact path={buildUrl("/best-practice/maintainability")} component={BestPracticeListMaintainability} />
          <Route exact path={buildUrl("/best-practice/bugs")} component={BestPracticeListBugs} />
          <Route exact path={buildUrl("/best-practice/testing")} component={BestPracticeListTesting} />
        </Switch>
      </React.Suspense>
    </BaseWebsite>
  );
}