import React from 'react'

import { Text } from '@primer/components'

import Snippet from '../../components/Snippet'
import Code from '../../components/Code'
import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = `dict((item, get_value(item)) for item in items)`

export const exampleAfter = `{item: get_value(item) item item in items}`

export const code = 'RewriteToComprehension'

export const title = 'Use list and dict comprehension instead'

export const label = 'Use comprehension'

export const wordCode = 'use-comprehension'

export const furtherReading = [

]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Using list and dict comprehension is simpler and computationally quicker than calling <Code>list()</Code> and <Code>dict()</Code>.
    </Text>
  )
}

const codeExample = (
`values = {item: get_value(item) item item in items}

values = dict([item, get_value(item)] for item in items)

values = dict((item, get_value(item)) for item in items)

values = dict([(item, get_value(item)) for item in items])`
)

const codeExampleSet = (
`items = [1, 2, 3]

one = {item: None for item in items}  # evaluates to dict

two = {item for item in items}  # evaluates to set

empty = []

three = {item: None for item in empty}  # evaluates to dict

four = {item for item in empty}  # evaluates to dict OH NO!
`)


export const explanation = (
  <>
    <Text as={'p'}>Comprehensions are more efficient than calling <Code>dict()</Code> and <Code>list()</Code>.</Text>
    <Text as={'p'}>Given that these are completely equivalent:</Text>
    <Snippet value={codeExample} />
    <Text as={'p'}>Why not choose the easiest to both read, write, and quickest to execute (the first one)?</Text>
    <Text as={'p'}>Do not follow this advice with set comprehension though, as when set comprehension is performed on an empty iterable it returns a <Code>dict</Code> because Python cannot differentiate set comprehension and dict comprehension when working with an empty iterable:</Text>
    <Snippet value={codeExampleSet} />
    <Text as={'p'}>Notice that <Code>four</Code> evaluated to an empty dict, which was unwanted behaviour.</Text>
  </>
)


export {category, impact}