import React from 'react'

import { useFirebaseAuth } from '../hooks/useFirebaseAuth'
import { useOctokit } from '../hooks/useOctokit'
import { Button } from '@primer/components'

import FirebaseAuth from 'react-firebaseui/FirebaseAuth';


export default function GrantPublicAccessButton(props) {
  const firebaseAuth = useFirebaseAuth()
  const [octokit, setAuth] = useOctokit()
  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      {
        provider: 'github.com',
        scopes: ['public_repo',],
        fullLabel: props.label,
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult, url) => {
        setAuth({
          accessToken: authResult.credential.accessToken,
          username: authResult.additionalUserInfo.username,
          permission: 'public'
        })
        props.onLogin && props.onLogin()
        return false
      }
    }
  }
  return <Button  className={`${props.className} p-0`} as={FirebaseAuth} uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
}

GrantPublicAccessButton.defaultProps = {
  label: 'Save changes'
}
