import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'

export const exampleTitle = 'urls.py'

export const exampleBefore = (
`from django.conf import settings
from django.contrib import admin

\t
urlpatterns = [...]
\t
if settings.DEBUG:
    # not exposing admin to prod so it can't be hacked
    urlpatterns.append(path('admin/', admin.site.urls))`
)

export const exampleAfter = (
`from django.conf import settings
from django.contrib import admin

\t
urlpatterns = [...]
\t
if settings.FEATURE_ADMIN_ENABLED:
    # not exposing admin to prod so it can't be hacked
    urlpatterns.append(path('admin/', admin.site.urls))`
)

export const code = 'C4018'

export const title = "Using settings.DEBUG as a feature flag hinders testing"

export const label = "Using DEBUG as feature flag"

export const wordCode = 'checking-settings-debug'

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/3.2/ref/settings/#std:setting-DEBUG',
    text: 'Django DEBUG documentation',
  },
  {
    href: 'https://dev.to/djangodoctor/don-t-abuse-django-s-debug-setting-1flc',
    text: 'Our blog post on the topic',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Your code is harder to test in isolation because the value of <code style={{fontSize: 'inherit'}}>settings.DEBUG</code> affects other Django behaviours including error handling. Consider using a feature flag instead.  
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'} className="mb-3"><code style={{fontSize: 'inherit'}}>settings.DEBUG</code> is for switching whether Django should run in debug mode. This is a switch to control the behavior of the <i>framework</i>, not your code. Using this for also controlling the behaviour of the application code you built on top of the framework makes the codebase harder to maintain and test.</Text>
    <Text as={'p'} className="mb-3">For a concrete example, <code style={{fontSize: 'inherit'}}>settings.DEBUG = True</code> shows detailed error pages for use during local development, while <code style={{fontSize: 'inherit'}}>settings.DEBUG = False</code> can result in the error instead being emailed to <code style={{fontSize: 'inherit'}}>settings.ADMINS</code>. Do you want to cause either of those behavioural changes while testing your code? Probably not, as this is inconvenient and also goes against the <a href="https://en.wikipedia.org/wiki/Principle_of_least_astonishment">principle of least surprise</a>.</Text>
    <Text as={'p'} className="mb-3"><a href="https://12factor.net">Twelve factor App</a> suggests aiming for <a href="https://12factor.net/dev-prod-parity">dev/prod parity</a>. Using <code style={{fontSize: 'inherit'}}>settings.DEBUG</code> to control which block of code your application runs prevents dev/prod parity because most devs will not test or run their local environment with <code style={{fontSize: 'inherit'}}>DEBUG = False</code>. To solve this instead consider adding a feature flag specifically for this one check, which you can switch easily during unit tests and with none of the other side effects inherent with <code style={{fontSize: 'inherit'}}>settings.DEBUG</code>.</Text>
  </>
)



export const ogImage = "/og-image/C4018.png"
export {category, impact}