import React from 'react'

import { Text } from '@primer/components'

import Snippet from '../../components/Snippet'
import Code from '../../components/Code'
import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = (
`def foo_bar() -> Union[int, None]:
    # sometimes return int other times None`
)

export const exampleAfter = (
`def foo_bar() -> Optional[int]:
    # sometimes return int other times None`
)

export const code = 'ReplaceUnionWithOptional'

export const title = 'Use Optional instead of Union'

export const label = title

export const wordCode = 'use-optional'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/typing.html#typing.Optional',
    text: 'Python documentation on Optional',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      This could be simplified by using <Code>typing.Optional</Code>
    </Text>
  )
}

const codeExample = (
`def foo_bar() -> Optional[int]:
    # sometimes return int other times None

def foo_bar() -> Union[int, None]:
    # sometimes return int other times None`
)


export const explanation = (
  <>
    <Text as={'p'}>Core to the philosophy of Python is it's written to be read. <Code>Optional</Code> does a better job at communicates to the reader that the value is optional. Given that these are completely equivalent:</Text>
    <Snippet value={codeExample} />
    <Text as={'p'}>Why not choose the easiest to both read and write (the first one)</Text>
  </>
)


export {category, impact}