import React from 'react';
import { TextInput, Text, ButtonPrimary, ButtonOutline, ButtonGroup, Box, Popover } from '@primer/components'

const url = 'https://doctor.us7.list-manage.com/subscribe/post?u=850e72b96d202c7fcf9a47a54&amp;id=98c4c39461'


export default function Subscribe(props) {
  const [subscribeSubmitted, setSubscribeSubmitted] = React.useState(props.isSubscribed)
  const form = React.useRef(undefined)

  function handleSubmit(event) {
    if (form.current.checkValidity()) {
      props.handleSubscribed()
      setTimeout(() => setSubscribeSubmitted(true), 0)
    }
  }

  const Button = props.isPrimary ? ButtonPrimary : ButtonOutline

  return (
    <Box id="mc_embed_signup" display='inline-block'>
      <form ref={form} action={url} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
        <div id="mc_embed_signup_scroll">
          { subscribeSubmitted
            ?
              <Text>Subscribed</Text>
            : (
                <ButtonGroup display='inline-block' my={2} className="m-0">
                  <TextInput
                    type="email"
                    name="EMAIL"
                    className="email bg-white"
                    id="mce-EMAIL"
                    placeholder="email address"
                    required
                    paddingTop="5px"
                    paddingBottom="5px"
                  />
                  <Button as="button" type="submit" onClick={handleSubmit}>Subscribe</Button>
                </ButtonGroup>
              )
          }
          <div style={{position: 'absolute', left: '-5000px', bottom: '100px'}} aria-hidden="true">
            <TextInput name="b_850e72b96d202c7fcf9a47a54_98c4c39461" />
            <div className="clear">
              <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
            </div>
          </div>
        </div>
      </form>
    </Box>
  )
}