import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_SECURITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'settings.py'

export const exampleBefore = (
`MIDDLEWARE = [
    'django.middleware.common.CommonMiddleware',
    'django.contrib.sessions.middleware.SessionMiddleware',
    ...
]`
)

export const exampleAfter = (
`MIDDLEWARE = [
    'django.middleware.security.SecurityMiddleware',
    'django.middleware.common.CommonMiddleware',
    'django.contrib.sessions.middleware.SessionMiddleware',
    ...
]`
)

export const code = 'C4007'

export const title = 'Security middleware not activated'

export const label = title

export const wordCode = 'missing-security-middleware'

export const furtherReading = [
  {
    href: 'https://dev.to/djangodoctor/hacking-django-websites-30f',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/middleware/#module-django.middleware.security',
    text: 'Django security documentation',
  },
  {
    href: 'https://en.wikipedia.org/wiki/Cross-site_scripting',
    text: 'Wikipedia page for XSS attack',
  },
  {
    href: 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Content-Type-Options',
    text: 'Mozilla X-Content-Type-Options documentation',
  },
  {
    href: 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-XSS-Protection',
    text: 'Mozilla X-XSS-Protection documentation',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Your website is vulnerable to a number of common hacker attacks because <code style={{fontSize: 'inherit'}}>MIDDLEWARE</code> setting is missing <code style={{fontSize: 'inherit'}}>django.middleware.security.SecurityMiddleware</code>.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'} className="mb-3">Django's SecurityMiddleware performs a suite of security checks and enhancements. By not including this middleware the following security features are not enabled:</Text>
    <ul className="bullet-list-blue">
      <li className="mb-3"><strong><a href="https://docs.djangoproject.com/en/3.1/ref/middleware/#http-strict-transport-security" target="_blank" rel="noopener noreferrer">HTTP Strict Transport Security</a></strong> SecurityMiddleware can tell the browser to always use HTTPS for your website (avoiding your website doing a HTTP to HTTPS redirect every time).</li>
      <li className="mb-3"><strong><a href="https://docs.djangoproject.com/en/3.1/ref/middleware/#referrer-policy" target="_blank" rel="noopener noreferrer">Referrer Policy</a></strong> SecurityMiddleware sets the referer policy header, which impacts user privacy.</li>
      <li className="mb-3"><strong><a href="https://docs.djangoproject.com/en/3.1/ref/middleware/#x-content-type-options-nosniff" target="_blank" rel="noopener noreferrer">X-Content-Type-Options</a></strong> SecurityMiddleware sets the <code style={{fontSize: 'inherit'}}>X-Content-Type-Options</code> header to <code style={{fontSize: 'inherit'}}>nosniff</code> to prevent hackers from tricking your website into executing a malicious javascript file that they uploaded.</li>
      <li className="mb-3"><strong><a href="https://docs.djangoproject.com/en/3.1/ref/middleware/#x-xss-protection-1-mode-block" target="_blank" rel="noopener noreferrer">X-XSS-Protection</a></strong> SecurityMiddleware sets the <code style={{fontSize: 'inherit'}}>X-XSS-Protection</code> header to <code style={{fontSize: 'inherit'}}>1; mode=block</code> to enable the browser's built-in XSS protection. This fearure is present on Internet Explorer, Chrome and Safari.</li>
      <li className="mb-3"><strong><a href="https://docs.djangoproject.com/en/3.1/ref/middleware/#ssl-redirect" target="_blank" rel="noopener noreferrer">SSL Redirect</a></strong> SecurityMiddleware can redirect HTTP connections to HTTPS if <code style={{fontSize: 'inherit'}}>SECURE_SSL_REDIRECT</code> is set to <code style={{fontSize: 'inherit'}}>True</code>.</li>
    </ul>
  </>
)

export const ogImage = "/og-image/C4007.png"
export {category, impact}