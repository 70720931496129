import React from 'react';

export default function Wave(props) {
  const image1 = props.upsideDown ? '/wave1-bottom.png' : '/wave1.png'
  const image2 = props.upsideDown ? '/wave2-bottom.png' : '/wave2.png'
  const image3 = props.upsideDown ? '/wave3-bottom.png' : '/wave3.png'
  const style = props.upsideDown ? {top: '-2px', marginTop: 0} : {}
  const waveStyle = props.upsideDown ? {backgroundPosition: '0 top', transformOrigin: 'center top', marginTop: 0} : {backgroundPosition: '0 bottom', transformOrigin: 'center bottom'}



  return (
    <div className="wave-effect wave-anim" style={style}>
      <div className="waves-shape shape-one">
        <div className="wave wave-one" style={{ backgroundImage: `url(${image1})`, ...waveStyle}}></div>
      </div>
      <div className="waves-shape shape-two">
        <div className="wave wave-two" style={{ backgroundImage: `url(${image2})`, ...waveStyle}}></div>
      </div>
      <div className="waves-shape shape-three">
        <div className="wave wave-three" style={{ backgroundImage: `url(${image3})`, ...waveStyle}}></div>
      </div>
    </div>
  )
}