import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_LOW as impact } from '../../constants'

export const exampleTitle = 'models.py'

export const exampleBefore = (
`class CommentModel(models.Model):
    class Meta:
        ordering = ('body',)

    body = models.TextField()`
)

export const exampleAfter = exampleBefore

export const code = 'C3002'

export const lineNumber = 5  // which line to show the comment on

export const title = 'Model method order'

export const label = title

export const wordCode = 'model-method-order'

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/dev/internals/contributing/writing-code/coding-style/#model-style',
    text: 'Django Model style documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      The <a href='https://docs.djangoproject.com/en/dev/internals/contributing/writing-code/coding-style/#model-style'>Django coding style</a> suggests a standard order of model attributes. Increasing standardisation and predictability of your code style helps other developers read your code.
    </Text>
  )
}


export const explanation = (
  <>
    <Text as={'p'}>The Django Coding Style suggests the following order of inner classes, methods and attributes:</Text>
    <ol style={{marginLeft: '25px', marginBottom: '15px'}}>
      <li>Field choice tuples</li>
      <li>Database fields</li>
      <li>Custom manager attributes</li>
      <li>class Meta</li>
      <li>def __str__()</li>
      <li>def save()</li>
      <li>def delete</li>
      <li>def get_absolute_url()</li>
      <li>Any custom methods</li>
    </ol>
    <Text as={'p'}>This standardisation is very useful when Python should be written to be read.</Text>
  </>
)


export {category, impact}