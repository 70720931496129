import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Django from './Django'
import Python from './Python'
import Common from './Common'
import ScrollToTop from '../components/ScrollToTop'
import pythonRoutes from '../corpus/python/routes'
import djangoRoutes from '../corpus/django/routes'

const PYTHON_PREFIX = "/features/python"
const DJANGO_PREFIX = "/features/django"


// "features" as features is reserved github name, so no repo org can be that name, so does not clash with repo link
export default function App() {
  return (
    <Router>  
      <ScrollToTop />      
      <Switch>
        { Object.entries(djangoRoutes).map(([code, route]) => <Redirect from={`/advice/${code}`} to={`${DJANGO_PREFIX}${route}`} /> ) }
        { Object.entries(pythonRoutes).map(([code, route]) => <Redirect from={`/advice/${code}`} to={`${PYTHON_PREFIX}${route}`} /> ) }
        <Route path={PYTHON_PREFIX} component={Python} />
        <Route path={DJANGO_PREFIX} component={Django} />
        <Route path="/" component={Common} />
      </Switch>
    </Router>
  );
}