import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_SECURITY as category } from '../../constants'
import { IMPACT_LOW as impact } from '../../constants'

export const exampleTitle = 'settings.py'

export const exampleBefore = `SECURE_HSTS_PRELOAD = False`

export const exampleAfter = `SECURE_HSTS_PRELOAD = True`

export const code = 'C4017'

export const title = 'Use HSTS browser preload'

export const label = title

export const wordCode = 'missing-hsts-preload'

export const furtherReading = [
  {
    href: 'https://dev.to/djangodoctor/hacking-django-websites-man-in-the-middle-attack-3l4',
    text: 'Out blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/middleware/#http-strict-transport-security',
    text: 'Django HSTS documentation',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/middleware/#module-django.middleware.security',
    text: 'Django security documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Your website must set <code style={{fontSize: 'inherit'}}>SECURE_HSTS_PRELOAD</code> in order to be submitted to Chrome's list of sites that are hardcoded as being HTTPS only.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'} className="mb-3"><code style={{fontSize: 'inherit'}}>SecurityMidddleware</code> adds <code style={{fontSize: 'inherit'}}>preload</code> to the HSTS header when <code style={{fontSize: 'inherit'}}>SECURE_HSTS_PRELOAD = True</code> to facilitate this.</Text>
    <Text as={'p'} className="mb-3">Browsers that use the HSTS preload list will perform HTTPS requests without your website first returning a response with a HSTS header.</Text>
  </>
)


export {category, impact}