import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_LOW as impact } from '../../constants'


export const title = 'Tall Model'

export const label = title

export const wordCode = 'tall-model'

export const code = 'C2001'

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      A taller Model with many fields may be more difficult to maintain than a shorter Model with fewer fields.
    </Text>
  )
}


export const explanation = (
  <>
    <Text as={'p'}>There is a limit to how many items a developer can consider when forming a mental model of source code.</Text>
    <Text as={'p'}>It may be time to split the Model into multiple Models with a OneToOne relationship.</Text>
  </>
)

export {category, impact}