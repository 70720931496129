export default {
  NoInheritFromObject: '/best-practice/avoid-inheriting-from-object',
  AwaitAsyncCall: '/best-practice/avoid-calling-coroutine-without-await',
  ChainedInstanceCheck: '/best-practice/avoid-chaining-isinstance-calls',
  ChainedSubclassCheck: '/best-practice/avoid-chaining-subclass-calls',
  ClsInClassMethod: '/best-practice/classmethod-missing-cls',
  ComparePrimitivesByEqual: '/best-practice/avoid-identity-checking-privatives',
  CompareSingletonPrimitivesByIs: '/best-practice/avoid-equality-checking-boolean-none',
  ExplicitFrozenDataclass: '/best-practice/frozen-dataclass-immutable',
  NoDeprecatedTestCaseAliases: '/best-practice/avoid-deprecated-testcase-assertions',
  NoAssertTrueForComparison: '/best-practice/avoid-misusing-unittest-assert-true',
  NoMissingComma: '/best-practice/avoid-missing-comma',
  NoNamedtuple: '/best-practice/avoid-named-tuple-use-dataclass',
  NoRedundantArgumentsSuper: '/best-practice/avoid-redundant-super-arguments',
  NoRedundantFString: '/best-practice/avoid-redundant-f-string',
  NoRedundantLambda: '/best-practice/avoid-redundant-lambda',
  NoRedundantListComprehension: '/best-practice/avoid-redundant-list-comprehension',
  NoStaticIfCondition: '/best-practice/avoid-if-statements-with-static-conditions',
  NoStringTypeAnnotation: '/best-practice/avoid-string-type-annotation',
  ReplaceUnionWithOptional: '/best-practice/avoid-union-use-optional',
  RewriteToComprehension: '/best-practice/use-list-set-dict-comprehension',
  RewriteToLiteral: '/best-practice/use-list-set-dict-literals',
  UseAssertIn: '/best-practice/use-assert-in-for-inclusion-checks',
  UseAssertIsNotNone: '/best-practice/use-assert-is-not-none',
  UseFString: '/best-practice/use-f-string',
  UseIsNoneOnOptional: '/best-practice/compare-optional-against-none',
  ChainedExceptionHandler: '/best-practice/handling-multiple-exception-types-incorrectly',
  NoBreakpoint: '/best-practice/do-not-commit-pdb',
  ProbablyMeantTuple: '/best-practice/tuple-missing-trailing-comma',
  ProbablyMeantNotTuple: '/best-practice/tuple-accidental-trailing-comma',
  UseJsonLoad: '/best-practice/read-json-file-json-load',
  UseJsonDump: '/best-practice/write-json-file-json-dump',
  NoUnclosedFile: '/best-practice/close-files-context-manager',
  NoUnsupportedFileOperation: '/best-practice/file-open-operation-modes',
  NoOperationAfterClose: '/best-practice/file-read-write-after-close',
  UseFileEncodingRead: '/best-practice/file-open-read-encoding',
  UseFileEncodingWrite: '/best-practice/file-open-write-encoding',
  NoIncorrectFileEncodingRead: '/best-practice/avoid-incorrect-encoding-file-open-UnicodeDecodeError',
  NoDuplicateTestNames: '/best-practice/avoid-duplicate-unit-test-names',
  ProbablyMeantFstring: '/best-practice/avoid-f-string-missing-prefix-typo',
}
