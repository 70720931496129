import React from 'react';
import { Box, Text, StyledOcticon } from '@primer/components'

import { CommentDiscussion } from '@primer/octicons-react'


export default function() {

  return (
    <Box>
      <Box className="container-xl pt-md-8 pb-md-8 pt-4 pb-4">
        <Box alignItems="center" className="text-center mb-4 mb-md-0 col-md-8 offset-md-2">
          <Box className="mb-10">
            <Text as="p" className="f1">It caught malformed test assertions that were incorrect. The automated codebase audit tool is so handy!</Text>
            <Text className="f3">Jared Lockhart, Senior Software Engineer at <b>Mozilla</b></Text>
          </Box>

          <Box className="mb-10">
            <Text as="p" className="f1">Extremely positive. Suggested useful changes, giving our senior developers time back.</Text>
            <Text className="f3">Jon Atkinson, Technical Director at <b>Giant</b></Text>
          </Box>

          <Box>
            <Text as="p" className="f1">Uncovered some bugs in the tests - thanks a lot!</Text>
            <Text className="f3">Patrick von Platen, Research Engineer at <b>Hugging Face</b></Text>
          </Box>

        </Box>
      </Box>
    </Box>
  );
}



