import React from 'react';

import { useScrollPercentage } from 'react-scroll-percentage'
import useAnalytics from '../hooks/useAnalytics'


export default function ({children}) {
  const analytics = useAnalytics()
  const [scrollRef, scrollPercentage] = useScrollPercentage();
  if (scrollPercentage >= 0.75) {
    analytics.trackOnce('OI23EJVL')
  } else if (scrollPercentage >= 0.50) {
    analytics.trackOnce('INU0TXUO')
  } else if (scrollPercentage >= 0.25) {
    analytics.trackOnce('L5JJ467X')
  }

  return (
    <div ref={scrollRef}>
      { children }
    </div>
  );
}

