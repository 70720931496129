import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import { CATEGORY_TESTS as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'tests.py'

export const exampleBefore = (
`class TestFeature(unittest.TestCase):
    def test_feature(self):
        self.assertEquals(int('1'), 1)`
)

export const exampleAfter = (
`class TestFeature(unittest.TestCase):
    def test_feature(self):
        self.assertEqual(int('1'), 1)`
)

export const code = 'NoDeprecatedTestCaseAliases'

export const ogImage = `/og-image/${code}.png`

export const title = 'Avoid deprecated TestCase method aliases'

export const label = title

export const wordCode = 'avoid-deprecated-testcase-aliases'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/unittest.html#deprecated-aliases',
    text: 'Python documentation on deprecated TestCase aliases'
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Don't use deprecated <Code>TestCase</Code> assertions like <Code>self.assertEquals</Code>. Use their modern counterpart instead like <Code>self.assertEqual</Code>.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as="p">For historical reasons, some <Code>TestCase</Code> methods had one or more aliases that are now deprecated.</Text>
    <Text as="p">Use the standardised assertions so the code is simpler, easier to read, and more future-proof (because an implication of deprecation is it will eventually be removed).</Text>

    <table className="d-table w-100 mt-6 mb-6" style={{columnGap: '30px', width: '100%'}}>
      <thead className="text-left">
        <tr>
          <th className="text-right pr-4">Deprecated</th>
          <th>Standardised</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-right pr-4"><Code>assertEquals</Code></td>
          <td><Code>assertEqual</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4"><Code>failUnlessEqual</Code></td>
          <td><Code>assertEqual</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">failIfEqual<Code></Code></td>
          <td><Code>assertNotEqual</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">assertNotEquals<Code></Code></td>
          <td><Code>assertNotEqual</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">failUnless<Code></Code></td>
          <td><Code>assertTrue</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">assert_<Code></Code></td>
          <td><Code>assertTrue</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">failIf<Code></Code></td>
          <td><Code>assertFalse</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">failUnlessRaises<Code></Code></td>
          <td><Code>assertRaises</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">failUnlessAlmostEqual<Code></Code></td>
          <td><Code>assertAlmostEqual</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">assertAlmostEquals<Code></Code></td>
          <td><Code>assertAlmostEqual</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">assertNotAlmostEquals<Code></Code></td>
          <td><Code>assertNotAlmostEqual</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">failIfAlmostEqual<Code></Code></td>
          <td><Code>assertNotAlmostEqual</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">assertRegexpMatches<Code></Code></td>
          <td><Code>assertRegex</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">assertNotRegexpMatches<Code></Code></td>
          <td><Code>assertNotRegex</Code></td>
        </tr>
        <tr>
          <td className="text-right pr-4">assertRaisesRegexp<Code></Code></td>
          <td><Code>assertRaisesRegex</Code></td>
        </tr>
      </tbody>
    </table>
  </>
)

export {category, impact}
