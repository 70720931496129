import React, { useContext } from 'react';

import { Box, Flex, StyledOcticon, BorderBox, Timeline, Avatar, Label } from '@primer/components'
import { GitCommit, Eye, Info } from '@primer/octicons-react'

import { CorpusContext } from '../../hooks/useCorpus' 
import { useProduct } from '../../hooks/useProduct'

import Card from './CardWithContext'
import { countLines } from '../../helpers'


export default function(props) {
  const { corpus } = useContext(CorpusContext)
  const productDetails = useProduct()

  const botName = productDetails.id === 'djangodoctor' ? 'django-doctor' : 'code-review-doctor'

  return (
    <Box style={{background: "white", borderRadius: 6}} className="pr-2 pl-2 pl-md-4 ml-md-0 mr-md-0 ml-2 mr-2">
      <Timeline>
        <Timeline.Item className={"pb-0"}>
          <Timeline.Badge>
            <StyledOcticon icon={Eye} />
          </Timeline.Badge>
          <Timeline.Body>
            <Avatar src="/favicon.png" size={32} className="mr-2 text-grey" />
            <strong className="pr-2">{botName}</strong>
            <Label variant="small" sx={{border: "1px solid grey", color: "grey", backgroundColor: "white"}}>bot</Label>
            <span  className="pl-2"><span className="hide-sm">suggested changes</span> just now</span>
          </Timeline.Body>
        </Timeline.Item>
        {
          props.examplesCodes.map((exampleCode, i) => {
            const example = corpus[exampleCode]
            const hasDelta = example.exampleAfter !== example.exampleBefore
            return (
              <Timeline.Item className={"pb-0"}>
                <Timeline.Body className="ml-2 ml-md-4">
                  <Box style={{borderBottomRightRadius: 0, borderTopLeftRadius: 6}}>
                    <BorderBox className="bg-gray f5 p-1 pl-3 text-mono" style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 6, borderTopRightRadius: 6, border: '1px solid #dedede', borderBottom: 0}}>
                      { example.exampleTitle }
                    </BorderBox>
                    <Card
                      textClassName={"text-black"}
                      lineNumber={hasDelta ? undefined : countLines(example.exampleBefore)}
                      {...example}
                      exampleNumber={i}
                      hasDelta={hasDelta}
                      linesOffset={0}
                      minimalistAdvice={false}
                    />
                  </Box>
                </Timeline.Body>
              </Timeline.Item>
            )
          })
        }
        <Timeline.Item className={"pb-4 pt-4"} condensed>
          <Timeline.Badge>
            <StyledOcticon icon={GitCommit} />
          </Timeline.Badge>
          <Timeline.Body>
            <Avatar src="/richard.png" square={false} style={{borderRadius: 20}} className="mr-2 text-grey" />
            Update {corpus[props.examplesCodes[props.examplesCodes.length-1]].exampleTitle}
          </Timeline.Body>
        </Timeline.Item>
      </Timeline>
    </Box>
  )
}

Card.defaultProps = {
  showLabels: true
}