import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'
import { CATEGORY_TESTS as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'tests.py'

export const exampleBefore = (
`class TestFeature(unittest.TestCase):
    def test_feature(self):
        self.assertTrue(value in values)`
)

export const exampleAfter = (
`class TestFeature(unittest.TestCase):
    def test_feature(self):
        self.assertIn(value, values)`
)

export const code = 'UseAssertIn'

export const title = 'Use AssertIn when performing inclusion tests'

export const label = 'Use AssertIn'

export const wordCode = 'use-assert-in'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/unittest.html#unittest.TestCase.assertIn',
    text: 'Python documentation on TestCase.assertIn'
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      <Code>assertIn</Code> and <Code>assertNotIn</Code> provide more helpful failure messages than <Code>assertTrue</Code> or <Code>assertFalse</Code>.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>When performing inclusion checks it's better to use the correct tool for the job: <Code>assertIn</Code> and <Code>assertNotIn</Code> are provided explicitly for this task.</Text>
    <Text as={'p'}>The difference is assertion methods such as <Code>assertTrue</Code> will report the message:</Text>
    <Text as={'p'} className="mt-4 mb-4"><pre style={{fontSize: 'inherit'}}>AssertionError: False is not True</pre></Text>
    <Text as={'p'}>While <Code>assertIn</Code> will provided a much more helpful message:</Text>
    <Text as={'p'} className="mt-4 mb-4"><pre style={{fontSize: 'inherit'}}>AssertionError: 'foo' not found in 'items'</pre></Text>
    <Text as={'p'}>Good quality failure messages allows the failure mode to be understood rapidly and therefore the problem to be fixed more quickly.</Text>
  </>
)

export {category, impact}
