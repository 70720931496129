import React, { useContext } from 'react'
import { Box, Text, Heading, ButtonPrimary } from '@primer/components'
import { Link } from "react-router-dom"
import LazyLoad from 'react-lazyload'
import exampleMessages from '../../fixtures/issues-django-and-python.json'

import { useCategory, CorpusContext } from '../../hooks/useCorpus'
import { useProduct } from '../../hooks/useProduct'
import useAnalytics from '../../hooks/useAnalytics'
import { groupMessagesByFile as groupMessages } from '../../helpers'
import HeaderLevelTwoRepository from '../../components/HeaderLevelTwoRepository'

import Card from '../../components/BeforeAfter/CardWithContext'

import PullRequestTimeline from '../Repository/PullRequestTimeline'
import { getMessageCentricMessages } from '../Repository'


const RepositorySearch = React.lazy(() => import('../../components/RepositorySearch'))


export default function(props) {
  const { corpus } = useContext(CorpusContext)
  const analytics = useAnalytics()
  const messages = getMessageCentricMessages(exampleMessages)
  const report = groupMessages(exampleMessages, true, corpus)
  const productDetails = useProduct()
  return (
    <Box className="position-relative pt-md-8 pt-4 pl-2 pr-3 pl-md-4 pr-md-4" id="a-quick-example">
      <Box className="container-xl  mb-4 mb-md-0 text-center">
        <Heading>A quick example</Heading>
        <Text as="p" className="lead">This is a sample of the kind of advice you can expect from {productDetails.name}.</Text>
      </Box>
      <HeaderLevelTwoRepository
        owner={'code-review-doctor'}
        repository={'a-quick-example'}
        adviceCount={messages.length}
      />
      <Box alignItems="center" className="container-xl mt-3 d-md-flex mb-4 mb-md-0">
        <PullRequestTimeline
          messages={messages}
          report={report}
          repoBlobLink={`https://github.com/code-review-doctor/a-quick-example/blob/devel`}
          showFilter={false}
        />
      </Box>
      <Box className="container-md mt-8 mb-8 text-center">
        <Box className="position-relative mt-6" onClick={() => analytics.track('JS3AWDTZ')}>
          <Heading className="mb-6">Check your repository instantly for free</Heading>
          <div className="text-left">
            <React.Suspense fallback={<div>loading...</div>} >
              <RepositorySearch />
            </React.Suspense>
          </div>
        </Box>
      </Box>
    </Box>
  )
}
