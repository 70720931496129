import React from 'react';

import { Box, BorderBox, Heading, Text } from '@primer/components'

import { format as timeagoFormat } from 'timeago.js'
import { default as DefaultChangeNotes } from '../../ChangeNotes.js';


const ChangeNotes = DefaultChangeNotes.slice().reverse()


function getFixCounts(versionFrom, versionTo) {
  var bugs = 0
  var security = 0

  var indexFrom = ChangeNotes.findIndex(item => item.version === versionFrom)
  var indexTo = ChangeNotes.findIndex(item => item.version === versionTo)

  ChangeNotes.slice(indexFrom+1, indexTo+1).forEach(item => {
    bugs += item.bugs.length
    security += item.security.length
  })
  return { bugs, security }
}


export default function(props) {
  return (
    <BorderBox className="pl-4 pr-4 pb-4 pt-2 mb-4">
      <Heading fontSize={4} className="mb-4">Django version</Heading>
      <Box className="bullet-list-blue mt-4 f3">
        <Text className="f4"><span as="pre">Project uses </span><a href={`https://github.com/${props.owner}/${props.repository}/blob/${props.branch}/${props.filetype}`} rel="noopener noreferrer" target="_blank" title={`view ${props.filetype}`}>Django{props.specifier}</a>
        {!props.specifier.startsWith("==") && <span> which resolves to <DjangoReleaseLink version={props.specifierResolves} /></span>}
        {props.newestMatchingVersionDetails && <span>, released {timeagoFormat(props.newestMatchingVersionDetails.upload_time, 'en_UK')}.</span> }
        </Text>
        <AvailableVersions
          isNewMinorVersionAvailable={props.isNewMinorVersionAvailable}
          isNewMajorVersionAvailable={props.isNewMajorVersionAvailable}
          newestAvailableMinorVersion={props.newestAvailableMinorVersion}
          newestAvailableMajorVersion={props.newestAvailableMajorVersion}
          newestMatchingVersion={props.newestMatchingVersion}
          specifierResolves={props.specifierResolves}
        />
      </Box>
    </BorderBox>
  )
}

export function DjangoReleaseLink(props) {
  const minor = props.version.split('.').slice(0,-1).join('.')
  return <a href={`https://docs.djangoproject.com/en/${minor}/releases/${props.version}/`} target="blank">{props.version}</a>
}


function AvailableVersions(props) {
  const children = []

  if (props.isNewMinorVersionAvailable) {
    const minorVersionFixes = getFixCounts(props.newestMatchingVersion, props.newestAvailableMinorVersion)

    if (minorVersionFixes.bugs || minorVersionFixes.security) {
      children.push(
        <Text key={'bugfix-release-available'} className="f4 mt-4"> {props.specifierResolves} has <a href='#django-bugs'><strong>{minorVersionFixes.bugs} bugs</strong> and <strong>{minorVersionFixes.security} vulnerabilities</strong></a>. Upgrade to <DjangoReleaseLink version={props.newestAvailableMinorVersion} /> to fix them.</Text>
      )
    } else {
      children.push(
        <Text key={'minor-release-available'} className="f4 mt-4" as="p">New minor version <DjangoReleaseLink version={props.newestAvailableMinorVersion} /> available.</Text>
      )
    }
  }

  if (props.isNewMajorVersionAvailable) {
    children.push(<Text key={'major-release-available'}  className="f4 mt-4" as="p">Newest Django version <DjangoReleaseLink version={props.newestAvailableMajorVersion} /> is available for upgrade.</Text>)
  }

  return <>{children}</>
}
