import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'

export const exampleTitle = 'migration.py'

export const exampleBefore = (
`class Migration(migrations.Migration):
    dependencies = [("core", "0001_initial.py")]
    operations = [RunPython(forwards)]`
)

export const exampleAfter = (
`class Migration(migrations.Migration):
    dependencies = [("core", "0001_initial.py")]
    operations = [RunPython(forwards, RunPython.noop)]`
)

export const code = 'C8001'

export const title = 'Missing reverse migration'

export const label = title

export const wordCode = 'missing-reverse-migration'

export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/the-hidden-django-anti-pattern-preventing-your-prod-rollback-179fe23f8ad9',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/topics/migrations/#reversing-migrations',
    text: 'Django migration documentation',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      It's good to, as a minimum, specify <code style={{fontSize: 'inherit'}}>noop</code> in <code style={{fontSize: 'inherit'}}>RunPython</code> so the migration can be skipped when going backwards, and even better to specify a function that <a href="https://docs.djangoproject.com/en/3.1/topics/migrations/#reversing-migrations" target="_blank" rel="noopener noreferrer" style={{color: 'inherit'}}>undoes the migration</a>.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>The code in <code style={{fontSize: 'inherit'}}>forwards</code> mutates the data, but in order to undo the change then <code style={{fontSize: 'inherit'}}>reverse_code</code> is needed.</Text>
    <Text as={'p'}>If <code style={{fontSize: 'inherit'}}>reverse_code</code> is missing then when an attempt is made to undo the migration then Django will raise <code style={{fontSize: 'inherit'}}>IrreversibleError</code>.</Text>
    <Text as={'p'}>This is a problem because it prevents rolling back the state of the database, which may be needed if a bug is detected after a production deployment and the last good version of the app needs deploying back to production. Without <code style={{fontSize: 'inherit'}}>reverse_code</code>, the state of the database will be out of step with the app code.</Text>
  </>
)


export const ogImage = "/og-image/C8001.png"
export {category, impact}