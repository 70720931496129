import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'


export const exampleTitle = 'models.py'

export const exampleBefore = (
`class ExampleModel(models.Model):
    date = models.DateField()
    foo = models.CharField(unique_for_date='date')`
)

export const exampleAfter = (
`class ExampleModel(models.Model):
    date = models.DateField()
    foo = models.CharField()

    def save(self, *args, **kwargs):
        # more verbose, but less brittle than unique_for_date
        if self.objects.filter(date=self.date, foo=self.foo).exists():
            raise ValidationError({'foo': 'Must be unique for date.'})
        return super().save(*args, **kwargs)`
)

export const code = 'C2004'

export const title = 'Brittle unique_for'

export const label = title

export const wordCode = 'brittle-unique-for'

export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/the-hidden-pitfalls-of-unique-for-date-296254b19012',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#unique-for-date',
    text: 'Django unique_for documentation',
  },
]

export function Summary(props) {
  return (
    <Text as='p' className={props.className}>
      <code style={{fontSize: 'inherit'}}>unique_for_date/month/year</code> is very brittle and over time will likely be a source for unexpected behaviour.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as='p'><code style={{fontSize: 'inherit'}}>unique_for_date/month/year</code> prevents entry of two records with the same value on the same date or month or year.</Text>
    <Text as='p'>However, this feature is unfortunately brittle:</Text>
    <ul>
      <li>This constraint is not enforced by the database.</li>
      <li>It is checked during <code style={{fontSize: 'inherit'}}>Model.validate_unique()</code> and so will not occur if Model.save() is called without first calling <code style={{fontSize: 'inherit'}}>Model.validate_unique()</code>.</li>
      <li>The constraint will not be checked even if <code style={{fontSize: 'inherit'}}>Model.validate_unique()</code> is called when using a ModelForm that does not include a field involved in the check.</li>
      <li>Only the date portion of the field will be considered, even when validating a <code style={{fontSize: 'inherit'}}>DateTimeField</code>.</li>
    </ul>
    <Text as='p'>Most of these problems can be mitigated by moving the validation to <code style={{fontSize: 'inherit'}}>Model.save()</code>.</Text>
  </>
)


export const ogImage = "/og-image/C2004.png"
export {category, impact}