import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'views.py'

export const exampleBefore = (
`class HomeView(View):
    def get(self, request):
        import pdb; pdb.set_trace()
        ...`
)

export const exampleAfter = (
`class HomeView(View):
    def get(self, request):
        ...`
)

export const code = 'NoBreakpoint'

export const ogImage = `/og-image/${code}.png`

export const title = 'Avoid committing breakpoints'

export const label = title

export const wordCode = 'no-breakpoint'

export const furtherReading = [

]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Don't commit breakpoints. This will pause execution in prod and break the app.
    </Text>
  )
}

export const explanation = null

export {category, impact}
