import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'
import { CATEGORY_TESTS as category, IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'tests.py'

export const exampleBefore = (
`class TestFeature(unittest.TestCase):
    def test_feature(self):
        ...
\t
    def test_feature(self):
        ...`
)

export const exampleAfter = (
`class TestFeature(unittest.TestCase):
    def test_feature(self):
        ...
\t
    def test_feature_two(self):
        ...`
)

export const code = 'NoDuplicateTestNames'

export const title = 'Use unique names for tests'

export const label = title

export const wordCode = 'duplicate-test-names'

export const furtherReading = []

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Duplicate names for tests results in some tests being skipped.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>When a function or class is defined multiple times in the same scope the last definition overwrite all the previous ones. This is true for variable assignments in general as well as tests more specifically.</Text>
    <Text as={'p'}>Test runners such as <a href="https://pytest.org" target="_blank">pytest</a> or the <a href="https://docs.python.org/3/library/unittest.html" target="_blank">built-in unittest package</a> can only detect and run the tests that are defined in the test files - so if a file contains a duplicate test name then the earlier ones will not be available to the test runner and will therefore not be ran:</Text>
    <Snippet value={`# file with 3 tests (with duplicate names)
def test_a():
    pass

def test_a():
    pass

def test_a():
    pass`} />
    <Text as={'p'}>So three tests were written. Lets run them:</Text>
    <Snippet value={`$ pytest
collecting ... 
 tests/test_helpers.py::test_a ✓ 100% ██████████

Results (0.85s):
       1 passed
`} />
  <Text as={'p'}>Three tests were written but only one test was found in the file and therefore only one test was ran.</Text>
  </>
)

export {category, impact}
