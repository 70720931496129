import React, { createContext, useContext } from 'react'

import * as django from '../corpus/django'
import djangoRoutes from '../corpus/django/routes'

import * as python from '../corpus/python'
import pythonRoutes from '../corpus/python/routes'


const moduleToObject = module => Object.fromEntries(Object.entries(module))

const corpusDjango = moduleToObject(django)
const corpusPython = moduleToObject(python)
const routesDjango = moduleToObject(djangoRoutes)
const routesPython = moduleToObject(pythonRoutes)

// yes this sucks
Object.values(corpusDjango).forEach(value => value.stack = 'django')
Object.values(corpusPython).forEach(value => value.stack = 'python')

const corpusMap = {
  django: corpusDjango,
  python: corpusPython,
  all: {...django, ...python}
}

const routeMap = {
  django: routesDjango,
  python: routesPython,
  all: {...routesDjango, ...routesPython}
} 

export const useCorpus = name => corpusMap[name]
export const useCorpusRoutes = name => routeMap[name]


export const CorpusProvider = ({name, appPath, children}) => {
  const corpus = useCorpus(name)
  const routes = {}
  Object.entries(useCorpusRoutes(name)).forEach(([code, route]) => {
    routes[code] = `/features/${corpus[code].stack}${route}`
  })

  Object.entries(corpus).forEach(([code, check]) => {
    check.urlPrefix = `/features/${corpus[code].stack}`
  })

  return (
    <CorpusContext.Provider value={{corpus, routes, name}}>
      {children}
    </CorpusContext.Provider>
  )
};
export const CorpusContext = createContext({})

export const useCategory = category => {
  const { corpus } = useContext(CorpusContext)
  return Object.values(corpus).filter(item => item.category === category)
}

export const useImpact = impact => {
  const { corpus } = useContext(CorpusContext)
  return Object.values(corpus).filter(item => item.impact === impact)
}