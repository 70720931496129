import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_PERFORMANCE as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'

export const exampleTitle = 'views.py'

export const exampleBefore = (
`def get_random_item():
    return MyModel.objects.order_by('?')[0]`
)


export const exampleAfter = (
`from random import randint

def get_random_item():
    index = randint(0, MyModel.objects.count() -1)
    return model.objects.all()[index]`
)
export const code = 'C9004'

export const title = 'Random ordering via order_by("?")'

export const label = title

export const wordCode = 'inefficient-order-by-random'

export const furtherReading = [
  {
    href: 'https://dev.to/djangodoctor/the-django-feature-that-can-slow-your-website-to-a-crawl-2f9k',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.2/ref/models/querysets/#django.db.models.query.QuerySet.order_by',
    text: 'Django queryset documentation',
  }
]

export function Summary(props) {
  return (
    <Text as="p" className={props.className}>
      Using <code style={{fontSize: 'inherit'}}>order_by('?')</code> can be very inefficient if you have lots of rows in the table. Moving the randomness to the application layer will probably give significant a performance improvement. 
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>Using <code style={{fontSize: 'inherit'}}>order_by('?')</code> can be very slow as to achieve random ordering the database has to:</Text>
    <ul className="mb-4">
      <li>Generate a random number for each row.</li>
      <li>Scan the numbers for the smallest one.</li>
      <li>Read the one row we're interested in.</li>
    </ul>
    <Text as={'p'}>Let that sink in: generating random numbers is slow and scanning is not quick. We're doing that for every row in the table just to read one row! If there are a lot of rows then expect a performance impact. In short, databases are not good at random. Applications are though. So consier splitting responsibilities between the database and the application:</Text>
    <ul className="mb-4">
      <li>At database level determine the number of rows via <code style={{fontSize: 'inherit'}}>objects.count()</code></li>
      <li>At application level get a random number between 0 and that count.</li>
      <li>At database level select the row at that index.</li>
    </ul>
    <Text as={'p'}>This will mean two database reads are performed, but that will be significantly quicker than the database copying the table and ordering all the rows randomly.</Text>
  </>
)


export {category, impact}