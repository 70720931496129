import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'

export const exampleTitle = 'views.py'

export const exampleBefore = (
`from example.config import settings
from django.db import models`)

export const exampleAfter = (
`from django.conf import settings
from django.db import models`
)

export const code = 'C4006'


export const title = 'Importing setting file directly'

export const label = title

export const wordCode = 'direct-import-settings'

export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/naked-settings-py-in-your-codebase-f4d1a9711a86',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/topics/settings/',
    text: 'Django settings documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Using <code style={{fontSize: 'inherit'}}>from django.conf import settings</code> simplifies the code and makes it more maintainable.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>It's preferable to use <code style={{fontSize: 'inherit'}}>from django.conf import settings</code> instead of importing the settings file directly.</Text>
    <Text as={'p'}>Importing directly can trigger a race condition when your code imports settings before the app is ready.</Text>
    <Text as={'p'}>Overriding settings in tests is simplified when using <code style={{fontSize: 'inherit'}}>django.conf.settings</code> as you can then use Django's <a href="https://docs.djangoproject.com/en/3.1/topics/testing/tools/#django.test.modify_settings" target="_blank" rel="noopener noreferrer"><code style={{fontSize: 'inherit'}}>modify_settings</code></a> and django-pytest's <a href="https://pytest-django.readthedocs.io/en/latest/helpers.html#settings" target="_blank" rel="noopener noreferrer"><code style={{fontSize: 'inherit'}}>settings</code></a> fixture, which rely on the code using <code style={{fontSize: 'inherit'}}>django.conf.settings</code>.</Text>
  </>
)



export const ogImage = "/og-image/C4006.png"
export {category, impact}