import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_SECURITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'

export const exampleTitle = 'settings.py'

export const exampleBefore = `SECURE_CONTENT_TYPE_NOSNIFF = False`

export const exampleAfter = `SECURE_CONTENT_TYPE_NOSNIFF = True`

export const code = 'C4012'

export const title = 'Browser can be tricked into executing uploaded malicious code'

export const label = 'Missing nosniff'

export const wordCode = 'missing-secure-content-type-nosniff'

export const furtherReading = [
  {
    href: 'https://dev.to/djangodoctor/hacking-django-websites-30f',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/middleware/#module-django.middleware.security',
    text: 'Django security documentation',
  },
  {
    href: 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Content-Type-Options',
    text: 'Mozilla X-Content-Type-Options documentation',
  },
]


export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Your website is vulnerable to being tricked into executing uploaded malcious code because the <code style={{fontSize: 'inherit'}}>SECURE_CONTENT_TYPE_NOSNIFF</code> setting is not set.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'} className="mb-3"><code style={{fontSize: 'inherit'}}>SecurityMiddleware</code> sets the <code style={{fontSize: 'inherit'}}>X-Content-Type-Options</code> header to nosniff when <code style={{fontSize: 'inherit'}}>SECURE_CONTENT_TYPE_NOSNIFF = True</code> to prevent hackers from tricking your website into executing a malicious javascript file that they uploaded via one of your forms.</Text>
    <Text as={'p'} className="mb-3">This header indicates to the browser that the MIME types advertised in the Content-Type headers should not be changed (by "sniffing" the content).</Text>
    <Text as={'p'} className="mb-3">The sniffing feature is the browser being helpful when a developer or server misconfiguration misidentified the Content-Type. If the browser respected an incorrect MIME type then a javascript, css, or image file would not work and the website would break. Very helpful feature. But it can be abused:</Text>
    <ul className="bullet-list-blue mb-3">
      <li>A bad actor uploads HTML containing javascript (maybe pretending to be an image file).</li>
      <li>The file is served by your website but does not set MIME type in the Content-Type header</li>
      <li>The browser infers the MIME type based on the content</li>
      <li>The browser executes the javascript</li>
    </ul>
    <Text as={'p'} className="mb-3">By setting <code style={{fontSize: 'inherit'}}>SECURE_CONTENT_TYPE_NOSNIFF = True</code>, the browser will not infer the MIME type if the Content-Type is not set, closing this security hole.</Text>
  </>
)


export {category, impact}