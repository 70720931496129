import React, { useContext } from 'react';

import {
  Box,
  ButtonPrimary,
  CounterLabel,
  Details,
  Flex,
  Heading,
  Label,
  LabelGroup,
  StyledOcticon,
  Text,
} from '@primer/components'

import { ChevronUp, ChevronRight, LinkExternal } from '@primer/octicons-react'
import { Link } from "react-router-dom";
import ReactDiffViewer from 'react-diff-viewer';

import { CorpusContext } from '../hooks/useCorpus'
import { IMPACT_LOW, IMPACT_MEDIUM, IMPACT_HIGH } from '../constants'

const CAN_AUTOFIX = [
  'C8001', 'C2000', 'C2002', 'C2003', 'C2006', 'C2008', 'C4006', 'C4005', 'C4007', 'C4008', 'C4009', 'C4010', 'C4011',
  'C4012', 'C4013', 'C4014', 'C4015', 'C4016', 'C4017', 'C6002', 'C9000', 'C9001', 'C9002', 'C9003', 'C9004'
]


export function normalizeDiff(diff) {
  if (!diff || diff.length == 0) {
    return ''
  }
  const normalized = []

  for (let line of diff) {
    normalized.push(line[1])
  }

  return normalized.join('\n')
} 

const getDiffLineOffset = message => Math.min(message.diff.before[0][0], message.diff.after[0][0]) -1
const buildGitHubLink = (owner, repository, branch, path, line) => `https://github.com/${owner}/${repository}/blob/${branch}/${path}#L${line || 1}`

const GitHubLink = ({owner, repository, branch, path, line, count, inert}) => {
  path = path.replace("/tmp/patient/", "")
  const href = buildGitHubLink(owner, repository, branch, path, line)
  return (
    <p className="mt-1 mb-0">
      {inert
        ? <span className="text-gray">{path}{line && `:${line}`}</span>
        : (
          <a
            className="text-gray"
            href={!inert && href}
            target="_blank"
            rel="noopener noreferrer"
          >{path}{line && `:${line}`}</a>
        )
      }
      { count > 1 && <span> and <CounterLabel>{count-1}</CounterLabel> other {count-1 === 1 ? 'place' : 'places'}</span> }
    </p>
  )
}

export const Diff = ({owner, repository, branch, message, showLink, className, showLabel}) => {
  const before = normalizeDiff(message.diff.before)
  const after = normalizeDiff(message.diff.after)
  const offset = getDiffLineOffset(message)
  const href = buildGitHubLink(owner, repository, branch, message.path, message.line)

  const lineIdentifier = showLink
    ? <a
        className="link-gray"
        target="_blank"
        rel="noopener noreferrer"
        href={href}
      >line {message.line} of {message.path}</a>
    : (<Text>line {message.line} of {message.path}</Text>)
  return (
    <Box className={className + ' hide-sm'}>
      { showLabel && <p className={className}>A suggested change for {lineIdentifier} is:</p> }
      <ReactDiffViewer
        oldValue={before}
        newValue={after}
        splitView={false}
        compareMethod={'diffLines'}
        linesOffset={offset}
        disableWordDiff={true}
        extraLinesSurroundingDiff={0}
      />                    
    </Box>
  )
}

Diff.defaultProps = {
  className: 'mb-6',
  showLabel: true,
}

const impactColourMap = {
  [IMPACT_HIGH]: 'red.5',
  [IMPACT_MEDIUM]: 'blue.5',
  [IMPACT_LOW]: 'green.5',
}

export function ImpactLabel({ impact }) {
  const colour = impactColourMap[impact]
  return <Label className="hide-sm" bg={colour}>{ impact }</Label>
}


export function CategoryLabel({category}) {
  return <Label className="hide-sm">{ category }</Label>
}


export default function Advice(props) {
    const { corpus, routes } = useContext(CorpusContext)
    const bestPractice = corpus[props.messageId]

    if (bestPractice == undefined) {
      return null
    }

    if (!bestPractice.title) {
      return null
    }
    const adviceUrl = routes[props.messageId]
    const Summary = bestPractice.Summary

    return (
      <li key={props.messageId} className="Box-row" style={{...props.style, padding: 0}}>
        <Details defaultOpen={props.defaultOpen}>
          {({open}) => (
            <>
              <Flex as="summary" className="p-2 p-md-3" title={(open ? 'Click to collapse' : 'Click to open')}>
                <div style={{width: '15px'}}>
                  <StyledOcticon icon={(open ? ChevronUp : ChevronRight)} size={24}  marginTop={'5px'} />
                </div>
                <Box style={{marginLeft: '10px', width: '100%'}} className="text-small text-gray pb-0">
                  <Box className="d-md-flex" justifyContent="space-between">
                    <Heading fontSize={3}>{ bestPractice.title }</Heading>
                    {
                      props.showLabels && (
                        <LabelGroup>
                          <CategoryLabel category={bestPractice.category} />
                          <ImpactLabel style={{verticalAlign: "middle"}} impact={bestPractice.impact} />
                        </LabelGroup>
                      )
                    }
                  </Box>
                  {
                    props.showHeaderCount && props.items.length > 1 && (
                      <CounterLabel style={{verticalAlign: 'middle'}} className="ml-1">{props.items.length}</CounterLabel>
                    )
                  }
                  {
                    props.showLink && (
                      <GitHubLink
                        count={props.items.length}
                        owner={props.owner}
                        repository={props.repository}
                        branch={props.branch}
                        path={props.items[0].path}
                        line={props.items[0].line}
                        inert={!open}
                      />
                    )
                  }
                </Box>
              </Flex>
              <Box className="p-3 pt-md-0 pt-0 p-md-4 mt-0" paddingTop="0" paddingBottom="20px" marginLeft="15px">
                {
                  props.showDiff && props.items[0] && props.items[0].diff && Object.keys(props.items[0].diff).length > 0 && (
                    <Diff
                     owner={props.owner}
                     repository={props.repository}
                     branch={props.branch}
                     message={props.items[0]}
                     showLink={props.showLink}
                    />
                  )
                }
                <div className="mb-4 f4">
                  <Box className={props.className}>
                    <Summary />
                  </Box>

                  <Link className="d-inline ml-1" to={adviceUrl}>Read more</Link>
                </div>
                {
                  props.handleFixForMeClick && CAN_AUTOFIX.includes(props.messageId) && (
                    <ButtonPrimary onClick={props.handleFixForMeClick} className="mr-2">Fix for me</ButtonPrimary>
                  )
                }
                { props.items && props.items.length > 1 && (
                  <>
                    <Heading
                      fontSize={2}
                      marginTop="30px"
                      marginBottom="15px"
                    >
                      {props.items.length -1} similar {props.items.length === 2 ? 'problem': 'problems'}:
                    </Heading>
                    <table style={{maxHeight: '200px', overflow: 'scroll', display: 'inline-block', width: '100%', columnGap: '30px'}}>
                      <tbody>
                        {props.items.slice(1).map((item, i) =>
                          <tr key={i}>
                            { props.showLink
                              ? (
                                <>
                                  <td>
                                      <a
                                      className="link-gray"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`https://github.com/${props.owner}/${props.repository}/blob/${props.branch}/${item.path}#L${item.line||1}`}
                                    >
                                      {item.path}{item.line && `:${item.line}`}
                                      <StyledOcticon color={'link-gray'} icon={LinkExternal} marginLeft={'6px'} />
                                    </a>
                                  </td>
                                  <td>{item.message}</td>
                                </>
                              )
                              : <><td>{item.path}{item.line && `:${item.line}`}</td><td>{item.message}</td></>
                            }
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </>
                )
              }
              </Box>
            </>
          )}
        </Details>

      </li>
    )
}

Advice.defaultProps = {
  showDiff: false,
  showLink: false,
  showLabels: true,
  style: {},
}