export default {
  C1001: '/best-practice/upgrade-django-version-not-actively-maintained',
  C1002: '/best-practice/upgrade-django-bugfixes-and-new-features-available',
  C2000: '/best-practice/avoid-null-string-field',
  C2001: '/best-practice/avoid-tall-models',
  C2002: '/best-practice/avoid-redundant-default-arguments',
  C2003: '/best-practice/fields-blank-and-null',
  C2004: '/best-practice/unique-for-considered-harmful',
  C2005: '/best-practice/foreign-key-related-name',
  C2006: '/best-practice/charfield-vs-textfield',
  C2008: '/best-practice/booleanfield-vs-nullbooleanfield',
  C2011: '/best-practice/primary-key-not-unique',
  C3000: '/best-practice/too-many-lines-django-models',
  C3002: '/best-practice/model-method-order',
  C3003: '/best-practice/naming-models-redundant-prefix',
  C4000: '/best-practice/middleware-order',
  C4001: '/best-practice/template-settings-relative-path',
  C4002: '/best-practice/template-settings-windows',
  C4003: '/best-practice/middleware-order-end',
  C4004: '/best-practice/middleware-order-top',
  C4005: '/best-practice/simplify-your-settings',
  C4006: '/best-practice/importing-settings-directly',
  C4007: '/best-practice/security-middleware',
  C4008: '/best-practice/prevent-clickjacking-middleware',
  C4009: '/best-practice/activate-csrf-protection',
  C4010: '/best-practice/http-strict-transport-security',
  C4011: '/best-practice/hsts-protect-subdomain',
  C4012: '/best-practice/content-type-no-sniff-protection',
  C4013: '/best-practice/http-enable-https-protection',
  C4014: '/best-practice/session-cookie-secure-packet-sniffing-attack',
  C4015: '/best-practice/session-cookie-http-only-xss-attack',
  C4016: '/best-practice/crsf-vulnerable',
  C4017: '/best-practice/preload-hsts',
  C4018: '/best-practice/feature-flag-instead-of-debug',
  C5000: '/best-practice/where-to-put-djano-admin-files',
  C6000: '/best-practice/url-unresolvable',
  C6001: '/best-practice/url-duplicate-name',
  C6002: '/best-practice/reverse-lazy-vs-reverse',
  C7000: '/best-practice/hard-coded-url-template-url-template-tag',
  C7001: '/best-practice/hard-coded-static-url-template-static-template-tag',
  C8000: '/best-practice/avoid-model-imports-in-migrations',
  C8001: '/best-practice/data-migration-best-practice-reverse-noop',
  C9000: '/best-practice/len-queryset-vs-queryset-count',
  C9001: '/best-practice/foreign-key-id-related-avoid-lookup',
  C9002: '/best-practice/queryset-count-vs-queryset-exists',
  C9003: '/best-practice/queryset-truthiness-vs-queryset-exists',
  C9004: '/best-practice/efficient-get-random-record-from-queryset',
}