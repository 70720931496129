import { useContext } from 'react'

import {
  SET_SHOULD_SHOW_SUBSCRIBE,
  useStore,
  getShouldShowSubscribe,
  LOCAL_STORAGE_KEY_USER_IS_SUBSCRIBED
} from '../Store'


export const useSubscribe = function() {

  const { state, dispatch } = useStore();

  function handleSubscribed() {
    dispatch({type: SET_SHOULD_SHOW_SUBSCRIBE, payload: false});
    localStorage.setItem(LOCAL_STORAGE_KEY_USER_IS_SUBSCRIBED, true)
  }

  return [getShouldShowSubscribe(state), handleSubscribed]

}
