import React from 'react';

import { Box, Heading, Text, ButtonPrimary } from '@primer/components'
import { withRouter } from "react-router-dom";

import WaveHero from '../../../components/WaveHero'
import CodeReview from '../../../components/BeforeAfter/BeforeAfterHorizontal'
import useAnalytics from '../../../hooks/useAnalytics'
import RepositorySearch from '../../../components/RepositorySearch'

import { defaultExamplePythonRepos } from '../../../hooks/useOctokit'


export default withRouter(function(props) {
  const analytics = useAnalytics()
  return (
    <WaveHero>
      <Box className="container-lg pt-md-8 mb-10 pl-2 pr-2 pl-md-4 pr-md-4">
        <Box className="text-white m-md-0 text-center">
          <Heading as="h1" fontSize={58} paddingTop="15px" className="mb-4" lineHeight={"1em"}>Fix more bugs during code review</Heading>
          <Text
            as="p"
            fontSize={4}
            className="text-white pl-2 pr-2 pl-md-0 pl-md-0 mb-2"
          >Trusted by <b>over 3000 teams</b> to securely fix bugs and improve Python code. Check your codebase for free instantly.</Text>
          <ButtonPrimary
            as={'a'}
            variant="large"
            className="f3 p-3 mt-4"
            href={'#a-quick-example'}
          >View demo
          </ButtonPrimary>
        </Box>
      </Box>
    </WaveHero>
  )
})


