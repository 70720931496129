import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'models.py'

export const exampleBefore = (
`class CommentModel(models.Model):
    body = models.TextField(null=False,editable=True)
    date = models.DateField()`
)

export const exampleAfter = (
`class CommentModel(models.Model):
    body = models.TextField()
    date = models.DateField()`
)

export const code = 'C2002'

export const title = 'Redundant default arguments'

export const label = title

export const wordCode = 'redundant-default-args'

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#field-options',
    text: 'Field documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Stating defaults add complexity when reading the code but does not change Django's behaviour.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>Python allows function arguments to have default values. The default value is used when the function is called without the argument.</Text>
    <Text as={'p'}>Redundant default arguments are explicitly passing in default values when calling a function.</Text>
    <Text as={'p'}>Redundant default arguments are noise. They don't add value but do add maintenance overhead. The code will execute the same without specifying them. Reduce the amount of code that needs to be read and maintained by deleting redundant default arguments.</Text>
  </>
)


export {category, impact}