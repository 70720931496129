import React from 'react'

import { Text } from '@primer/components'

import Snippet from '../../components/Snippet'
import Code from '../../components/Code'
import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = `any([item for item in items])`

export const exampleAfter = `any(item for item in items)`

export const code = 'NoRedundantListComprehension'

export const ogImage = `/og-image/${code}.png`

export const title = 'Avoid redundant list comprehension'

export const label = 'Redundant list comprehension'

export const wordCode = 'avoid-redundant-list-comprehension'

export const furtherReading = [
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      <Code>all</Code> and <Code>any</Code> can take a generator, so constructing a list first may be unnecessary.
    </Text>
  )
}

const exampleCode = (
`def send_messages(messages):
    if not all(send(message) for message in messages):
        print('not all messages sent')

def send(message):
    # return True if sent, and False if not
    ...
`
)


export const explanation = (
  <>
    <Text as={'p'}>A generator returns a single item in the sequence ad hoc, on each iteration, while a list first loads the full list to memory.</Text>
    <Text as={'p'}><Code>any</Code> stops the loops as soon as a truthy is encountered and <Code>all</Code> stops the loop as soon as a falsey is encountered, so they lend themselves well to working with generators.</Text>
    <Text as={'p'}>Caution is needed though: it's possible that a developer would want to avoid short-circuiting the loop: when the proper functioning of the code relies on the loop being completed. For example:</Text>
    <Snippet value={exampleCode} />
    <Text as={'p'}>In that case the emails would stop being sent as soon as one message failed to send. Probably not what the developer intended to happen.</Text>
  </>
)


export {category, impact}