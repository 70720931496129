import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_LOW as impact } from '../../constants'

export const exampleTitle = 'models.py'

export const exampleBefore = (
`class BlogPost(models.Model):
    post = models.ForeignKey(User, on_delete=models.CASCADE)
\t
def get_user_blog_posts(user):
    return user.blogpost_set.all()`)

export const exampleAfter = exampleBefore

export const lineNumber = 2  // which line to show the comment on

export const code = 'C2005'

export const title = 'ForeignKey missing related_name'

export const label = title

export const wordCode = 'missing-related-name'


export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#foreignkey',
    text: 'Django ForeignKey documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      ORM queries are more readable and relationships more explicit when <code style={{fontSize: 'inherit'}}>related_name</code> is specified.
    </Text>
  )
}


export const explanation = (
  <>
    <Text as={'p'}>The related_name attribute specifies the name of the reverse relation from the related model back to the model.</Text>
    <Text as={'p'}>Django automatically creates a related_name if related_name is not set. Django uses the lower-case name of the model with the suffix _set.</Text>
  </>
)


export {category, impact}