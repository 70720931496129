import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'
import ReactDiffViewer from 'react-diff-viewer';

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'
import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_HIGH as impact } from '../../constants'

export const exampleTitle = 'settings.py'

export const exampleBefore = `raise ValidationError('{value} is not allowed')`

export const exampleAfter = `raise ValidationError(f'{value} is not allowed')`

export const code = 'ProbablyMeantFstring'

export const ogImage = `/og-image/${code}.png`

export const title = 'Missing f prefix in f-string'

export const label = title

export const wordCode = 'probably-meant-fstring'

export const furtherReading = []

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      An f-string will not work if the <Code>f</Code> prefix is missing.
    </Text>
  )
}


const exampleCode = (
`name = "jeff"
print(f"My name is {name}")`
)

export const explanation = (
  <>
    <Text as={'p'}>Literal String Interpolation aka f-strings (because of the leading character preceding the string literal) were introduced by <a href="https://docs.python.org/3/whatsnew/3.6.html#whatsnew36-pep498" target="_blank">PEP 498</a>. Prefix a string with the letter "f" and it’s now an f-string:</Text>
    <Snippet value={exampleCode} />
    <Text as={'p'} className="mt-3">String interpolation is meant to be simpler with f-strings. However <a href="https://codereviewdoctor.medium.com/10-of-the-666-most-popular-python-github-repos-have-this-f-string-bug-69e3540c0583" target="_blank">our research</a> show 10% of repositories have one of these f-string mistakes:</Text>

    <Text as={'p'} className="mt-3">Missing prefix:</Text>
    <ReactDiffViewer
      oldValue={'"hello {name}"'}
      newValue={'f"hello {name}"'}
      splitView={true}
      hideLineNumbers={true}
      disableWordDiff={true}
      leftTitle="bad"
      rightTitle="good"
    />

    <Text as={'p'} className="mt-3">Prefix was written inside the string:</Text>
    <ReactDiffViewer
      oldValue={'"fhello {name}"'}
      newValue={'f"hello {name}"'}
      splitView={true}
      hideLineNumbers={true}
      disableWordDiff={true}
      leftTitle="bad"
      rightTitle="good"
    />

    <Text as={'p'} className="mt-3">Concatenated strings uses the prefix on only the first string.</Text>
    <ReactDiffViewer
      oldValue={'f"hello {name}" + "do you like {food}?"'}
      newValue={'f"hello {name}" + f"do you like {food}?"'}
      splitView={true}
      hideLineNumbers={true}
      disableWordDiff={true}
      leftTitle="bad"
      rightTitle="good"
    />
    <br/>
  </>
)

export {category, impact}
