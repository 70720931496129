
import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'models.py'

export const exampleBefore = (
`class FooBarClass:
    @classmethod
    def from_dict(self):
        pass`
)

export const exampleAfter = (
`class FooBarClass:
    @classmethod
    def from_dict(cls):
        pass`
)

export const code = 'ClsInClassMethod'

export const title = 'Missing cls in class method'

export const label = title

export const wordCode = 'class-method-missing-cls'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/functions.html#classmethod',
    text: 'Python @classmethod documentation',
  },
  {
    href: 'https://docs.python.org/3/glossary.html#term-decorator',
    text: 'Python decorator documentation',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Class methods should take <Code>cls</Code> as the first argument.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>The <Code>@classmethod</Code> decorator converts the method to a class method.</Text>
    <Text as={'p'}>When the class method is called, Python will implicitly pass in the class as the first argument, just like how an instance method receives the instance (<Code>self</Code>, by convention).</Text>
    <Text as={'p'}>While it is true that the first argument could be called anything, Python should be written to be read, and Python developers expect the first argument of class methods to be named <Code>cls</Code>.</Text>
  </>
)


export {category, impact}
