import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'
import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'utils.py'

export const exampleBefore = `title = 'Hello world',`

export const exampleAfter = `title = 'Hello world'`

export const code = 'ProbablyMeantNotTuple'

export const ogImage = `/og-image/${code}.png`

export const title = 'Avoid accidental tuples'

export const label = title

export const wordCode = 'probably-meant-not-tuple'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/stdtypes.html#tuple',
    text: 'Python documentation on tuple'
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      A comma after a value turns it into <Code>tuple</Code>. Probably not what you intended to do.
    </Text>
  )
}


const exampleCode = (
`value = (1)  # evaluates to int
value = 1,  # evaluates to tuple with one element
value = (1,)  # evaluates to tuple with one element
value = 1, 2  # evaluates to tuple with two elements
`
)

export const explanation = (
  <>
    <Text as={'p'}>As far as the Python parser is concerned the parentheses are optional for non-empty tuples. According to <a href="https://docs.python.org/3/library/stdtypes.html#tuple" target="_blank">the documentation</a>: <i>it is actually the comma which makes a tuple, not the parentheses. The parentheses are optional, except in the empty tuple case.</i></Text>
    <Snippet value={exampleCode} />
    <Text as={'p'}>By accidentally suffixing a comma on <Code>value = 1,</Code> expect to get a <Code>TypeError</Code> when the code later attempts to perform integer operations on a variable that evaluated to <Code>tuple</Code>.</Text>
  </>
)

export {category, impact}
