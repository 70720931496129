import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import BaseWebsite from './BaseWebsite'
import Loading from '../components/Loading'
import { useCorpus, useCorpusRoutes } from '../hooks/useCorpus'

import LandingPage from '../views/django/LandingPage'
const Repository = React.lazy(() => import('../views/Repository'))
const RepositoryList = React.lazy(() => import('../views/RepositoryList'))
const OwnerRepositoryList = React.lazy(() => import('../views/OwnerRepositoryList'))
const AbstractRule = React.lazy(() => import('../views/AbstractRule'))
const AdviceListSecurity = React.lazy(() => import('../views/django/AdviceListSecurity'))
const AdviceListPerformance = React.lazy(() => import('../views/django/AdviceListPerformance'))
const AdviceListMaintainability = React.lazy(() => import('../views/django/AdviceListMaintainability'))
const TermsAndConditions = React.lazy(() => import('../views/TermsAndConditions'))
const PrivacyPolicy = React.lazy(() => import('../views/Privacy'))
const ChallengeMaintainability = React.lazy(() => import('../views/django/ChallengeMaintainability'))
const ChallengePerformance = React.lazy(() => import('../views/django/ChallengePerformance'))
const ChallengeSecurity = React.lazy(() => import('../views/django/ChallengeSecurity'))
const ChallengeList = React.lazy(() => import('../views/django/ChallengeList'))
const Config = React.lazy(() => import('../views/Config'))
const ReleaseNotes = React.lazy(() => import('../views/django/ReleaseNotes'))


const name = "django"


export default function App(props) {
  const corpus = useCorpus(name)
  const routes = useCorpusRoutes(name)
  const urlPrefix = (props.match && props.match.url) || ''
  const buildUrl = url => urlPrefix + url
  return (
    <BaseWebsite name={name} appPath={urlPrefix}>
      <Switch>
        <Route exact path={buildUrl("/")} component={LandingPage} />
      </Switch>
      <React.Suspense fallback={<Loading /> } >
        <Switch>
          <Route exact path={buildUrl("/404.html")} component={Loading} />
          <Route exact path={buildUrl("/compare-django-release-notes/:from?/:to?")} component={ReleaseNotes} />
          <Route exact path={buildUrl("/challenge")} component={ChallengeList} />
          <Route exact path={buildUrl("/challenge/settings-security")} component={ChallengeSecurity} />
          <Route exact path={buildUrl("/challenge/database-queries")} component={ChallengePerformance} />
          <Route exact path={buildUrl("/challenge/models")} component={ChallengeMaintainability} />
          <Route exact path={buildUrl("/config")} component={Config} />
          <Route exact path={buildUrl("/terms")} component={TermsAndConditions} />
          <Route exact path={buildUrl("/privacy")} component={PrivacyPolicy} />
          <Route exact path={buildUrl("/best-practice/maintainability")} component={AdviceListMaintainability} />
          <Route exact path={buildUrl("/best-practice/security")} component={AdviceListSecurity} />
          <Route exact path={buildUrl("/best-practice/performance")} component={AdviceListPerformance} />
          {
            Object.entries(routes).map(([code, route]) =>
              <Route
                key={route}
                exact
                path={buildUrl(route)}
                render={props => <AbstractRule {...props} {...corpus[code]} />}
              />
            )
          }
          <Route exact path={buildUrl("/@me")} component={RepositoryList} />
          <Route path={buildUrl("/:owner/:repository/:branch?")} component={Repository} />
          <Route path={buildUrl("/:owner")} component={OwnerRepositoryList} />
        </Switch>
      </React.Suspense>
    </BaseWebsite>
  );
}