import React from 'react';

import { Box, BorderBox, Heading, Text, Flash, Label } from '@primer/components'

import { default as DefaultChangeNotes } from '../../ChangeNotes.js';
import { DjangoReleaseLink } from './DjangoVersionAnalysis'
import { Link } from "react-router-dom";

const ChangeNotes = DefaultChangeNotes.slice().reverse()

export function getRange(versionFrom, versionTo) {
  const indexFrom = ChangeNotes.findIndex(item => item.version === versionFrom)
  const indexTo = ChangeNotes.findIndex(item => item.version === versionTo)
  return ChangeNotes.slice(indexFrom+1, indexTo+1)
}


export default function(props) {
  let range = []
  if (props.versionTo && props.versionFrom) {
    range = getRange(props.versionFrom, props.versionTo)  
  }

  const items = []

  range.forEach(release => {
    release.security.forEach(item => {
      items.push({label: 'security', ...item})
    })
    release.bugs.forEach(item => {
      items.push({label: 'bug', ...item})
    })
  })

  if (items.length === 0) {
    return null
  }
  
  return (
    <Box className="d-md-flex" id="django-bugs">
      <div className="col-md-3 mr-md-4">&nbsp;</div>
      <BorderBox className="col-md-9 mt-md-4">
        <Box className="p-4">
          <Heading fontSize={4}>Bugs affecting Django { props.specifierResolves }</Heading>
          <Text as="p" className="f4">Upgrade to Django <DjangoReleaseLink version={props.newestAvailableMinorVersion} /> to fix these bugs and secutity vulnerabilities:</Text>
        </Box>
        <Box className="lead mb-0">
          <table>
            <tbody>
              {
                items.map((item, i) => (
                  <tr key={i} className={i % 2 === 0 ? 'bg-blue-light': ''}>
                    <td className="pt-2 pb-2 pl-4 pr-4"><a target="_blank" href={item.url} rel="noopener noreferrer" className="f4" dangerouslySetInnerHTML={{__html: handleCode(item.summary.replace('Fixed a ', ''))}} /></td>
                    <td className="pt-2 pb-2 pl-4 pr-4 text-center"><Label>{ item.label }</Label></td>
                  </tr>
                ))
              }
          </tbody>
          </table>
        </Box>
        <ReleaseNotesAdvert versionTo={props.versionTo} versionFrom={props.versionFrom} />
      </BorderBox>
    </Box>
   )  
 }

 function handleCode(item) {
  return item.replace(/<code>/g, '<code class="f4">')
 }


function ReleaseNotesAdvert(props) {
  return (
    <Flash variant="warning" style={{...props.style, border: 0}}>
      Use our <Link to={`/features/django/compare-django-release-notes/${props.versionFrom}/${props.versionTo}`}>Django release notes tool</Link> to view fixes and changes across Django releases
    </Flash>
  )
}