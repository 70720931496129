import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_LOW as impact } from '../../constants'


export const title = 'Tall models.py with a common prefix'

export const label = 'Tall models common prefix'

export const wordCode = 'model-common-prefix'

export const code = 'C3003'

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Taller <code style={{fontSize: 'inherit'}}>models.py</code> with many Models may be more difficult to maintain than shorter <code style={{fontSize: 'inherit'}}>models.py</code> with fewer Models.
    </Text>
  )
}


export const explanation = (
  <>
    <Text as={'p'}>There is a limit to how many items a developer can consider when forming a mental model of source code.</Text>
    <Text as={'p'}>It may be time to split an application if models.py is very long.</Text>
    <Text as={'p'}>A good starting point would be to move models with the same prefix to a sub-application.</Text>
  </>
)


export {category, impact}