import React from 'react'

import { Text, Heading } from '@primer/components'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'

import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'catalogue.py'

export const exampleBefore = (
`with open('some/path.txt') as f:
    content = f.write('foo')`
)

export const exampleAfter = (
`with open('some/path.txt', 'w') as f:
    content = f.write('foo')`
)

export const code = 'NoUnsupportedFileOperation'

export const ogImage = `/og-image/${code}.png`

export const title = 'Open files with correct mode flags'

export const label = 'Incorrect file modes'

export const wordCode = 'no-unsupported-file-operation'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/functions.html#open',
    text: 'Python open documentation',
  },
  {
    href: 'https://en.wikipedia.org/wiki/Process_identifier#Pidfile',
    text: 'Wikipedia page on pid files',
  },

]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Read and write calls will fail at runtime if <Code>open</Code> is called with incorrect <Code>modes</Code>.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>The second argument of <Code>open</Code> specifies the "mode" in which the file is opened. For example opening in write mode:</Text>
    <Snippet value={
`with open('some/path.txt', 'w') as f:
    f.write('foo')`
    } />

    <Text as={'p'} className="mt-2">If unspecified, mode defaults to <Code>'rt'</Code> which allows calling <Code>read</Code>, <Code>readline</Code> and <Code>readlines</Code>. <Code>'t'</Code> means the contents will be of type <Code>str</Code>.</Text>
    <Text as={'p'} className="mt-3">Python will throw an exception at runtime if a file read/write method is used without the file being opened in a mode that allows it. File modes are a source of bugs that will happen at runtime because there is no in-language feature preventing a developer from specifying modes that are invalid with the way the file will later be interacted with. If the invalid modes are not hit during testing then they will ultimately fail in prod.</Text>
    <Text as={'p'}>The full options are:</Text>
    <table className="d-table w-100 mt-6 mb-6" style={{columnGap: '30px', width: '100%'}}>
      <thead className="text-left">
        <tr>
          <th>Meaning</th>
          <th>Character</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Open for reading (default)</td>
          <td><Code>r</Code></td>
        </tr>
        <tr>
          <td>Encode as text (default)</td>
          <td><Code>t</Code></td>
        </tr>
        <tr>
          <td>Open for writing, truncating it first</td>
          <td><Code>w</Code></td>
        </tr>
        <tr>
          <td>Open and raise <Code>FileExistsError</Code> if file already exists</td>
          <td><Code>x</Code></td>
        </tr>
        <tr>
          <td>Open for writing, appending to the end of file if it exists</td>
          <td><Code>a</Code></td>
        </tr>
        <tr>
          <td>Open for updating (reading and writing)</td>
          <td><Code>+</Code></td>
        </tr>
        <tr>
          <td>Encode a bytes</td>
          <td><Code>b</Code></td>
        </tr>
        <tr>
          <td>For backwards compatibility. Has no effect in Python 3</td>
          <td><Code>U</Code></td>
        </tr>
      </tbody>
    </table>

    <Text as={'p'}>In Python 2.7 <Code>'U'</Code> simplified the handling of newlines across platforms, so a file's new lines work on Unix even if created Windows. This is the default behaviour in Python 3, so 'U' is not needed in Python 3.</Text>

    <p className="mt-6 mb-6">
      <Heading fontSize={4}>Reading</Heading>
      <Text as={'p'}>Python supports various methods of reading files: <Code>read</Code>, <Code>readline</Code> and <Code>readlines</Code>. To use the read methods <Code>'r'</Code> mode must be in use (or must be in <a href="#section-updating">read/write mode</a>):</Text>
      <Snippet value={
`with open('some/path.txt', 'r') as f:
    content = f.read()

with open('some/path.txt') as f:  # 'r' is the default mode
    content = f.read()

with open('some/path.txt') as f:
    content = f.readlines() # returns a list of lines

with open('some/path.txt') as f:
    line_one = f.readline() # returns one line at a time
    line_two = f.readline()
    line_three = f.readline()`}
      />

      <Text as={'p'} className="mt-3">Python will throw an exception if <Code>read</Code>, <Code>readline</Code> and <Code>readlines</Code> are used without the file being opened with <Code>'r'</Code> mode:</Text>
      <Snippet value={
`with open('some/path.txt', 'w') as f:
    content = f.read()

UnsupportedOperation: not readable`}
      />
    </p>

    <p className="mt-6 mb-6">
      <Heading fontSize={4}>Writing</Heading>
      <Text as={'p'}>Python supports <Code>write</Code> for writing to files. To use them <Code>'w'</Code>, or <Code>'a'</Code>, or <Code>'x'</Code> mode must be in use (or <a href="#section-updating">read/write mode</a>):</Text>

      <Snippet value={
`with open('some/path.txt', 'w') as f:  # open and truncate
    f.write('hello')

with open('some/path.txt', 'a') as f:  # open and not truncate
    f.write('hello')  # added to end of the existing contents

with open('some/path.txt', 'x') as f:  # open in exclusive mode
    f.write('hello')`}
      />

      <Text as={'p'} className="mt-3">Python will throw an exception if <Code>write</Code> is used without the file being opened with <Code>'w'</Code>, or <Code>'a'</Code>, or <Code>'x'</Code> mode (or <a href="#section-updating">read/write mode</a>):</Text>
      <Snippet value={
`with open('some/path.txt') as f:
    f.write('hello')

UnsupportedOperation: not writable`}
      />

      <Text as={'p'} className="mt-3">Python will also throw an exception if exclusive mode is used but the file already exists</Text>
      <Snippet value={
`with open('some/path.txt', 'x') as f:
    f.write('hello')

FileExistsError: [Errno 17] File exists`}
      />

      <Text as={'p'} className="mt-3">Exclusive mode is useful for creating thread-safe <a href="https://en.wikipedia.org/wiki/Process_identifier#Pidfile" target="_blank">pid files</a>:</Text>
      <Snippet value={
`try:
    with open("some/pid/file", "x") as f:
        f.write(...)
except FileExistsError:
    pass`}
    />

    </p>
    <span id="section-updating" />
    <p className="mt-6 mb-6">
      <Heading fontSize={4}>Updating</Heading>
      <Text as={'p'} className="mt-3">Python allows the file to be opened in both read and write mode by using <Code>'+'</Code> alongside <Code>'r'</Code>, <Code>'w'</Code>, or <Code>'a'</Code>, so there is no need to open the same file multiple times in different modes.</Text>
      <Text as={'p'}>Note though that <Code>'r+'</Code>, <Code>'a+'</Code>, and <Code>'w+'</Code> do very different things:</Text>
      <ul>
        <li><Code>'r+'</Code> opens an existing file for reading and writing without truncating it. The file pointer is at the <i>start</i> of the file, so <Code>read</Code> will return the current contents of the file, but <Code>write</Code> will override the current contents unless the file pointer is be moved to the end of the fie via <Code>f.seek(...)</Code></li>
        <li><Code>'a+'</Code> opens a file for reading and writing without truncating it. The file pointer is at the <i>end</i> of the file, so <Code>read</Code> will return an <Code>''</Code> (even if there is contents in the file), and <Code>write</Code> will append the end of the existing file contents. The file pointer can be moved to the start of the fie via <Code>f.seek(0)</Code></li>
        <li><Code>'w+'</Code> creates a new file or truncates an existing file, then opens it for reading and writing.</li>
      </ul>
      <Snippet value={
`with open('some/path.txt', 'r+') as f:
    content = f.read()
    f.write('some other contents')

with open('some/path.txt', 'w+') as f:  # 
    content = f.read()
    f.write('some other contents')

with open('some/path.txt', 'a+') as f:
    content = f.read()
    f.write('some other contents')`}
      />
    <Text as={'p'}>That means there is no need to do the following:</Text>
      <Snippet value={
`# don't do this. use 'w+', 'r+', or 'a+' instead
path = 'some/path.txt'
with open(path) as f_in:
    content = f_in.read()
    with open(path, 'w') as f_out:
        f_out.write('some other contents')`}
      />
    </p>
  </>
)


export {category, impact}