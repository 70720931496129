import React from 'react';

import {
  BorderBox,
  Box,
  Flex,
  Heading,
  StyledOcticon,
  Text,
  Label,
} from '@primer/components'
import { Repo } from '@primer/octicons-react'
import { Link } from "react-router-dom";

import GrantPrivateAccessButton from './GrantPrivateAccessButton'


const wrapperStyle = {
  background: 'white',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 6,
  borderBottomLeftRadius: 6,
}
export default function RepositorySearchResult(props) {
  return (
    <div style={wrapperStyle}>
      <BorderBox style={props.style}>
        <ul>{
          props.repositories.map(item => (
            <li className="Box-row" key={item.full_name}>
              <Link to={`/${item.full_name}`} target={props.target} style={{textDecoration: 'none'}}>
                <Flex>
                  <StyledOcticon icon={Repo} size={24} color="#959da5" marginTop={'5px'} />
                  <Box style={{marginLeft: '10px'}} className="text-small text-gray pb-0">
                    <Heading
                      fontSize={3}
                      className="link-gray-dark v-align-middle no-underline js-navigation-open"
                    >
                      {props.showOwner && `${item.owner.login}/`}{item.name}{item.private && <Label style={{verticalAlign: 'middle'}} outline variant="medium" ml={2}>Private</Label>}
                    </Heading>
                    <Text>{item.description}</Text>
                  </Box>
                </Flex>
              </Link>
            </li>
          ))
        }</ul>
      </BorderBox>
      <div className="text-right pt-2"><GrantPrivateAccessButton label="Login to search private repos" /></div>
    </div>
  )
}

RepositorySearchResult.defaultProps = {
  target: '_blank',
  style: {
    overflowY: 'auto',
    marginTop: '-4px',
    maxHeight: '400px',
  }
}
