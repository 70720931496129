import React, { useState, useContext, useEffect, useRef, createContext } from 'react'
import { Octokit } from "@octokit/rest/dist-node";
import { useHistory } from "react-router-dom";

import { useDebounce } from './useDebounce'
import { useFirebaseAuth } from './useFirebaseAuth'
import { searchRepositories } from '../github'


import {
  SET_LOGIN,
  useStore,
  getGitHubAuthToken,
  getIsAuthenticated,
  LOCAL_STORAGE_KEY_ACCESS_TOKEN,
  LOCAL_STORAGE_KEY_USERNAME,
  LOCAL_STORAGE_KEY_GITHUB_PERMISSION,
} from '../Store'


export const createOctokit = function(auth) {
  let octokit
  if (auth) {
    octokit = new Octokit({ auth });
    octokit.isAuthed = true
  } else {
    octokit = new Octokit()
    octokit.isAuthed = false
  }
  return octokit
}


export const OctokitProvider = ({children}) => {
  const { state } = useStore();
  const auth = getGitHubAuthToken(state)
  let octokit = createOctokit(auth)
  useEffect(() => {
    octokit = createOctokit(auth)
  }, [auth])

  return (
    <OctokitContext.Provider value={octokit}>
      {children}
    </OctokitContext.Provider>
  )
}


// TODO: split
export const useOctokit = function() {
  const octokit = useContext(OctokitContext)
  const { dispatch } = useStore();

  function setAuth({ accessToken, username, permission }) {
    dispatch({type: SET_LOGIN, payload: { accessToken, username }});
    if (accessToken === undefined)
      localStorage.removeItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN)
    else {
      localStorage.setItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN, accessToken)
    }
    if (username === undefined) {
      localStorage.removeItem(LOCAL_STORAGE_KEY_USERNAME)
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY_USERNAME, username)
    }

    localStorage.setItem(LOCAL_STORAGE_KEY_GITHUB_PERMISSION, permission)
  }


  return [octokit, setAuth]
}


export const useSearchRepositories = function(defaultSearchParams, defaultRepositories) {
  const history = useHistory();
  const [octokit, setOctokitAuth] = useOctokit()
  const [params, setParams] = useState({q: '', page: 1, orgs: [], owner: null, ...defaultSearchParams})
  const [retrieving, setRetrieving] = useState(false)
  const [repositories, setRepositories] = useState(defaultRepositories || [])
  const [totalCount, setTotalCount] = useState(defaultRepositories ? defaultRepositories.length : 0)
  const [handleGithubError, isRateLimited, setIsRateLimited] = useGithubRatelimit()
  const debouncedSearchTerm = useDebounce(params.q, 500);

  const { state } = useStore()
  const isAuthenticated = getIsAuthenticated(state)

  // prevent search on load
  const firstUpdate = useRef(true);

  useEffect(() => {
    // prevent search on load
    if ((defaultRepositories && defaultRepositories.length === 0) || !firstUpdate.current) {
      handleSearch()
    }
  }, [debouncedSearchTerm, params.page, params.owner, params.orgs, isAuthenticated])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
    }
  }, [])

  function handleSearch() {
    setRetrieving(true)
    searchRepositories(octokit, params.user, params.orgs, params.q, params.page) 
      .then(response => {
        setIsRateLimited(false)
        setRepositories(response.data.items)
        setTotalCount(response.data.total_count)
      })
      .catch(handleGithubError)
      .finally(() => setRetrieving(false))
  }

  function maybeFollowLink(q) {
    if (q.includes('github.com/')) {
      const split = q.split('/')
      const owner = split[split.length -2]
      const repository = split[split.length -1]
      if (owner && repository) {
        history.push(`/${owner}/${repository}/`)
      }
    }
  }

  function updateParams(newParams) {
    newParams.q && maybeFollowLink(newParams.q)
    setParams({...params, ...newParams})
  }

  return [updateParams, params, repositories, retrieving, totalCount, isRateLimited]
}


export function useGithubRatelimit() {
  const [isRateLimited, setIsRateLimited] = useState(false)
  const firebaseAuth = useFirebaseAuth()

  function handleGithubError(error) {
    error.status === 403 && setIsRateLimited(true)
    firebaseAuth.onAuthStateChanged(user => {
      user && setIsRateLimited(false)
    })
  }
  return [handleGithubError, isRateLimited, setIsRateLimited]
}

export const OctokitContext = createContext()


export const defaultExamplePythonRepos = [
  {
    "name": "ansible",
    "full_name": "ansible/ansible",
    "private": false,
    "owner": {"login": "ansible"},
    "description": "A radically simple IT automation platform",
  }, 
  {
    "name": "pytorch",
    "full_name": "pytorch/pytorch",
    "private": false,
    "owner": {"login": "pytorch"},
    "description": "Tensors and Dynamic neural networks in Python with strong GPU acceleration.",
  },
  {
    "name": "fastapi",
    "full_name": "tiangolo/fastapi",
    "private": false,
    "owner": {"login": "tiangolo"},
    "description": "A high performance, easy to learn, fast to code, ready for production.",
  },
  {
    "name": "scrapy",
    "full_name": "scrapy/scrapy",
    "private": false,
    "owner": {"login": "scrapy"},
    "description": "A fast high-level web crawling & scraping framework for Python.",
  },
  {
    "name": "pandas",
    "full_name": "pandas-dev/pandas",
    "private": false,
    "owner": {"login": "pandas-dev"},
    "description": "A flexible and powerful data analysis / manipulation library.",
  },
]

export const defaultExampleDjangoRepos = [
  {
    "name": "wagtail",
    "full_name": "wagtail/wagtail",
    "private": false,
    "owner": {"login": "wagtail"},
    "description": "A Django content management system focused on flexibility and user experience",
  },
  {
    "name": "django-rest-framework",
    "full_name": "encode/django-rest-framework",
    "private": false,
    "owner": {"login": "encode"},
    "description": "Web APIs for Django. :guitar",
  },
  {
    "name": "django-cms",
    "full_name": "django-cms/django-cms",
    "private": false,
    "owner": {"login": "django-cms"},
    "description": "The easy-to-use and developer-friendly enterprise CMS powered by Django",
  },
  {
    "name": "django-allauth",
    "full_name": "pennersr/django-allauth",
    "private": false,
    "owner": {"login": "pennersr"},
    "description": "Integrated set of Django applications addressing authentication, registration, account management",
  }, 
  {
    "name": "django-doctor-example",
    "full_name": "code-review-doctor/django-doctor-example",
    "private": false,
    "owner": {"login": "code-review-doctor"},
    "description": "Repo to demonstrate Code Review Doctor's code review skills",
  },
]