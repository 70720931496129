import React from 'react';

import { Link } from "react-router-dom";
import { Flash } from '@primer/components'

import { useGithubRatelimit } from '../hooks/useOctokit'


export default function RateLimited({ show }) {
  const [_, isRateLimited] = useGithubRatelimit()
  if (show === false) {
    return null
  }

  const elements = document.querySelectorAll('#firebaseui_container button')
  const showSignIn = elements.length > 0
  function handleClick() {
  	// yes this is indeed horrible
 	  elements[0].click()
  }

  return (
    <Flash
      variant="danger"
      marginBottom={'30px'}
    >
      <span>GitHub rate limit reached. </span> 
      { showSignIn ? <Link onClick={handleClick}>Sign in with GitHub</Link> : <span> Wait 30 seconds</span> }
      <span> and then try again.</span>
    </Flash>
  )
}

RateLimited.defaultProps = {
  show: false
}