import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_PERFORMANCE as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'

export const exampleTitle = 'tasks.py'

export const exampleBefore = (
`def task(admin_blog_ids):
    queryset = CommentModel.objects.all()
    if len(queryset) > 100:
        send_email_to_admin(queryset)`
)

export const exampleAfter = (
`def task(admin_blog_ids):
    queryset = CommentModel.objects.all()
    if queryset.count() > 100:
        send_email_to_admin(queryset)`
)

export const code = 'C9000'

export const title = 'Using len(queryset) instead of queryset.count()'

export const label = 'Misusing queryset.count()'

export const wordCode = 'queryset-length'

export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/fixing-inefficient-orm-calls-in-brownfield-django-projects-c5a1de39989',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/models/querysets/#count',
    text: 'Django migration documentation',
  }
]


export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      <code style={{fontSize: 'inherit'}}>len(queryset)</code> performs the count at application level. That is much less efficient than doing <code style={{fontSize: 'inherit'}}>queryset.count()</code>, which does the calculation at database level and just returns the count.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>Querysets are <a href="https://docs.djangoproject.com/en/3.1/topics/db/queries/#querysets-are-lazy" target="_blank" rel="noopener noreferrer">lazily evaluated</a> - meaning the records are not read from the database until code interact with the data. Thats's why we can do <code style={{fontSize: 'inherit'}}>queryset.all()</code> without downloading every records from the database.</Text>
    <Text as={'p'}>An example of something that interacts with the data is doing <code style={{fontSize: 'inherit'}}>len(queryset)</code>. This <i>will</i> read all the records in the queryset: effectively downloading the database over the internet. Not particularly efficient.</Text>
    <Text as={'p'}>On the other hand doing <code style={{fontSize: 'inherit'}}>queryset.count()</code> handles the calculation at the database level by executing SQL like <code style={{fontSize: 'inherit'}}>SELECT COUNT(*) FROM table</code>.</Text>
    <Text as={'p'}>That means using <code style={{fontSize: 'inherit'}}>queryset.count()</code> makes the code quicker, and improves database performance. Plus imagine the waste in downloading 5000 records only to check the length and throw them away at the end! Having said that though, if the records will need reading after the length if checked, then <code style={{fontSize: 'inherit'}}>len(queryset)</code> can be valid.</Text>
  </>
)


export const ogImage = "/og-image/C9000.png"

export {category, impact}