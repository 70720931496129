import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'


export const title = 'Unresolvable URL'

export const label = title

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/3.1/topics/http/urls/',
    text: 'Django URL dispatcher documentation',
  },
]

export const exampleBefore = (
`# in urls.py
urlpatterns = [
    path('articles', Articles.as_view(), name='example'),
    path('about-me', About.as_view(), name='example'),
]

# in index.html
<a href="{% url 'example' %}">Go to articles</a> <!-- Resolves /articles/ -->
<a href="{% url 'example' %}">Go to about</a> <!-- Resolves /articles/ but really we want /about-me/ -->`
)

export const exampleAfter = (
`# in urls.py
urlpatterns = [
    path('articles', Articles.as_view(), name='articles'),
    path('about-me', About.as_view(), name='about'),
]

# in index.html
<a href="{% url 'articles' %}">Go to articles</a> <!-- Resolve /articles/ -->
<a href="{% url 'about' %}">Go to about</a> <!-- Resolve /about/ -->`
)

export function Summary(props) {
  return (
    <>
      <Text as={'p'}>Django encourages giving URLs a name so they can be resoved later rather than hard coding the url.</Text>
      <Text as={'p'}>This approach makes changing URLs easier, however if two urls are given the same name then one of those URLs cannot be resolved by that name.</Text>
      <Text as={'p'}>Thia can be solved by giving each URL a unique name.</Text>
    </>
  )
}


export {category, impact}