import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_PERFORMANCE as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'


export const exampleTitle = 'tasks.py'

export const exampleBefore = (
`if queryset:
    trigger_tasks(queryset)`
)

export const exampleAfter = (
`if queryset.exists():
    trigger_tasks(queryset)`
)

export const code = 'C9003'

export const title = 'Checking queryset truthiness instead of checking queryset.exists()'

export const label = 'Misusing queryset truthiness'

export const wordCode = 'truthy-instead-exists'

export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/fixing-inefficient-orm-calls-in-brownfield-django-projects-c5a1de39989',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/topics/db/optimization/#use-queryset-exists',
    text: 'Django queryset optimization documentation',
  }
]

export function Summary(props) {
  return (
    <Text as="p" className={props.className}>
      This can load every row in the database table into memory because the queryset is evaluated. Checking if a queryset is truthy/falsey is much less efficient than checking <code style={{fontSize: 'inherit'}}>queryset.exists()</code>.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>Querysets are lazy, so do not do database reads until the data is interacted with by the code. Checking truthiness evaluates the queryset, and reads every row in the queryset in one go - loading all of them into memory.</Text>
    <Text as={'p'}>This is especially inefficient if the table is very large: it can cause a CPU spike on the database and use a lot of memory on the web server. So instead of pulling every single row from the table, check <code style={{fontSize: 'inherit'}}>queryset.exists()</code>, which simply tries to read a single record from the table in a very efficient way.</Text>
  </>
)


export const ogImage = "/og-image/C9003.png"
export {category, impact}