import React from 'react'

import Hero from './Hero'
import Features from '../../../components/Features'
import Testimonial from '../../../components/Testimonial'
import Seo from '../../../components/Seo'
import Example from './Example'
import HowItWorks from '../../../components/HowItWorks'
import ScrollSpy from '../../../components/ScrollSpy'
import Clients from '../../../components/Clients'


export default function(props) {

  return (
    <ScrollSpy>
      <Seo
        title={'Fix more Django bugs during code review'} 
        description={`3000 teams improve their code with us. Instantly audit your Django codebase for free.`}
        image={"/og-image/django.png"}
      />
      <Hero />
      <Clients />
      <Testimonial />
      <Features />
      <Example />
    </ScrollSpy>
  )
}
