import React, { Component } from 'react';

import { withRouter } from "react-router-dom";

const scrollToLocation = hash => {
  if (hash !== '') {
    let retries = 0;
    const id = hash.replace('#', '');
    const scroll = () => {
      retries += 0;
      if (retries > 50) return;
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => element.scrollIntoView(), 0);
      } else {
        setTimeout(scroll, 100);
      }
    };
    scroll();
  }
}

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			if (this.props.location.hash === "") {
				window.scrollTo(0, 0);
			} else {
				scrollToLocation(this.props.location.hash)
			}
		}
	}

	render() {
		return <></>
	}
}

export default withRouter(ScrollToTop)