import React from 'react'

import { Text } from '@primer/components'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'

import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'catalogue.py'

export const exampleBefore = (
`content = open('some/path.txt').read()`
)

export const exampleAfter = (
`with open('some/path.txt') as f:
    content = f.read()`
)

export const code = 'NoUnclosedFile'

export const ogImage = `/og-image/${code}.png`

export const title = 'Always close files when finished with them'

export const label = 'Unclosed file'

export const wordCode = 'no-unclosed-files'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/tutorial/inputoutput.html#tut-files',
    text: 'Python reading/writing files documentation',
  },
  {
    href: 'https://docs.python.org/3/library/stdtypes.html?highlight=context%20manager#context-manager-types',
    text: 'Python context manager documentation',
  },
  {
    href: 'https://stackify.com/python-garbage-collection/',
    text: 'Python Garbage Collection: What It Is and How It Works',
  },
  {
    href: 'https://www.python.org/dev/peps/pep-0020/#id2',
    text: 'The Zen of Python'
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Leaving files open degrades application performance and increases the risk of unexpected side effects. Using <Code>open</Code> as a context manager will close it automatically for you.
    </Text>
  )
}

const pythonCodeExample = (
`# bad: maybe not written to disk yet as the file was not closed
open('foo.txt', 'w').write('foo')

# also bad: maybe not written to disk as the file was not closed
f = open('bar.txt', 'w')
f.write('bar')

# better: written to disk when the file closed
f = open('baz.txt', 'w')
f.write('baz')
f.close()

# good: written to disk when the file closed and code is neater
with open('qux.txt', 'w') as f:
    f.write('qux')

`
)


export const explanation = (
  <>
    <Text as='p'>Computers have finite resources, including limits on how many files can be simultaneously open. Therefore leaving files open consumes more finite file handles than necessary. Under extreme conditions this could exhaust the number of allowed open files causing an "<Code>IOError: Too many open files</Code>" exception to be raised. Additionally, leaving files open consumes finite memory for longer than is necessary. Therefore for performance of the application it is preferable to closing file after finished with them.</Text>
    <Text as='p'>Python's <a href="https://stackify.com/python-garbage-collection/">garbage collection</a> will eventually close dangling unclosed files, but "eventually" should raise a red flag if the goal is to write predictable software. Indeed, Python3 and Cpython might implicitly close the file the instant they're no longer being used, while others might not for a long time. Under some conditions it might not happen at all. When pairing this problem with the zen of Python's <i>Explicit is better than implicit</i>, it's clear that it's better to not leave files open.</Text>
    <Text as='p'>Python can also be fuzzy around exactly <i>when</i> file changes are committed to disk: the data may not be written to disk until the file is closed:</Text>
      <Snippet value={pythonCodeExample}/>
    <Text as='p'>Windows (the Operating System) locks files opened by Python. This means leaving files open is not thread safe as one thread will lock the file preventing other threads from opening it - even if the other thread just wants to read from it.</Text>
  </>
)


export {category, impact}