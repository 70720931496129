import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_LOW as impact } from '../../constants'

export const exampleTitle = 'settings.py'

export const exampleBefore = (
`MIDDLEWARE =[
    "django.contrib.sessions.middleware.SessionMiddleware",
    "django.middleware.common.CommonMiddleware",
    ...
]
USE_TZ = False`
)

export const exampleAfter = (
`MIDDLEWARE =[
    "django.contrib.sessions.middleware.SessionMiddleware",
    "django.middleware.common.CommonMiddleware",
    ...
]`
)

export const code = 'C4005'

export const title = 'Redundant setting'

export const label = title

export const wordCode = 'redundant-setting'

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/settings/',
    text: 'Django settings documentation',
  },
]


export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Stating defaults add complexity when reading the code but does not change Django's behaviour.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>Django defines a set of default values that can be overridden in the settings file.</Text>
    <Text as={'p'}>Explicitly overriding the default values with the same value as the default value is redundant.</Text>
    <Text as={'p'}>Redundant settings increase noise when developers are reading the settings file, making the code harder to maintain.</Text>
  </>
)


export {category, impact}