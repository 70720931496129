import React from 'react'

import { Text } from '@primer/components'

import Snippet from '../../components/Snippet'
import Code from '../../components/Code'
import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = `values = list([1, 2, 3])`

export const exampleAfter = `values = [1, 2, 3]`

export const code = 'RewriteToLiteral'

export const ogImage = `/og-image/${code}.png`

export const title = 'Use literals instead of calling list/set/dict'

export const label = 'Use literals'

export const wordCode = 'use-comprehension'

export const furtherReading = [
  {
    href: 'https://en.wikipedia.org/wiki/Literal_(computer_programming)',
    text: 'Wikipedia page on literals',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Using list/set/dict literal syntax is simpler and computationally quicker than calling <Code>list()</Code>, <Code>set()</Code>, or <Code>dict()</Code>.
    </Text>
  )
}

const codeExample = (
`values = [1, 2, 3]

values = list([1, 2, 3])

values = list((1, 2, 3))`
)


export const explanation = (
  <>
    <Text as={'p'}>A literal is a terse and easily understood way to write a value without having to call the Python builtin function. A literal is syntax that the parser recognises as writing an object directly. Some examples of literal syntax:</Text>

    <table className="d-table w-100 mt-6 mb-6" style={{columnGap: '30px', width: '100%'}}>
      <thead className="text-left">
        <tr>
          <th>datatype</th>
          <th className="text-right pr-4">builtin call</th>
          <th>literal syntax</th>
        </tr>
      </thead>
      <tbody>
       <tr>
          <td>tuple</td>
          <td className="text-right pr-4"><Code>tuple([1, 2, 3])</Code></td>
          <td><Code>(1, 2, 3)</Code></td>
        </tr>
        <tr>
          <td>list</td>
          <td className="text-right pr-4"><Code>list([1, 2, 3])</Code></td>
          <td><Code>[1, 2, 3]</Code></td>
        </tr>
        <tr>
          <td>set</td>
          <td className="text-right pr-4"><Code>set([1, 2, 3])</Code></td>
          <td><Code>{'{1, 2, 3}'}</Code></td>
        </tr>
        <tr>
          <td>dict</td>
          <td className="text-right pr-4"><Code>dict(a=2)</Code></td>
          <td><Code>{"{'a': 2}"}</Code></td>
        </tr>
        <tr>
          <td>bytes</td>
          <td className="text-right pr-4"><Code>bytes('\x00', 'utf8')</Code></td>
          <td><Code>b'\x00'</Code></td>
        </tr>
        <tr>
          <td>int</td>
          <td className="text-right pr-4"><Code>int('42')</Code></td>
          <td><Code>42</Code></td>
        </tr>
        <tr>
          <td>float</td>
          <td className="text-right pr-4"><Code>float('4.2')</Code></td>
          <td><Code>4.2</Code></td>
        </tr>
        <tr>
          <td>bool</td>
          <td className="text-right pr-4"><Code>bool(1)</Code></td>
          <td><Code>True</Code></td>
        </tr>
      </tbody>
    </table>
    <Text as={'p'}>Calling <Code>dict()</Code>, <Code>list()</Code>, and <Code>set()</Code> is slower than using the literal syntax, because the function name must be looked up in the global scope.</Text>
    <Text as={'p'}>There is also the risk that <Code>dict()</Code>, <Code>list()</Code>, and <Code>set()</Code> have been rebound e.g, poor naming choices may result in a developer writing <Code>list = [1, 2, 3]</Code>. This is poor naming decisions, but would you expect poor naming decisions break the code?</Text>
    <Text as={'p'}>Given that these are completely equivalent:</Text>
    <Snippet value={codeExample} />
    <Text as={'p'}>Why not choose the easiest to read, write, and quickest to execute (the first one)?</Text>
  </>
)


export {category, impact}