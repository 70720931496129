import React, { useContext } from 'react';

import { Box, BorderBox, Truncate } from '@primer/components'

import { useProduct } from '../../hooks/useProduct'
import { CorpusContext } from '../../hooks/useCorpus'
import Card, { splitCodeAtDiff } from '../../components/BeforeAfter/CardWithContext'

import { normalizeDiff } from '../../components/Advice'
import * as constants from '../../constants'


export default function PullRequestFile(props) {
  const { corpus, routes } = useContext(CorpusContext)
  const productDetails = useProduct()

  // delta lines are lines that have suggested changes
  const seenLines = new Set()
  const claimedLines = new Set()
  const lines = []
  
  try {
    for (let message of props.messages) {
      for (var [lineNumber, lineCode] of message.diff.before) {
        if (!seenLines.has(lineNumber)) {
          seenLines.add(lineNumber)
          lines.push([lineNumber, lineCode])
        }
      }
    }
  } catch (err) {
    return null
  }

  // adding delta lines
  // loops in reverse, add each line to front of stack when hit a delta associate the stack with the line
  const messageLinesBefore = {}
  // problem: context lines above delta dont get claimed by the delta they belong to, so the next delta claims them
  // solution: only claim context lines no more than 2 below delta line, then loop again to claim context above delta lines
  for (var i = props.messages.length - 1; i >= 0; i--) {
    const message = props.messages[i]

    messageLinesBefore[message.line] = []
    const firstDifferentLine = getFirstDifferentLine(message)

    const start = lines.findIndex(([lineNumber, lineCode]) => lineNumber == firstDifferentLine)
    messageLinesBefore[message.line] = lines.splice(start, 1)
    claimedLines.add(start)
  }

  // adding context lines
  let stack = [lines[0]]
  for (var i = 1; i < lines.length -1; i++) {
    const lineNumber = lines[i][0]

    if (lineNumber === lines[i-1][0] +1) {
      stack.push(lines[i])
    } else {
      const head = stack[0][0]-1
      const tail = stack[stack.length-1][0] +1
      if (messageLinesBefore[head]) {
        messageLinesBefore[head].push(...stack)
      } else if (messageLinesBefore[tail]) {
        messageLinesBefore[tail].unshift(...stack)
      }
      stack = [lines[i]]
    }
  }


  // context lines are not unique per message: multiple messages can contain the same context, plus don't want to show
  // the same line as context line and as delta line. `seenLines` facilitates de-duplicating

  // reversing then reversing again as a hack workaround to unexpected results when delta line spans multiple lines e.g,
  // 176 field_b = models.TextField(
  // 177      null=True
  // 178 )
  // In this example, null=True is part of the delta, but we cannot tell as message. line just says where
  // delta starts, not what lines it spans. So we loop messages in reverse order (so later lines first) so a delta
  // can claim the lines as part of it's context. Then reverse it back.
  const path = props.path.replace("/tmp/patient/", "")
  return (
    <Box className="django-doctor-before-after"  style={{borderBottomLeftRadius: "6px", borderBottomRightRadius: "6px"}}>
      <BorderBox className="bg-gray p-2 f5 text-mono" style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: 0}}>
        <Truncate title={path} maxWidth={"100%"}>
          {path}
        </Truncate>
      </BorderBox>
      {
        props.messages.map((message, i)  => {
          const beforeLines = messageLinesBefore[message.line]
          const linesOffset = beforeLines.length > 0 ? beforeLines[0][0] : 1

          let lineBreak = false
          const nextMessage = props.messages[i+1]
          if (nextMessage) {
            const nextBeforeLines = messageLinesBefore[nextMessage.line]
            lineBreak = nextBeforeLines[0][0] !== beforeLines[beforeLines.length-1][0] +1
          }
          return (
            <Card
              exampleBefore={normalizeDiff(beforeLines)}
              exampleAfter={normalizeDiff(message.diff.after)}
              deltaBefore={normalizeDiff(message.diff.before)}
              deltaAfter={normalizeDiff(message.diff.after)}
              codes={message.message_ids}
              lineNumber={message.line}
              textClassName={"text-black"}
              linesOffset={linesOffset - 1}
              simulatePullRequest={false}
              hasDelta={message.diff.after !== null}
              lineBreak={lineBreak}
              isFirstMessage={i===0}
              isLastMessage={i===props.messages.length-1}
              key={`card-${message.path}:${message.line}`}
              minimalistAdvice={props.minimalistAdvice}
              repoBlobLink={`${props.repoBlobLink}/${path}`}
              scmKey={props.scmKey}
            />
          )
        })
      }
    </Box>
  )
}


function getFirstDifferentLine(message) {
  if (message.diff.after) {
    for (var i = 0; i < message.diff.before.length; i++) {
      if (message.diff.before[i][1] !== message.diff.after[i][1]) {
        return message.diff.before[i][0]
      }
    }
  }
  return message.line
}


PullRequestFile.defaultProps = {
  minimalistAdvice: true
}
