import React from 'react'

import { Text } from '@primer/components'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'

import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = (
`try:
    foo()
except ValueError or TypeError:
    pass`
)

export const exampleAfter = (
`try:
    foo()
except (ValueError, TypeError):
    pass`
)

export const code = 'ChainedExceptionHandler'

export const ogImage = `/og-image/${code}.png`

export const title = 'Incorrectly using or in exception handling'

export const label = title

export const wordCode = 'exception-handler-or'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/tutorial/errors.html',
    text: 'Python exception handling documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
       Catch multiple exception types using a tuple, not with <Code>or</Code> operator.
    </Text>
  )
}



export const explanation = (
  <>
    <Text as='p'>Using <Code>or</Code> in exception handler is valid Python insofar as the python parser does not complain, but using <Code>or</Code> to specify multiple exception classes does not do what you think it should. Take this code as example:</Text>
    <Snippet value={exampleBefore}/> 
    <Text as={'p'}>This will only catch <Code>ValueError</Code>. <Code>TypeError</Code> will not be caught because Python is treating the <Code>ValueError or TypeError</Code> as a binary comparison to evaluate rather than as a list of exception classes to catch. Python evaluates <Code>ValueError or TypeError</Code> to <Code>ValueError</Code> because <Code>ValueError</Code> is truthy.</Text>
    <Text as={'p'}>To have Python catch multiple exception types instead specify the multiple types as a tuple:</Text>
     <Snippet value={exampleAfter}/> 
  </>
)


export {category, impact}