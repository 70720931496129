import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'

export const exampleTitle = 'settings.py'

export const exampleBefore = (
`TEMPLATES = [
    {
        ...
        'DIRS': ['core/html'],
    },
]`
)

export const exampleAfter = exampleBefore

export const code = 'C4001'

export const lineNumber = 4  // which line to show the comment on

export const title = 'Relative path in TEMPLATES setting'

export const label = 'Use absolute TEMPLATES path'

export const wordCode = 'template-dir-relative'

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/settings/#templates',
    text: 'Django TEMPLATES documentation',
  },
]

export function Summary(props) {
  return (
        <Text as={'p'} className={props.className}>DIRS must be absolute paths. Relative paths will not work.</Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>The TEMPLATE setting is a list of the template engines used when finding template files and rendering them.</Text>
    <Text as={'p'}>The DIRS key within the TEMPLATE list denotes the directories where the engine should look for template source files.</Text>
  </>
)


export {category, impact}