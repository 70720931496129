import React from 'react'

import { Text } from '@primer/components'
import { Helmet } from "react-helmet";

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'models.py'

export const exampleBefore = (
`class CommentModel(models.Model):
    body = models.TextField(null=True)
    date = models.DateField()`
)

export const exampleAfter = (
`class CommentModel(models.Model):
    body = models.TextField(default="", blank=True)
    date = models.DateField()`
)

export const code = 'C2000'

export const title = 'Nullable string field'

export const label = title

export const wordCode = 'nullable-string-field'

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#django.db.models.CharField',
    text: 'CharField Django documentation',
  },
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#django.db.models.TextField',
    text: 'TextField Django documentation',
  },
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#null',
    text: 'Django Field null documentation',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      ORM queries and Python code are made more complex because values will sometimes be <code style={{fontSize: 'inherit'}}>None</code> and other times <code style={{fontSize: 'inherit'}}>str</code>.
    </Text>
  )
}


export const explanation = (
  <>
    <Text as={'p'}>The null attribute specifies that the field can be None (null at database level). This is useful for optional fields.</Text>
    <Text as={'p'}>This adds complexity as the data type is inconsistent: some instances may be strings and others None. This complicates ORM queries and extra code must guard against performing str operations on a NoneType.</Text>
    <Text as={'p'}>This problem is avoided by specifying default='' instead. The database will even throw an exception if there is an attempt to explicitly save None, thus avoiding bad data.</Text>
  </>
)

export {category, impact}