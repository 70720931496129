import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'

export const exampleTitle = 'template.html'

export const exampleBefore = `<img src="/static/logo.png" />`

export const exampleAfter = (
`{% load static %}
<img src="{% static 'logo.png' %}" />`
)

export const code = 'C7001'

export const title = 'Hard-coded static asset URL in template'

export const label = 'Hard-coded static URL'

export const wordCode = 'hard-coded-static-url'

export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/did-you-know-static-is-not-just-about-s3-cdf9d333aaf3',
    text: 'Our blog post on the topic',
  },
]

export function Summary(props) {
  const staticTag = '"{% static ... %}"'
  return (
    <>
      <Text as={'p'}>Hard-coding static asset urls is brittle because the place the files are stored depends on the `STATICFILES_STORAGE` used - so if in prod the storage backend uploads to S3 or even renames the file then this hard-coded URL will break.</Text>
      <Text as={'p'}>Using {staticTag} solves that as it knows exactly where the files are stored.</Text>
    </>
  )
}

export const ogImage = "/og-image/C7001.png"
export {category, impact}