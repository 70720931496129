
import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'models.py'

export const exampleBefore = (
`from dataclasses import dataclass
\t
@dataclass
class FooBarClass:
    pass`
)

export const exampleAfter = (
`from dataclasses import dataclass
\t
@dataclass(frozen=True)
class FooBarClass:
    pass`
)

export const code = 'ExplicitFrozenDataclass'

export const ogImage = `/og-image/${code}.png`

export const title = 'Using mutable dataclass'

export const label = title

export const wordCode = 'freeze-dataclasses'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/dataclasses.html#frozen-instances',
    text: 'Python documentation on frozen dataclass'
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Use <Code>frozen=True</Code> to make the <Code>dataclasses</Code> immutable and hashable.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as="p">With <Code>@dataclass(frozen=True)</Code> then assigning to fields after the object has been instantiated will raise a <Code>FrozenInstanceError</Code>. This emulates read-only frozen instances, and gives the advantages of immutability.</Text>
    <Text as="p">Frozen <Code>dataclass</Code> will also generate a <Code>__hash__()</Code> method on the class. This allows the instance to be used in <Code>set</Code> and <Code>dict</Code> objects. Attempting to add a non-hashable object to a <Code>dict</Code> will result in <Code>TypeError: unhashable type</Code></Text>
    <Text as="p">An immutable object is an object that cannot be modified. Strings are an example of an immutable object. There are some advantages to immutability:</Text>
    <ul className="bullet-list-blue">
      <li>Thread safety: Threads can safely share immutable objects because none of the threads can mutate the object.</li>
      <li>Less moving parts: A developer can see the object is immutable so they can remove "<i>what if this gets changed</i>" from their mental model of how the code works.</li>
      <li>Less side effects: Mutable objects may be inadvertently updated by buggy code, resulting in hard-to-debug bugs. This is not possible with immutable objects.</li>
    <Text as="p"></Text>
    </ul>
  </>
)

export {category, impact}
