import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_SECURITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'


export const exampleBefore = `SESSION_COOKIE_HTTPONLY = False`

export const exampleAfter = (
`from ast import literal_eval
from os import getenv

# feature flagged so can turn off in local development
SESSION_COOKIE_HTTPONLY = literal_eval(getenv("HTTPS_ONLY", "True"))`
)

export const code = 'C4015' 


export const title = 'Session cookie is vulnerable to XSS attack'

export const label = title

export const wordCode = 'missing-session-cookie-http-only'

export const furtherReading = [
  {
    href: 'https://dev.to/djangodoctor/hacking-django-websites-session-hijacking-with-xss-2l8o',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/topics/http/sessions/',
    text: 'Django session documentation',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/middleware/#module-django.middleware.security',
    text: 'Django security documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Your website is vulnerable because the <code style={{fontSize: 'inherit'}}>SESSION_COOKIE_HTTPONLY</code> setting is not set - so hackers have an easier time stealing your users' session cookies using an XSS attack.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'} className="mb-3"><code style={{fontSize: 'inherit'}}>SessionMiddleware</code> marks the session cookie as httpOnly when <code style={{fontSize: 'inherit'}}>SESSION_COOKIE_HTTPONLY = True</code>, so cookie cannot be read with nefarious JavaScript in the browser.</Text>
    <Text as={'p'} className="mb-3">If a bad actor successfully ran nefarious JavaScript on your website using an XSS attack then they could steal the user session cookie and authenticate as that user.</Text>
  </>
)


export {category, impact}