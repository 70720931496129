import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import { CATEGORY_TESTS as category, IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'tests.py'

export const exampleBefore = (
`class TestFeature(unittest.TestCase):
    def test_feature(self):
        self.assertTrue(value_one, value_two)`
)

export const exampleAfter = (
`class TestFeature(unittest.TestCase):
    def test_feature(self):
        self.assertEqual(value_one, value_two)`
)

export const code = 'NoAssertTrueForComparison'

export const title = 'Avoid misusing assertTrue in tests'

export const label = title

export const wordCode = 'avoid-misusing-assert-true'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/unittest.html#unittest.TestCase.assertTrue',
    text: 'Python documentation on TestCase.assertTrue'
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      <Code>assertTrue</Code> is not for comparing arguments. Consider <Code>assertEqual</Code> or others instead.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>The purpose of the second argument of <Code>assertTrue</Code> is not for comparing equality with the first argument. It's actually the message that should be shown if the first argument is not truthy.</Text>
    <Text as={'p'}>Calling <Code>assertTrue</Code> with two objects and expecting them to be compared will not work.</Text>
  </>
)

export {category, impact}
