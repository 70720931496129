import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'

export const exampleTitle = 'template.html'

export const exampleBefore = `<a href="/articles/">Go to articles</a>`

export const exampleAfter = `<a href="{% url 'articles-list' %}">Go to articles</a>`

export const code = 'C7000'

export const title = 'Hard-coded URL in template'

export const label = title

export const wordCode = 'hard-coded-url'

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/3.1/topics/http/urls/#reverse-resolution-of-urls',
    text: 'Django URL dispatcher documentation',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/templates/builtins/#url',
    text: 'Django {% url %} template tag documentation',
  },
]


export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Hard-coding urls makes changing URLs harder, and makes linking to the wrong page easier - so harms maintainability.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>Django encourages giving URLs a name so they can be resoved later (rather than hard coding the URL).</Text>
    <Text as={'p'}>This be taken advantage of by defining a `name` in the url.py entry, and then using that name in templates.</Text>
  </>
)


export const ogImage = "/og-image/C7000.png"
export {category, impact}