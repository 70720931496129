import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_SECURITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'

export const exampleTitle = 'settings.py'

export const exampleBefore = (
`MIDDLEWARE = [
    'django.middleware.common.CommonMiddleware',
    'django.contrib.sessions.middleware.SessionMiddleware',
    ...
]`)

export const exampleAfter = (
`MIDDLEWARE = [
    'django.middleware.common.CommonMiddleware',
    'django.contrib.sessions.middleware.SessionMiddleware',
    'django.middleware.clickjacking.XFrameOptionsMiddleware',
    ...
]`
)

export const code = 'C4008'

export const title = 'Clickjacking protection not activated'

export const label = title

export const wordCode = 'missing-xframe-middleware'

export const furtherReading = [
  {
    href: 'https://dev.to/djangodoctor/django-hacking-clickjacking-4hg2',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/clickjacking/',
    text: 'Django clickjacking documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Your website is vulnerable to clickjack attack because the <code style={{fontSize: 'inherit'}}>MIDDLEWARE</code> setting is missing <code style={{fontSize: 'inherit'}}>django.middleware.clickjacking.XFrameOptionsMiddleware</code> - so users can be tricked into interacting with your website without realising.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'} className="mb-3">If unprotected, an invisible iframe pointing at your site can be placed on top of a innocent looking button on the malicious web page - so when the user clicks the innocent button they'are actually interacting with a button on your web page.</Text>
    <Text as={'p'} className="mb-3">You may find iframes useful though, so, so Django allows setting the policy to SAMEORIGIN via <code style={{fontSize: 'inherit'}}>X_FRAME_OPTIONS = 'SAMEORIGIN'</code></Text>
  </>
)



export {category, impact}