import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_SECURITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'

export const exampleTitle = 'settings.py'

export const exampleBefore = (
`MIDDLEWARE = [
    'django.middleware.common.CommonMiddleware',
    'django.contrib.sessions.middleware.SessionMiddleware',
    ...
]`
)

export const exampleAfter = (
`MIDDLEWARE = [
    'django.middleware.common.CommonMiddleware',
    'django.contrib.sessions.middleware.SessionMiddleware',
    'django.middleware.csrf.CsrfViewMiddleware',
    ...
]`)

export const code = 'C4009'

export const title = 'Cross Site Request Forgery protection not activated'

export const label = 'CSRF protection not activated'

export const wordCode = 'missing-csrf-middleware'

export const furtherReading = [
  {
    href: 'https://dev.to/djangodoctor/hacking-django-websites-cross-site-request-protection-2i1i',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/csrf/',
    text: 'Django CSRF documentation',
  },
  {
    href: 'https://owasp.org/www-community/attacks/csrf',
    text: 'OWASP CSRF documentation'
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Your website is vulnerable to CSRF attacks because the <code style={{fontSize: 'inherit'}}>MIDDLEWARE</code> setting is missing <code style={{fontSize: 'inherit'}}>CsrfViewMiddleware</code> - so a hacker can fool your website into thinking a request is coming from a logged in user.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'} className="mb-3">If unprotected, when one of your logged-in users accesses a malicious website, that website can perform a request to your website and your website will think the request was performed by the user.</Text>
    <Text as={'p'} className="mb-3">This can be avoided by using CSRF protection - which adds a cycling string to forms on your website. Django then checks if the submitted string is as expected. If it's not then the request probably did not come from your website.</Text>
    <Text as={'p'} className="mb-3">Additionally, making sure that GET request are free from side-effects (so don't submit a form that creates a database entry on GET) helps avoud this problem.</Text>
  </>
)


export {category, impact}