import React from 'react';
import { Box, Text, Heading } from '@primer/components'
import { useProduct } from '../hooks/useProduct'

export default function() {
  const productDetails = useProduct()

  const src = productDetails.id == 'djangodoctor' ? '/diagram-dd.png' : '/diagram.png'
  return (
    <Box className="text-white pt-8" style={{background: 'linear-gradient(to right, #363795, #005C97)'}}>
      <Box className="container-lg pl-0 pr-0 pl-md-4 pr-md-4">
        <Heading className="mb-4 text-center">How it works</Heading>
        <Box alignItems="center" className="text-center mb-4 mb-md-0">
          <Box style={{ padding: 20, borderRadius: 6, background: 'white'}}>
            <img src={src} style={{width: '100%'}} />
          </Box>
        </Box>
        <p className="mb-4 mt-4" style={{fontSize: "2em"}}>Security of your code is our top priority. We handle your codebase with care:</p>
        <ol style={{fontSize: "1.25em"}}>
          <li>We are <a style={{color: 'white'}} href={productDetails.gitHubMarketplaceLink}>verified by GitHub</a> and follow their <a href="https://docs.github.com/en/rest/guides/best-practices-for-integrators"  style={{color: 'white'}}>security best practices</a>.</li>
          <li>Your repository is cloned during the analysis process and deleted immediately after. No human <i>ever</i> sees your code.</li>
          <li>The analysis occurs on <a style={{color: 'white'}} href="https://aws.amazon.com/lambda/" target="_blank">AWS lambda</a> serverless functions which are sandboxed to the repository being analysed.</li>
          <li>The lambda functions run in a locked-down <a style={{color: 'white'}} href="https://aws.amazon.com/vpc/" target="_blank">Logically Isolated Virtual Private Cloud</a> that only accepts HTTPS requests from GitHub and Bitbucket.</li>
          <li>Over the years we've served thousands of happy customers ranging from the Public Sector, Startups, and everything in-between.</li>
        </ol>
      </Box>
    </Box>
  );
}
