import React, { useContext } from 'react'

import LazyLoad from 'react-lazyload'
import { withRouter } from 'react-router-dom'

import { useStore, getIsAuthenticated } from '../../Store'
import Hero from './Hero'
import Features from '../../components/Features'
import Testimonial from '../../components/Testimonial'
import Seo from '../../components/Seo'
import Example from './Example'
import HowItWorks from '../../components/HowItWorks'
import ScrollSpy from '../../components/ScrollSpy'
import Clients from '../../components/Clients'

import { useProduct } from '../../hooks/useProduct'


export default withRouter(function(props) {
  const { state } = useStore()
  const productDetails = useProduct()
 
  return (
    <ScrollSpy>
      <Seo
        title={'Fix bugs hidden in your codebase'}
        description={`3000 teams improve their code with us. Audit your Python or Django instantly for free.`}
      />
      <Hero
        headline={`Fix more bugs during code review`}
        linkUrl={`${productDetails.gitHubMarketplaceLink}`}
        linkText={"Check repository"}
      />
      <Clients />
      <Testimonial />
      <Features />
      <Example />
    </ScrollSpy>
  )
})
