import React from 'react'
import { Flex, Box, Text, Heading, Button } from '@primer/components'
import { Link } from 'react-router-dom'

export default function Features(props) {
  return (
    <Box className=" position-relative pt-md-8 pt-4 pb-md-8 pb-4 pl-4 pr-4 text-white" style={{background: 'linear-gradient(to right, #363795, #005C97)'}}>
      <Box className="container-xl mb-4 mb-md-0 text-center">
        <Heading>Works with tools you use</Heading>
      </Box>
      <Box as="ul" className="d-md-flex list-style-none container-md mt-8 mb-8 f3 text-center" justifyContent='space-between'>
        <li className="mb-8 mb-md-0">
          <img alt="Python checks" title="Python checks" src="/python-logo.png" style={{ maxWidth: '100px'}} />
          <ul style={{listStyleType: 'none'}} className="mt-3">
            <li>Detect typos</li>
            <li><Link to={'/features/python/best-practice/maintainability'} className="text-white text-underline">Avoid mistakes</Link></li>
            <li><Link to={'/features/python/best-practice/bugs'} className="text-white text-underline">Prevent bugs</Link></li>
            <li><Link to={'/features/python/best-practice/testing'} className="text-white text-underline">Improve tests</Link></li>
            <li><Button as={Link} to='/features/python' className="mt-4">Read more</Button></li>
          </ul>
        </li>
        <li className="mb-8 mb-md-0">
          <img alt="Django checks" title="Django checks" src="/django-logo.png" style={{ maxWidth: '100px'}} />
          <ul style={{listStyleType: 'none'}} className="mt-3">
            <li>Avoid bugs</li>
            <li><Link to={'/features/django/best-practice/maintainability'} className="text-white text-underline">Best practice</Link></li>
            <li><Link to={'/features/django/best-practice/security'} className="text-white text-underline">Improve security</Link></li>
            <li><Link to={'/features/django/best-practice/performance'} className="text-white text-underline">ORM performance</Link></li>
            <li><Button as={Link} to='/features/django' className="mt-4">Read more</Button></li>
          </ul>
        </li>
        <li className="mb-8 mb-md-0">
          <img alt="GitHub integration" title="GitHub integration" src="/github-logo.png" style={{ maxWidth: '100px'}} />
          <ul style={{listStyleType: 'none'}} className="mt-3">
            <li>Review PRs</li>
            <li>One click fix</li>
            <li>Clear advice</li>
            <li>Delta aware</li>
            <li><Button as={Link} to='/features/github' className="mt-4">Read more</Button></li>
          </ul>
        </li>
        <li className="mb-8 mb-md-0">
          <img alt="Bitbucket integration" title="Bitbucket integration" src="/bitbucket-logo.png" style={{ maxWidth: '100px'}} />
          <ul style={{listStyleType: 'none'}} className="mt-3">
            <li>Review PRs</li>
            <li>Fix suggested</li>
            <li>Clear advice</li>
            <li>Delta aware</li>
            <li><Button as={Link} to='/features/bitbucket' className="mt-4">Read more</Button></li>
          </ul>
        </li>
      </Box>
      <Box className="container-xl mt-8 mb-md-0 text-center f3">
        <HowItWorksLink show={props.showHowItWorksLink} />
      </Box>
    </Box>
  )
}



function HowItWorksLink(props) {
  if (!props.show) {
    return null
  }
  return (
    <>
      Read about <Link className="text-white text-underline" to={'/features'}>how it works</Link>.
    </>
  )
}


Features.defaultProps = {
  showHowItWorksLink: true
}