import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_PERFORMANCE as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'

export const exampleTitle = 'tasks.py'

export const exampleBefore = (
`def trigger_tasks(queryset):
    for item in queryset:
        run_async_task(item.page.id)`
)

export const exampleAfter = (
`def trigger_tasks(queryset):
    for item in queryset:
        run_async_task(item.page_id)`
)

export const code = 'C9001'

export const title = 'Not using foreign keys directly'

export const label = title

export const wordCode = 'indirect-foreign-key'

export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/fixing-inefficient-orm-calls-in-brownfield-django-projects-c5a1de39989',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/topics/db/optimization/#use-foreign-key-values-directly',
    text: 'Django database optimization documentation',
  }
]


export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      When working with foreign keys, accessing the related field will result in a database read. That can be eliminated by using <code style={{fontSize: 'inherit'}}>*_id</code>, which is the foreign key value that Django has already cached on the object to make this scenario more efficient.
    </Text>
  )
}

export const ogImage = "/og-image/C9001.png"

export {category, impact}