import React, { useEffect} from 'react';

import { Box, Text, Heading } from '@primer/components'

import GrantPrivateAccessButton from '../../components/GrantPrivateAccessButton'


export default function() {
  // GrantPrivateAccessButton does not place the component where we expect, it just overrides the one in header
  // yes this sucks. workaround this https://github.com/firebase/firebaseui-web-react/issues/155

  function handleAfterLogin () {
    window.location.reload(false)
  }


  return (
    <Box class="blankslate" style={{textAlign: 'center'}}>
      <Text fontSize={'42px'}><span  role="img" aria-label="fox">🦊</span></Text>
      <Heading size={1}>Repository not found.</Heading>
      <Text as="p">You must login to check a private repository</Text>
      <GrantPrivateAccessButton label={"Login to check repo"} asButton={true} onLogin={handleAfterLogin} />
    </Box>    
  )
}