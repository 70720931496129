import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_PERFORMANCE as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'

export const exampleTitle = 'helpers.py'

export const exampleBefore = (
`def trigger_task(queryset):
    if queryset.count() > 0:
        trigger_tasks(queryset)`
)

export const exampleAfter = (
`def trigger_task(queryset):
    if queryset.exists():
        trigger_tasks(queryset)`
)

export const code = 'C9002'

export const title = 'Comparing queryset.count() instead of checking queryset.exists()'

export const label = 'Not using queryset.exists()'

export const wordCode = 'count-instead-exists'

export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/fixing-inefficient-orm-calls-in-brownfield-django-projects-c5a1de39989',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/topics/db/optimization/#use-queryset-exists',
    text: 'Django queryset optimization documentation',
  }
]

export function Summary(props) {
  return (
    <Text as="p" className={props.className}>
      Comparing <code style={{fontSize: 'inherit'}}>queryset.count()</code> is less efficient than checking <code style={{fontSize: 'inherit'}}>queryset.exists()</code>, so use <code style={{fontSize: 'inherit'}}>querySet.count()</code> if you only want the count, and use <code style={{fontSize: 'inherit'}}>queryset.exists()</code> if you only want to find out if at least one result exists.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}><code style={{fontSize: 'inherit'}}>queryset.count()</code> performs an SQL operation that scans every row in the database table. <code style={{fontSize: 'inherit'}}>queryset.exists()</code> simply reads a single record in the most optimized way (removing ordering, clearing any user-defined <code style={{fontSize: 'inherit'}}>select_related(...)</code> or <code style={{fontSize: 'inherit'}}>distinct(...)</code>.</Text>
    <Text as={'p'}>This is especially relevant for Postgres because <code style={{fontSize: 'inherit'}}>count()</code> can be very expensive.</Text>
  </>
)


export const ogImage = "/og-image/C9002.png"

export {category, impact}