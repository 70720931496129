import React from 'react';

import {
  Button,
  SelectMenu,
  StyledOcticon,
} from '@primer/components'
import { GitBranch, TriangleDown } from '@primer/octicons-react'

export default function(props) {
  // todo: receive list of checks in messages
  if (props.show === false) {
    return null
  }
  return (
    <SelectMenu className="mb-3">
      <Button as="summary">
      <StyledOcticon
        icon={GitBranch}
        size={16}
        marginRight={'6px'}
      />
        {props.branch}
        <StyledOcticon
          icon={TriangleDown}
          size={12}
          marginLeft={'6px'}
        />
      </Button>
      <SelectMenu.Modal>
        <SelectMenu.Header>Switch branches</SelectMenu.Header>
        <SelectMenu.List> {
          props.branches.map(({ name }) => (
            <SelectMenu.Item
              key={name}
              onClick={() => props.handleBranchChange(name)}
              style={{cursor: 'pointer', fontSize: '14px'}}
              title={name}
            >
              { name }
            </SelectMenu.Item>
          ))
        }
        </SelectMenu.List>
      </SelectMenu.Modal>
    </SelectMenu>
  )
}