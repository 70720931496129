import React from 'react'

import { Text } from '@primer/components'

import Snippet from '../../components/Snippet'
import Code from '../../components/Code'
import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = (
`class FooBarClass(Bar):
    def foo(self, bar):
        super(Foo, self).foo(bar)`
)

export const exampleAfter = (
`class FooBarClass(Bar):
    def foo(self, bar):
        super().foo(bar)`
)

export const code = 'NoRedundantArgumentsSuper'

export const title = 'Unnecessary arguments in super call'

export const label = 'Super call arguments'

export const wordCode = 'redundant-super-args'

export const furtherReading = [
  {
    href: 'https://www.python.org/dev/peps/pep-3135',
    text: 'Python documentation for super call for new-style classes',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      It's unnecessary to use arguments when calling super for the parent class.
    </Text>
  )
}


const codeExample = `
class FooBarClass(Bar):
    def foo(self):
        ...
        return super().foo()


class FooBarClass(Bar):
    def foo(self):
        ...
        return super(Foo, self).foo()

`

export const explanation = (
  <>
    <Text as={'p'}>Explicitly passing in both the class and instance breaks the DRY (Don't Repeat Yourself) rule. This make the code brittle with regards to changing the class name, and is often considered inelegant by many.</Text>
    <Text as={'p'}>As of Python 3, there is no need to explicitly specify the class name and instance when calling <Code>super()</Code>. If they are not provided, Python will default to the current class and the current instance.</Text>
    <Text as={'p'}>Python 2.7 required specifying arguments, but note that Python 2.7 has already reached "End Of Life" and is no longer supported by the Python Software Foundation. For this reason your code might not need to support Python 2.7 either.</Text>
    <Text as={'p'}>Moreover, it takes more than <i>specifying arguments in super</i> to make your codebase backwards compatible with Python 2.7: you must not use any new feature of Python 3...ever. That's some serious dedication to a dead language.</Text>
    <Text as={'p'}>Given that these two are completely equivalent:</Text>
    <Snippet value={codeExample} />
    <Text as={'p'}>Why not choose the easiest to both read and write (the first one)?</Text>
  </>
)


export {category, impact}