import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_SECURITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'

export const exampleTitle = 'settings.py'

export const exampleBefore = (
`MIDDLEWARE = [
    'django.middleware.csrf.CsrfViewMiddleware',
    ...
]`
)

export const exampleAfter = (
`MIDDLEWARE = [
    'django.middleware.csrf.CsrfViewMiddleware',
    ...
]

# feature flagged so can turn off in local development
CSRF_COOKIE_SECURE = literal_eval(getenv("HTTPS_ONLY", "True"))`)

export const code = 'C4016'

export const title = 'Cross Site Request Forgery protection weak to packet sniffing'

export const label = 'CSRF protection packet sniffing'

export const wordCode = 'missing-csrf-secure'

export const furtherReading = [
  {
    href: 'https://dev.to/djangodoctor/hacking-django-websites-stealing-sessions-with-packet-sniffing-n9k',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://en.wikipedia.org/wiki/Packet_analyzer',
    text: 'Wikipedia page for packet sniffing'
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/topics/http/sessions/',
    text: 'Django session documentation',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/middleware/#module-django.middleware.security',
    text: 'Django security documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Your website is vulnerable because the <code style={{fontSize: 'inherit'}}>CSRF_COOKIE_SECURE</code> setting is not set - so hackers have an easier time stealing your CSRF cookies on HTTP connections, allowing them to circumvent your CSRF protection.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'} className="mb-3"><code style={{fontSize: 'inherit'}}>CsrfMiddleware</code> marks the CSRF cookie as secure when <code style={{fontSize: 'inherit'}}>CSRF_COOKIE_SECURE = True</code>, to make the browser only send cookie over secure HTTPS connection.</Text>
    <Text as={'p'} className="mb-3">Cookies sent over insecure HTTP are unencrypted, so hackers can steal the CSRF cookie using a packet sniffer - allowing them to use it to trick the browser into thinking a request on their website was performed on your website by the logged a user.-.</Text>
  </>
)


export const ogImage = "/og-image/C4016.png"
export {category, impact}