import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_SECURITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'

export const exampleTitle = 'settings.py'

export const exampleBefore = `SESSION_COOKIE_SECURE = False`

export const exampleAfter = (
`from ast import literal_eval
from os import getenv

# feature flagged so can turn off in local development
SESSION_COOKIE_SECURE = literal_eval(getenv("HTTPS_ONLY", "True"))`
)


export const code = 'C4014'

export const title = 'Session cookie is vulnerable to packet sniffing attack'

export const label = 'Session cookie packet sniffing'

export const wordCode = 'missing-session-cookie-secure'

export const furtherReading = [
  {
    href: 'https://dev.to/djangodoctor/hacking-django-websites-stealing-sessions-with-packet-sniffing-n9k',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://en.wikipedia.org/wiki/Packet_analyzer',
    text: 'Wikipedia page for packet sniffing'
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/topics/http/sessions/',
    text: 'Django session documentation',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/middleware/#module-django.middleware.security',
    text: 'Django security documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Your website is vulnerable because the <code style={{fontSize: 'inherit'}}>SESSION_COOKIE_SECURE</code> setting is not set - so hackers have an easier time stealing your users' session cookies on HTTP connections.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'} className="mb-3"><code style={{fontSize: 'inherit'}}>SessionMiddleware</code> marks the session cookie as secure when <code style={{fontSize: 'inherit'}}>SESSION_COOKIE_SECURE = True</code>, to make the browser only send cookie over secure HTTPS connection.</Text>
    <Text as={'p'} className="mb-3">Cookies sent over insecure HTTP are unencrypted, so hackers can steal the session cookie using a packet sniffer - allowing them to effectively copy and paste it into their browser and be logged in as the user.</Text>
  </>
)


export {category, impact}