export const STORAGE_KEY_REPOSITORIES = 'repositories'
export const STORAGE_KEY_ORGANISATION = 'organisation'
export const PER_PAGE = 10

export const retrieveOrganisation = (octokit, org) => octokit.orgs.get({ org })

export const searchRepositories = (octokit, user, orgs, q, page) => {
  let searchString = `${q}+language:python+`
  searchString += orgs.map(org => `org:${org}`).join('+')
  if (user) {
    searchString += `+user:${user}`
  }
  return octokit.search.repos({'q': searchString, 'page': page, 'per_page': PER_PAGE});
}

export const searchOrganisations = (octokit, q) => {
  const searchString = `${q}+type:org`
  return octokit.search.users({'q': searchString, 'per_page': 25});
}

export const retrievingCurrentUserRepositories = (octokit) => {
 return octokit.repos.listForAuthenticatedUser({per_page: 15});  
}


export const listForOrg = (octokit, org) => octokit.repos.listForOrg({org});
export const listForUser = (octokit, user) => octokit.repos.listForUser({user});


// TODO: handle pagination as otherwise wont have accurate list of branches
export const retrieveBranches = (octokit, owner, repository) => octokit.repos.listBranches({ owner, repo: repository, per_page: 100 })

export const retrieveRepository = (octokit, owner, repository) => octokit.repos.get({ owner, repo: repository })

export const listOrgsForAuthenticatedUser = (octokit) => octokit.orgs.listForAuthenticatedUser();  

export const listInstallationsForAuthenticatedUser = octokit => octokit.apps.listInstallationsForAuthenticatedUser()
