import React from 'react'

import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'


export default function({ value }) {
  return (
    <Editor
      highlight={code => hljs.highlight('python', code).value}
      padding={10}
      value={value}
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 16,
      }}
      disabled
    />
  )
}