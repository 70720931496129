import React from 'react'

import { BaseStyles } from '@primer/components'

import { CorpusProvider } from '../hooks/useCorpus'
import { StoreProvider } from '../Store'
import { OctokitProvider } from '../hooks/useOctokit'
import { ProductProvider } from '../hooks/useProduct'

import ScrollToTop from '../components/ScrollToTop'
import Header from '../components/Header'
import Footer from '../components/Footer'

import { boostrapThirdParties } from '../helpers'

boostrapThirdParties()

export default ({ children, name, appPath }) => (
  <StoreProvider>
    <CorpusProvider name={name} appPath={appPath}>
      <ProductProvider name={name}>
        <OctokitProvider>
          <ScrollToTop />
          <BaseStyles className="App">
            <div className="pb-4 pb-md-8">
              <Header />
              { children }
            </div>
            <Footer />
          </BaseStyles>
        </OctokitProvider>
      </ProductProvider>
    </CorpusProvider>
  </StoreProvider>
)
