import React, { useContext } from 'react';

import { Box, BorderBox, LabelGroup, Avatar, Label, Dropdown, Tooltip, StyledOcticon, Text } from '@primer/components'
import { GitCommit, Eye, Info } from '@primer/octicons-react'

import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { Link } from "react-router-dom";

import { CorpusContext } from '../../hooks/useCorpus'
import { useProduct } from '../../hooks/useProduct'
import useScreen from '../../hooks/useScreen'
import { countLines } from '../../helpers'
import * as constants from '../../constants'


// this is not a space. It's a non-whitespace char that is blank. Needed to fool jsdiff to show leading/trailing empty lines
const BRAILLE_PATTERN_BLANK = '⠀'

const reactDiffStyles = {
  codeFold: {display: 'none'},
  emptyGutter: {display: 'none'},
  marker: {visibility: 'hidden'},
  variables: {
    light: {
      addedBackground: 'white',
      addedGutterBackground: '#fafbfc',
    }
  }
}

export default function CardWithContext(props) {
  const { corpus } = useContext(CorpusContext)
  const codes = props.codes || [props.code]

  let sourceAbove
  let sourceBelow

  const hasDelta = props.hasDelta && (props.exampleBefore !== props.exampleAfter)
  const scmKey = props.scmKey || constants.SCM_GITHUB

  if (hasDelta) {
    [sourceAbove, sourceBelow] = splitCodeAtDiff(props.exampleBefore, props.exampleAfter)
  } else {
    [sourceAbove, sourceBelow] = splitCodeAtLine(props.exampleBefore, props.lineNumber - props.linesOffset)
  }

  const { isSmall } = useScreen()

  const deltaBefore = props.deltaBefore || props.exampleBefore
  const deltaAfter = props.deltaAfter || props.exampleAfter

  // defaultProps not working for some reason...
  let linesOffset = props.linesOffset == undefined ? 343 : props.linesOffset

  const simulatePullRequest = props.simulatePullRequest == undefined ? true : props.simulatePullRequest

  const boxStyle = { borderLeft: '1px solid #dedede', borderRight: '1px solid #dedede'}

  if (props.isFirstMessage) {
    boxStyle.borderTop ='1px solid #dedede'
  }

  if (props.isLastMessage || props.lineBreak) {
    boxStyle.borderBottom = '1px solid #dedede'
  }
  if (corpus[codes[0]] === undefined) {
    return null
  }
  const adviceStyle = props.minimalistAdvice && codes.length === 1 ? getImpactStyle(corpus[codes[0]].impact) : {} 

  function handleLineNumberClick(lineId) {
    const lineNumber = lineId.replace('R-', '')
    const url = `${props.repoBlobLink}#L${lineNumber}`
    window.open(url, '_blank');
  }
  return (
    <Box className="django-doctor-before-after" style={boxStyle}>
      <div style={{overflowX: 'auto'}}>
        <ReactDiffViewer
          oldValue={simulatePullRequest ? '' : sourceAbove}
          newValue={sourceAbove}
          linesOffset={linesOffset}
          splitView={false}
          showDiffOnly={simulatePullRequest}
          disableWordDiff={true}
          compareMethod={DiffMethod.SENTENCES}
          extraLinesSurroundingDiff={simulatePullRequest ? null : 0}
          styles={reactDiffStyles}
          onLineNumberClick={handleLineNumberClick}
        />
      </div>
      <Box bg="white" style={{ borderTop: '1px solid #dedede', borderBottom: '1px solid #dedede', ...adviceStyle}}>
        <CardAttribution hide={props.minimalistAdvice} />
        <CardAdvice codes={codes} />
        <CardCodeChangeSuggestion
          scmKey={scmKey}
          show={hasDelta}
          minimalistAdvice={props.minimalistAdvice}
          deltaBefore={deltaBefore.trim('\n')}
          deltaAfter={deltaAfter.trim('\n')}
          simulatePullRequest={simulatePullRequest}
        />
      </Box>
      <div style={{overflowX: 'auto'}}>
        <ReactDiffViewer
          oldValue={simulatePullRequest ? '' : sourceBelow}
          newValue={sourceBelow}
          linesOffset={linesOffset + countLines(sourceAbove)}
          splitView={false}
          showDiffOnly={simulatePullRequest}
          disableWordDiff={false}
          compareMethod={DiffMethod.SENTENCES}
          extraLinesSurroundingDiff={simulatePullRequest ? null : 0}
          styles={reactDiffStyles}
          onLineNumberClick={handleLineNumberClick}
        />
      </div>
    </Box>
  )
}

CardWithContext.defaultProps = {
  minimalistAdvice: false,
  adviceStyle: {},
}


function CardAdvice(props) {
  return (
    <> {
      props.codes.map((code, i) => <CardText code={code} isMany={props.codes.length > 1} />)
    }
    </>
  )
}

function CardCodeChangeSuggestionBitbucket(props) {
  const style = {borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: 0}
  const { isSmall } = useScreen()
  return (
    <Box style={{border: '1px solid #dedede', borderRadius: '6px'}} className={"m-4 mt-0"}>
      <div style={{overflowX: 'auto'}}>
        <ReactDiffViewer
          oldValue={props.deltaBefore}
          newValue={props.deltaAfter}
          splitView={false}
          hideLineNumbers={true}
          showDiffOnly={true}
          disableWordDiff={true}
          extraLinesSurroundingDiff={0}
          styles={{codeFold: {'display': 'none'}}}
        />
      </div>
    </Box>
  )
}


function CardCodeChangeSuggestion(props) {
  const { scmKey, show, ...otherProps } = props

  if (!show) {
    return null
  }

  if (scmKey === constants.SCM_GITHUB) {
    return <CardCodeChangeSuggestionGithub {...otherProps} />
  } else if (scmKey === constants.SCM_BITBUCKET) {
    return <CardCodeChangeSuggestionBitbucket {...otherProps} />
  }
  throw `scmKey "${scmKey}" unsupported`
}

function CardCodeChangeSuggestionGithub(props) {
  const style = {borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: 0}
  const { isSmall } = useScreen()
  return (
    <Box className="bg-white ml-md-3" style={{borderRadius: 0}}>
      <Box className="pb-3 ml-3 mr-3">
        <BorderBox className="bg-gray p-2 f5 " style={style}>
          <Text className="pr-2">Suggested changes</Text>
          {
            !props.minimalistAdvice && (
              <Tooltip aria-label="Accept or ignore any suggestion. You're in control." direction={isSmall ? 'n' : 'e'}>
                <StyledOcticon icon={Info} />
              </Tooltip>
            )
          }
        </BorderBox>
        <Box style={{border: '1px solid #dedede'}}>
          <div style={{overflowX: 'auto'}}>
            <ReactDiffViewer
              oldValue={props.deltaBefore}
              newValue={props.deltaAfter}
              splitView={false}
              hideLineNumbers={true}
              showDiffOnly={true}
              disableWordDiff={true}
              extraLinesSurroundingDiff={0}
              styles={{codeFold: {'display': 'none'}}}
            />
          </div>
        </Box>
        {
          !props.minimalistAdvice && (
            <Box className="p-1 text-right bg-gray" style={{borderBottomLeftRadius: 6, borderBottomRightRadius: 6, border: '1px solid #dedede', borderTop: 0}}>
            {
              props.simulatePullRequest
                ? (
                  <Tooltip aria-label="Easily accept changes. No switching context." direction={isSmall ? 'n' : 'w'}>
                    <Dropdown.Button fontSize={0} className="p-1 pl-2 pr-2">Commit suggestion</Dropdown.Button>
                  </Tooltip>
                ) : <Box style={{height:'1em'}}></Box>
            }
            </Box>
          )
        }
      </Box>
    </Box>
  )
}

function CardAttribution(props) {
  const { gitHubMarketplaceLink } = useProduct()
  if (props.hide) {
    return null
  }
  return (
    <nav className="ml-3 mt-3">
      <Avatar src="/favicon.png" size={32} />
      <strong className="pl-2 pr-2"><a href={gitHubMarketplaceLink} rel="noopener noreferrer" target="_blank" style={{color: "black", textDecoration: "none"}}>code-review-doctor</a></strong>
      <Label variant="small" sx={{border: "1px solid grey", color: "grey", backgroundColor: "white"}}>bot</Label>
      <span className="pl-2">just now</span>
    </nav>
  )
}

function CardText(props) {
  const { corpus, routes } = useContext(CorpusContext)
  const { Summary, impact } = corpus[props.code]
  const adviceUrl = routes[props.code]
  const style = props.isMany ? getImpactStyle(impact) : {}
  return (
    <Box className={`${props.textClassName} pl-3 pb-3 pt-3`} style={style}>
      <Summary className="d-inline" />
      <span> <Link to={adviceUrl} className={`${props.textClassName} text-underline`}>Read more</Link> </span>
    </Box>
  )
}

CardWithContext.defaultProps = {
  linesOffset: 343,
  simulatePullRequest: true,
}

export function splitCodeAtDiff(before, after) {
  // assuming if there is only report of issue, and no delta then the last line contains the issue
  if (after === '') {
    return [before, after]
  }
  const beforeLines = before.split('\n')
  const afterLines = after.split('\n')
  for (var i = 0; i < beforeLines.length; i++) {
    if (beforeLines[i] !== afterLines[i]) {
      break
    }
  }
  const above = beforeLines.slice(0, i+1)
  const below = beforeLines.slice(i+1)

  return [
    preventWhitespaceTrim(above).join('\n'),
    preventWhitespaceTrim(below).join('\n'),
  ]
}


export function splitCodeAtLine(before, lineNumber) {
  // assuming if there is only report of issue, and no delta then the last line contains the issue
  const lines = before.split('\n')
  const above = lines.slice(0, lineNumber)
  const below = lines.slice(lineNumber)
  return [preventWhitespaceTrim(above).join('\n'), preventWhitespaceTrim(below).join('\n')]
}


function preventWhitespaceTrim(values) {
  const newValues = [...values]
  if (newValues[0] === '') {
    newValues[0] = BRAILLE_PATTERN_BLANK
  }
  if (newValues[newValues.length -1] === '') {
    newValues[newValues.length -1] = BRAILLE_PATTERN_BLANK
  }
  return newValues
}


function getImpactStyle(impact) {
  const mapping = {
    [constants.IMPACT_HIGH]: 'red',
    [constants.IMPACT_MEDIUM]: 'orange',
    [constants.IMPACT_LOW]: 'yellow',
  }
  const value = mapping[impact]
  if (value) {
    return {borderLeft: `3px solid ${value}`}
  }
  return {}

}