import React from 'react'

import { Text } from '@primer/components'

import Snippet from '../../components/Snippet'
import Code from '../../components/Code'
import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = `foo = lambda x: bar(x)`

export const exampleAfter = `foo = bar`

export const code = 'NoRedundantLambda'

export const title = 'Redundant lambda definitions'

export const label = title

export const wordCode = 'avoid-redundant-lambda-definition'

export const furtherReading = [
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Avoid unnecessarily wrapping a function in a lambda
    </Text>
  )
}


export const explanation = (
  <>
    <Text as={'p'}>Avoid defining a lambda function which passes through of it is arguments to another callable. You can instead safely directly call the callable.</Text>
  </>
)


export {category, impact}