import { useState, useEffect} from 'react'

export default function(allItems, collapsedSize) {
  const [filters, setFilters] = useState(getFiltersFromQuerystring)
  function updateFilters(changes) {
    if (changes === null) {
      setFilters({})
    } else {
      setFilters({...filters, ...changes})
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams();
    const serialized = (
      Object
      .entries(filters)
      .filter(([code, value]) => value ===true)
      .map(([code, value]) => code)
      .join(',')
    )
    if (serialized) {
      searchParams.set("filters", serialized)
    }

    // not doing via router to avoud re-rending the component
    window.history.pushState(
      {},
      document.title,
      window.location.pathname + (serialized === "" ? "" : '?' + unescape(searchParams.toString()))
    )

  }, [filters])


  return {filters, updateFilters}
}


function getFiltersFromQuerystring() {
  const initial = {}
  const params = new URLSearchParams(window.location.search)
  if (params.has('filters')) {
    for (let code of params.get("filters").split(',')) {
      initial[code] = true
    }
  }
  return initial
}