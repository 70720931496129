import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_LOW as impact } from '../../constants'

export const exampleTitle = 'models.py'

export const exampleBefore = (
`class CommentModel(models.Model):
    body = models.CharField(max_length=5000)
    date = models.DateField()`
)

export const exampleAfter = (
`class CommentModel(models.Model):
    body = models.TextField()
    date = models.DateField()`
)

export const code = 'C2006'

export const title = 'CharField with huge max_length'

export const label = title

export const wordCode = 'huge-max-length'

export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/thinking-charfield-youll-think-again-after-this-8d207abbca4d',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#django.db.models.CharField',
    text: 'CharField Django documentation',
  },
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#django.db.models.TextField',
    text: 'TextField Django documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Using <code style={{fontSize: 'inherit'}}>TextField</code> for very long string fields would simplify the code and make is easier to maintain.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>CharField should be used for smaller strings and TextField should be used for larger strings.</Text>
    <Text as={'p'}>CharField max_length adds value when the value is known format (phone numbers, ISBN) with a known maximum valid length.</Text>
    <Text as={'p'}>For arbitrary long text the max_length adds scope for unwanted validation errors without adding value elsewhere. Remove it and rely on unconstrained TextField.</Text>
  </>
)


export const ogImage = "/og-image/C2006.png"

export {category, impact}