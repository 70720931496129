import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'

export const exampleTitle = 'tasks.py'

export const exampleBefore = (
`def forwards(apps, schema_editor):
    MyModel.objects.get(...)`
)

export const exampleAfter = (
`def forwards(apps, schema_editor):
    apps.get_model("core", "MyModel").objects.get(...)`
)

export const code = 'C8000'

export const title = 'Importing models.py into migrations'

export const label = title

export const wordCode = 'migration-model-import'

export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/avoiding-flaky-migration-1fc71c7cdb66',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/topics/migrations/',
    text: 'Django migration documentation',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      It's better to use <a href="https://docs.djangoproject.com/en/3.1/ref/applications/#django.apps.apps.get_model" rel="noopener noreferrer" target="_blank"><code style={{fontSize: 'inherit'}}>apps.get_model</code></a>, which guarantees the Model's fields will reflect the fields in the database even if <code style={{fontSize: 'inherit'}}>models.py</code> is vastly out of step with the database.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>The fields in Django's <code style={{fontSize: 'inherit'}}>models.py</code> must agree with the schema in the database. When Django performs database read or write operations it uses the fields in your <code style={{fontSize: 'inherit'}}>models.py</code> to determine the name of the database fields to <code style={{fontSize: 'inherit'}}>SELECT</code> and <code style={{fontSize: 'inherit'}}>INSERT</code>.</Text>
    <Text as={'p'}>If <code style={{fontSize: 'inherit'}}>models.py</code> includes fields that are not yet in the database schema then the database will throw an error.</Text>
  </>
)


export const ogImage = "/og-image/C8000.png"
export {category, impact}