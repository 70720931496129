import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'
import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'validation.py'

export const exampleBefore = (
`values = (
    'yes',
    'correct'
    'agreed',
)`
)

export const exampleAfter = (
`values = (
    'yes',
    'correct',
    'agreed',
)`
)

export const code = 'NoMissingComma'

export const ogImage = `/og-image/${code}.png`

export const title = 'Avoid implicit string concatenation'

export const label = title

export const wordCode = 'avoid-missing-comma'

export const furtherReading = [

]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Missing commas in tuples results in implicit string concatenation. Probably not what you intended to do.
    </Text>
  )
}

const exampleCode = `
def is_positive(word):
    words = (
        'yes',
        'correct',
        'affirmative'
        'agreed',
    )
    return word in words

is_positive('agreed') is True  # evaluates to False
`
export const explanation = (
  <>
    <Text as={'p'}>Implicit string concatenation that resulted from a typo can change the behaviour of the application. Take for example:</Text>
    <Snippet value={exampleCode} />
    <Text as={'p'}><Code>is_positive('agreed')</Code> evaluates to <Code>False</Code> because a typo resulted in the comma being missed from the end of <Code>'affirmative'</Code>, resulting in <Code>'affirmative'</Code> and <Code>'agreed'</Code> being implicitly concatenated to  <Code>'affirmativeagreed'</Code>.</Text>
  </>
)

export {category, impact}
