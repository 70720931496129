import React from 'react';

import { Box, Text } from '@primer/components'


export default function(props) {
  return (
    <>
      <Box className="container-lg">
        <Box className="m-md-0 ml-4 mr-4 pt-md-9">
          <Text>Loading</Text><Text className="AnimatedEllipsis" />
        </Box>
      </Box>
    </>
  )
}
