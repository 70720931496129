import React, { useContext } from 'react';

import { Flash } from '@primer/components'

import { useProduct } from '../../hooks/useProduct'


export default function(props) {
  const { gitHubMarketplaceLink } = useProduct()

  return (
    <Flash variant="warning" style={{...props.style, border: 0}}>
      Use our <a href={gitHubMarketplaceLink} rel="noopener noreferrer" target="_blank">GitHub pull request bot</a> to prevent issue like these getting into your codebase.
    </Flash>
  )
}
