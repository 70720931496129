import React from 'react';

import { Box, Heading, Text, ButtonPrimary } from '@primer/components'

import WaveHero from '../../components/WaveHero'
import CodeReview from '../../components/BeforeAfter/BeforeAfterHorizontal'

export default function(props) {
  return (
    <WaveHero>
      <Box className="container-lg pt-md-8 mb-10 pl-2 pr-2 pl-md-4 pr-md-4">
        <Box className="text-white m-md-0 text-center">
          <Heading as="h1" fontSize={58} paddingTop="15px" className="mb-4" lineHeight={"1em"}>{props.headline}</Heading>
          <Text
            as="p"
            fontSize={4}
            className="text-white pl-2 pr-2 pl-md-0 pl-md-0 mb-2 container-md"
          >Trusted by <b>over 3000 teams</b> to fix more bugs during code review and find hidden Python or Django issues already in their codebase.</Text>
          <ButtonPrimary
            as={'a'}
            variant="large"
            className="f3 p-3 mt-4"
            href={'#a-quick-example'}
          >View demo
          </ButtonPrimary>
        </Box>           

      </Box>
    </WaveHero>
  )
}
