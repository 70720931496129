import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'
import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'models.py'

export const exampleBefore = (
`class FooBarClass(NamedTuple):
    pass`
)

export const exampleAfter = (
`@dataclass(frozen=True)
class FooBarClass:
    pass`
)

export const code = 'NoNamedtuple'

export const title = 'Use dataclass instead of NamedTuple'

export const label = title

export const wordCode = 'avoid-named-tuple'

export const furtherReading = [

]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Consider using the <Code>dataclass</Code> here instead for simplicity, performance gains, and consistency
    </Text>
  )
}


const exampleCode = (
`Point3D(2017, 6, 2) == Date(2017, 6, 2)  # evaluates to True
Point2D(1, 10) == (1, 10)  # evaluates to True
`
)
export const explanation = (
  <>
    <Text as={'p'}>The <Code>NamedTuple</Code> has some drawbacks such as Python allows accidentally compare <Code>NamedTuple</Code> to a tuple or namedtuple with the same number of fields:</Text>
    <Snippet value={exampleCode} />
    <Text as={'p'}>That is probably unexpected behaviour, and can result in bugs. Additionally, <Code>NamedTuple</Code> are less flexible and more brittle than <Code>dataclass</Code> because:</Text>
    <ul className="bullet-list-blue">
      <li>There is no option for mutable <Code>NamedTuple</Code>.</li>
      <li>Cannot specify default values for <Code>NamedTuple</Code> values.</li>
      <li>No control which fields are used for <Code>__init__</Code>, <Code>__repr__</Code>, etc.</li>
      <li>Cannot support combining fields by inheritance.</li>
    </ul>
    <Text as={'p'} />
    <Text as={'p'}>These drawbacks can be avoided by using a <Code>dataclass</Code>.</Text>
  </>
)

export {category, impact}


// for cleaner customization and
//     inheritance. It supports default value, combining fields for inheritance, and omitting optional fields at
//     instantiation.

//https://www.python.org/dev/peps/pep-0557