import React from 'react'

import { Text } from '@primer/components'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'

import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'catalogue.py'

export const exampleBefore = (
`with open('some/path.json', 'w') as f:
    f.write(json.dumps({'foo': 'bar'}))`
)

export const exampleAfter = (
`with open('some/path.json', 'w') as f:
    json.dump({'foo': 'bar'}, f)`
)

export const code = 'UseJsonDump'

export const ogImage = `/og-image/${code}.png`

export const title = 'Use json.dump to write JSON to file'

export const label = 'Use json.dump'

export const wordCode = 'use-json-dump'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/json.html#json.dump',
    text: 'Python json.dump documentation',
  },
  {
    href: 'https://docs.python.org/3/library/json.html#json.dumps',
    text: 'Python json.dumps documentation',
  },
  {
    href: 'https://github.com/python/cpython/blob/d7c567b08f9d7d6aef21b881340a2b72731129db/Lib/json/__init__.py#L120',
    text: 'CPython source code for json.dump'
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      <Code>json.dump(f)</Code> simplifies writing JSON to disk.
    </Text>
  )
}

const pythonCodeExample = (
`with open('some/path.json', 'w') as f:
    json.dump({'foo': 'bar'}, f)

with open('some/path.json', 'w') as f:
    f.write(json.dumps({'foo': 'bar'}))`
)

export const explanation = (
  <>
    <Text as='p'>If you ever wondered why <Code>json.dumps</Code> ends with a "s" it's because it's specifically for working on a string, but there is another related method: <Code>json.dump</Code></Text>
    <Text as='p'><Code>json.dumps</Code> is for JSON serialising an object to a <i>string</i>, while <Code>json.dump</Code> (no "s") is for JSON serialising an object to a <i>file</i>.</Text>
    <Text as='p'>The methods are very related. In fact, <Code>json.dump</Code> is a <a href="https://github.com/python/cpython/blob/d7c567b08f9d7d6aef21b881340a2b72731129db/Lib/json/__init__.py#L120">wrapper around <Code>json.dumps</Code></a>. It's a method provided out of the box by Python to simplify the task of writing JSON to a file-like object.</Text>
    <Text as="p">Python core developers are very careful about what features are included in the Python builtin modules, so it makes sense to utilise the convenience methods and not reinvent the wheel? For example, these result in the same outcome:</Text>
    <Snippet value={pythonCodeExample}/>
    <Text as={'p'}>So why not choose the easiest to read, write, and execute (the first one)?</Text>
  </>
)


export {category, impact}