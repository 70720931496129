import React from 'react'

import { Text } from '@primer/components'

import Snippet from '../../components/Snippet'
import Code from '../../components/Code'
import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_MEDIUM as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = (
`if value == 1 and False:
    foo_bar()`
)

export const exampleAfter = exampleBefore

export const code = 'NoStaticIfCondition'

export const title = 'Avoid expressions that always evaluate to the same value'

export const label = 'Static expression evaluation'

export const wordCode = 'avoid-static-if-condition'

export const furtherReading = [
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      This code will always evaluate the same value regardless of the inputs.
    </Text>
  )
}

const exampleCode = (
`def send_messages(messages):
    if not all(send(message) for message in messages):
        print('not all messages sent')

def send(message):
    # return True if sent, and False if not
    ...
`
)


export const explanation = (
  <>
    <Text as={'p'}>It looks like the if statement will either always evaluate to <Code>True</Code> or always evaluate to <Code>False</Code> regardless of what inputs are used.</Text>
    <Text as={'p'}>This is probably a typo or perhaps the code was for debugging and should not have been committed.</Text>
  </>
)


export {category, impact}