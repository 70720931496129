import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'models.py'

export const exampleBefore = (
`class BlogPost(models.Model):
    id = models.UUIDField(primary_key=True, unique=False)`
)

export const exampleAfter = exampleBefore

export const code = 'C2011'

export const title = 'Non-unique primary_key'

export const label = title

export const wordCode = 'non-unique-primary'

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#django.db.models.Field.unique',
    text: 'Django unique=True documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Primary key must be unique by definition: it's a field that uniquely identifies the record.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>A primary key is used to uniquely identify each record in the Model's database table.</Text>
    <Text as={'p'}>primary_key=True denotes the field is the primary key for the model.</Text>
    <Text as={'p'}>primary_key=True implies null=False and unique=True.</Text>
  </>
)


export {category, impact}