import React from 'react';
import { hydrate, render } from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import './App.css';
import './index.css';
import Entrypoint from './entrypoints/App'

const rootElement = document.getElementById('root');


if (rootElement.hasChildNodes()) {
  hydrate(<Entrypoint />, rootElement);
} else {
  render(<Entrypoint />, rootElement);
}
