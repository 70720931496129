function track(code) {
  if (window.fathom) {
    window.fathom.trackGoal(code, 0);
  }
}

export default function() {
  return {
    track,
    trackOnce,
  }
}

const trackOnce = once(track, {})

function once(fn, state) {
  return function(code) {
    if (!state[code]) {
      state[code] = true
        fn(code)
    }
  }
}