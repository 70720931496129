import React from 'react';

import { TextInput } from '@primer/components'
import { Search, History as Searching } from '@primer/octicons-react'

import { useSearchRepositories } from '../hooks/useOctokit'
import RateLimited from './RateLimited'
import RepositorySearchResult from './RepositorySearchResult'


export default function RepositorySearch(props) {
  const [
    updateSerachParams,
    searchParams,
    repositories,
    retrievingRepositories,
    repositoriesTotalCount,
    isRateLimited
  ] = useSearchRepositories({}, props.exampleRepos)
  return (
    <>
      <TextInput
        onChange={event => updateSerachParams({q: event.target.value})}
        name="repository"
        placeholder="Type repo name or paste github link"
        className="bg-white col-12 f3 pt-2 pb-2"
        icon={retrievingRepositories ? Searching : Search}
        autoFocus={props.autoFocus}
      />
      <RateLimited show={isRateLimited} />
      <RepositorySearchResult repositories={repositories} showOwner={false} />
    </>
  )
}

RepositorySearch.defaultProps = {
  autoFocus: false
}