import { useState } from 'react'

import firebase from 'firebase/app';
import 'firebase/auth'


// firebase
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
});


export const useFirebaseAuth = function() {
  const [value, setValue] = useState(() => firebase.auth())
  return value
}
