import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import BaseWebsite from './BaseWebsite'
import { useCorpus, useCorpusRoutes } from '../hooks/useCorpus'

import LandingPageRepoCheck from '../views/LandingPageRepoCheck'
import Loading from '../components/Loading'

const LandingPageGithub = React.lazy(() => import('../views/LandingPageGithub'))
const LandingPageBitbucket = React.lazy(() => import('../views/LandingPageBitbucket'))
const Repository = React.lazy(() => import('../views/Repository'))
const RepositoryList = React.lazy(() => import('../views/RepositoryList'))
const OwnerRepositoryList = React.lazy(() => import('../views/OwnerRepositoryList'))
const BitbucketLicencePurchased = React.lazy(() => import('../views/BitbucketLicencePurchased'))
const LandingPageHowItWorks = React.lazy(() => import('../views/LandingPageHowItWorks'))
const PriceBitbucket = React.lazy(() => import('../views/PriceBitbucket'))
const TermsAndConditions = React.lazy(() => import('../views/TermsAndConditions'))
const PrivacyPolicy = React.lazy(() => import('../views/Privacy'))

const Config = React.lazy(() => import('../views/Config'))

const name = "all"

export default function App(props) {
  const corpus = useCorpus(name)
  const routes = useCorpusRoutes(name)

  const urlPrefix = (props.match && props.match.url) || ''
  const buildUrl = url => urlPrefix + url
  return (
    <BaseWebsite name={name} appPath={urlPrefix}>
      <Switch>
        <Route exact path={buildUrl("")} component={LandingPageRepoCheck} />
      </Switch>
      <React.Suspense fallback={<Loading /> } >
        <Switch>
          <Route exact path={buildUrl("features")} component={LandingPageHowItWorks} />
          <Route exact path={buildUrl("features/github")} component={LandingPageGithub} />
          <Route exact path={buildUrl("features/bitbucket")} component={LandingPageBitbucket} />
          <Route exact path={buildUrl("features/bitbucket/price")} component={PriceBitbucket} />
          <Route exact path={buildUrl("features/bitbucket/install-webhook")} component={BitbucketLicencePurchased} />
          <Route exact path={buildUrl("404.html")} component={Loading} />
          <Route exact path={buildUrl("config")} component={Config} />
          <Route exact path={buildUrl("terms")} component={TermsAndConditions} />
          <Route exact path={buildUrl("privacy")} component={PrivacyPolicy} />
          <Route exact path={buildUrl("@me")} component={RepositoryList} />
          <Route path={buildUrl(":owner/:repository/:branch?")} component={Repository} />
          <Route path={buildUrl(":owner")} component={OwnerRepositoryList} />
        </Switch>
      </React.Suspense>
    </BaseWebsite>
  );
}