import React, { createContext, useContext } from 'react'

export const useProduct = () => useContext(ProductContext)

const bitbucketMarketplaceLink = "https://marketplace.atlassian.com/apps/1227731/code-review-doctor?tab=overview&hosting=cloud"
const gitHubMarketplaceLink = 'https://github.com/marketplace/django-doctor/'

const django = {
  id: 'djangodoctor',
  name: 'Code Review Doctor',
  stack: 'Django',
  gitHubMarketplaceLink,
  bitbucketMarketplaceLink,
  checkCount: 40,
  domain: 'codereview.doctor',
  socialLinkDevDotTo: 'https://dev.to/codereviewdoctor',
  socialLinkTwitter: "https://twitter.com/CodeReviewDr",
  socialLinkFacebook: "https://www.facebook.com/CodeReviewDoctor",
  socialLinkLinkedIn: "https://www.linkedin.com/company/code-review-doctor",
}

const python = {
  id: 'python',
  name: 'Code Review Doctor',
  stack: 'Python',
  gitHubMarketplaceLink,
  bitbucketMarketplaceLink,
  checkCount: 30,
  domain: 'codereview.doctor',
  socialLinkDevDotTo: 'https://dev.to/codereviewdoctor',
  socialLinkTwitter: "https://twitter.com/CodeReviewDr",
  socialLinkFacebook: "https://www.facebook.com/CodeReviewDoctor",
  socialLinkLinkedIn: "https://www.linkedin.com/company/code-review-doctor",
}

const all = {
  id: 'codereviewdoctor',
  name: 'Code Review Doctor',
  stack: 'Python',
  gitHubMarketplaceLink,
  bitbucketMarketplaceLink,
  checkCount: 70,
  domain: 'codereview.doctor',
  socialLinkDevDotTo: 'https://dev.to/codereviewdoctor',
  socialLinkTwitter: "https://twitter.com/CodeReviewDr",
  socialLinkFacebook: "https://www.facebook.com/CodeReviewDoctor",
  socialLinkLinkedIn: "https://www.linkedin.com/company/code-review-doctor",
}

const mapping = {
  django,
  python,
  all,
}

export const ProductProvider = ({name, children}) => {
  const value = mapping[name]
  return (
    <ProductContext.Provider value={value}>
      {children}
    </ProductContext.Provider>
  )
}

export const ProductContext = createContext({})
