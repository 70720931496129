import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'models.py'

export const exampleBefore = (
`class CommentModel(models.Model):
    body = models.TextField()
    date = models.DateField(null=True)`
)

export const exampleAfter = (
`class CommentModel(models.Model):
    body = models.TextField()
    date = models.DateField(null=True, blank=True)`
)

export const code = 'C2003'


export const title = 'Field allows null but not blank'

export const label = title

export const wordCode = 'field-null-not-blank'


export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/the-real-difference-between-blank-true-and-null-true-may-surprise-you-982a9ac3b9da',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#null',
    text: 'Django Field null documentation',
  },
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#blank',
    text: 'Django Field blank documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      If null is <code style={{fontSize: 'inherit'}}>True</code> and blank is <code style={{fontSize: 'inherit'}}>False</code> then validation becomes more complex.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>If null is True and blank is False then Django and the database disagree on whether empty values are allowed.</Text>
    <Text as={'p'}>The null and blank attributes are useful for optional fields. Both attributes default to False.</Text>
    <Text as={'p'}>The null attribute specifies that the database will accept no value. The blank attribute specifies Django should not check if the value is present when Model.clean is called.</Text>
    <Text as={'p'}>If Model.save is called via the shell without first calling Model.clean: the bad data will be saved and there may be unwanted side effects:</Text>
    <ul>
        <li>next time that record is validated it will fail</li>
        <li>the datatype will be inconsistent</li>
        <li>ORM queries will be  more complex</li>
    </ul>
    <Text as={'p'}>These unwanted side effects can cause more serious problems that are hard to debug long after the original error occurred.</Text>
  </>
)


export const ogImage = "/og-image/C2002.png"
export {category, impact}