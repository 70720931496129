import React from 'react'

import { Text } from '@primer/components'

import Snippet from '../../components/Snippet'
import Code from '../../components/Code'
import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = (
`def foo_bar() -> "FooBarClass":
    return FooBarClass()
\t
class FooBarClass:
    pass`
)

export const exampleAfter = (
`def foo_bar() -> FooBarClass:
    return FooBarClass()
\t
class FooBarClass:
    pass`
)

export const code = 'NoStringTypeAnnotation'

export const title = 'Use type identifiers instead of string type hints'

export const label = 'Use type identifiers'

export const wordCode = 'use-type-identifiers'

export const furtherReading = [
  {
    href: 'https://www.python.org/dev/peps/pep-0563/#forward-references',
    text: 'Python documentation on forward references',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Use type identifiers instead of using string type hints.
    </Text>
  )
}


export const explanation = (
  <>
    <Text as={'p'}>Historically, string type annotations catered for the case where a function on line 5 returns an instance of a class that that is defined at line 7: <i>how can type annotations on line 5 refer to a class that has not been defined yet?</i></Text> 
    <Text as={'p'}>Python 3.7 solved this problem by introducing postponing evaluation of type annotations until Python is ready to use them, removing the need to use string annotation styles.</Text>
    <Text as={'p'}>Function annotations and variable annotations don't evaluate at function definition time. Instead, under the hood they are preserved in <Code>__annotations__</Code> in string form.</Text>
    <Text as={'p'}>This deferring allows <i>forward references</i>: type hints can contains names that have not been defined yet.</Text>
  </>
)


export {category, impact}