import React from 'react'

import { Text } from '@primer/components'

import Snippet from '../../components/Snippet'
import Code from '../../components/Code'
import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = (
`class FooBarClass(object):
    pass`
)

export const exampleAfter = (
`class FooBarClass:
    pass`
)

export const code = 'NoInheritFromObject'

export const ogImage = `/og-image/${code}.png`

export const title = 'Unnecessary inheriting from object'

export const label = 'Inheriting from object'

export const wordCode = 'inheriting-from-object'

export const furtherReading = [
  {
    href: 'https://www.python.org/dev/peps/pep-0373/',
    text: 'PEP 373 -- Python 2.7 Release Schedule (Python 2.7 End of Life information)',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      A class is inherited from <Code>object</Code> by default, so explicitly inheriting from object is redundant. Removing it keeps the code simpler.
    </Text>
  )
}

const codeExample = `
class ExampleClass:
    pass


class ExampleClass(object):
     pass


class ExampleClass():
    pass
`
export const explanation = (
  <>
    <Text as={'p'}>A technical reason for inheriting from <Code>object</Code> could be when writing code that is compatibility with Python 2.7: Python 3 classes inherits from <Code>object</Code> by default, but Python 2.7 classes do not.</Text>
    <Text as={'p'}>Note though that Python 2.7 has already reached "End Of Life" and is no longer supported by the Python Software Foundation. For this reason your code might not need to support Python 2.7 either.</Text>
    <Text as={'p'}>Moreover, it takes more than <i>inheriting classes from object</i> to make your codebase backwards compatible with Python 2.7: you must not use any new feature of Python 3...ever. That's some serious dedication to a dead language.</Text>
    <Text as={'p'}>Given that all three of these class definitions are completely equivalent:</Text>
    <Snippet value={codeExample} />
    <Text as={'p'}>Why not choose the easiest to both read and write (the first one)</Text>
  </>
)


export {category, impact}