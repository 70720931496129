import React, { useContext } from 'react';

import { Box, Text, Heading } from '@primer/components'
import Checkbox from '../../components/Checkbox'
import { CorpusContext, useCategory, useImpact  } from '../../hooks/useCorpus'
import * as constants from '../../constants'



const CATEGORIES = ['high', 'medium', 'low', "security", "performance", "maintainability", "testing", "bugs"]

function CheckboxItem(props) {
  if (!CATEGORIES.includes(props.code) && props.count === 0) {
    return null
  }
  const checked = props.value === true;
  function onChange(event) {
    props.onChange({[props.code]: event.target.checked})
  }
  const id = `checkbox-${props.code}`
  return (
    <Box key={`sub-checkbox-${props.code}`} as="form" sx={{p: 1, display: 'flex', alignItems: 'center'}}>
      <Checkbox id={id} checked={checked} onChange={onChange} indeterminate={props.value === null} disabled={props.count === 0} />
      <Text as="label" sx={{fontSize: 2, fontWeight: 'bold', marginLeft: 1}} htmlFor={id}>
        <Text sx={{display: 'block'}}>{props.label}<i> ({props.count})</i></Text>
      </Text>
    </Box>
  )
}

export default function(props) {
  // todo: make explicitFilters a Set not Object
  const { corpus } = useContext(CorpusContext)
  const someCategory = CATEGORIES.some(code => props.explicitFilters[code] === true)
  const someChecks = Object.keys(props.checks).some(code => props.explicitFilters[code] === true)
  function clearFilters() {
    props.onChange(null)
  }

  function handleImplicitFilterChange(values) {
    const newValues = {}
    if (someChecks) {
      for (let code of Object.keys(props.explicitFilters)) {
        newValues[code] = false
      }
    }
    props.onChange({...newValues, ...values})
  }

  function handleExplicitFilterChange(values) {
    const newValues = {}

    for (let code of Object.keys(props.explicitFilters)) {
      if (CATEGORIES.includes(code)) {
        newValues[code] = null
      }
    }

    for (let item of props.implicitFilters) {
      newValues[item] = true
    }
    props.onChange({...newValues, ...values})
  }

  return (
    <>
      <Heading sx={{fontSize: 1, mb: 2}}>Filter { (someCategory || someChecks) && <a href="#" onClick={clearFilters}>clear</a>}</Heading>
      <CheckboxItem
        label="High impact"
        count={props.unfilteredMessageGroupCounts.high}
        value={props.explicitFilters.high}
        code={constants.IMPACT_HIGH}
        onChange={handleImplicitFilterChange}
      />
      <CheckboxItem
        label="Medium impact"
        count={props.unfilteredMessageGroupCounts.medium}
        value={props.explicitFilters.medium}
        code={constants.IMPACT_MEDIUM}
        onChange={handleImplicitFilterChange}
      />
      <CheckboxItem
        label="Low impact"
        count={props.unfilteredMessageGroupCounts.low}
        value={props.explicitFilters.low}
        code={constants.IMPACT_LOW}
        onChange={handleImplicitFilterChange}
      />
      <CheckboxItem
        label="Security"
        count={props.security}
        value={props.explicitFilters.security}
        code={constants.CATEGORY_SECURITY}
        onChange={handleImplicitFilterChange}
      />
      <CheckboxItem
        label="Performance"
        count={props.performance}
        value={props.explicitFilters.performance}
        code={constants.CATEGORY_PERFORMANCE}
        onChange={handleImplicitFilterChange}
      />
      <CheckboxItem
        label="Maintainability"
        count={props.maintainability}
        value={props.explicitFilters.maintainability}
        code={constants.CATEGORY_MAINTAINABILITY}
        onChange={handleImplicitFilterChange}
      />
      <CheckboxItem
        label="Test quality"
        count={props.testing}
        value={props.explicitFilters.testing}
        code={constants.CATEGORY_TESTS}
        onChange={handleImplicitFilterChange}
      />
      <CheckboxItem
        label="Bug"
        count={props.bugs}
        value={props.explicitFilters.bugs}
        code={constants.CATEGORY_INTRODUCE_BUGS}
        onChange={handleImplicitFilterChange}
      />

      <Text as="p" className="note" sx={{fontSize: 1, mb: 2, mt: 2}}>Issues</Text>
      { 
        Object.entries(props.unfilteredMessageGroupCounts.checks).map(([messageId, count]) => {
          var item = corpus[messageId]
          // default to issues implied from selected categories unless explicitly clicked issue
          const value = someChecks ? props.explicitFilters[messageId] : props.implicitFilters.has(messageId)
          return (
            <CheckboxItem
              label={item.label}
              key={messageId}
              count={count}
              value={value}
              code={messageId}
              onChange={handleExplicitFilterChange}
            />
          )
        })
      }

    </>
  )
}
