import React from 'react'

import { Text } from '@primer/components'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'

import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'catalogue.py'

export const exampleBefore = (
`with open('some/path.txt') as f:
    line_one = f.readline()

line_two = f.readline()`
)

export const exampleAfter = exampleBefore

export const code = 'NoOperationAfterClose'

export const title = "Don't try reading or writing closed files"

export const label = "Reading closed file"

export const wordCode = "no-operation-after-close"

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/tutorial/inputoutput.html#tut-files',
    text: 'Python reading/writing files documentation',
  },
  {
    href: 'https://docs.python.org/3/library/stdtypes.html?highlight=context%20manager#context-manager-types',
    text: 'Python context manager documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Accidentally trying to read or write files that are closed will cause a <Code>ValueError</Code> at runtime.
    </Text>
  )
}


export const explanation = (
  <>
    <Text as='p'>Python will raise "<Code>ValueError: I/O operation on closed file</Code>" if you try to read or write a file that has been closed.</Text>
    <Text as='p'>Files opened via a <Code>with open(...)</Code> context manager will be automatically closed once the context manager is exited, meaning the file pointer, while still in scope, is no longer open to reads or writes.</Text>
  </>
)


export {category, impact}