import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'
import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'settings.py'

export const exampleBefore = (
`REST_FRAMEWORK = {
    'DEFAULT_PERMISSION_CLASSES': (
        'rest_framework.permissions.IsAuthenticated'
    )
}`
)

export const exampleAfter = (
`REST_FRAMEWORK = {
    'DEFAULT_PERMISSION_CLASSES': (
        'rest_framework.permissions.IsAuthenticated',
    )
}`
)

export const code = 'ProbablyMeantTuple'

export const ogImage = `/og-image/${code}.png`

export const title = 'Missing trailing comma in tuple'

export const label = title

export const wordCode = 'probably-meant-tuple'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/stdtypes.html#tuple',
    text: 'Python documentation on tuple'
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      A <Code>tuple</Code> with only one element must end with a comma. Python won't know it's a <Code>tuple</Code> without the comma. Probably not what you intended to do.
    </Text>
  )
}


const exampleCode = (
`value = ()  # evaluates to empty tuple
values = 1,2  # evaluates to tuple with two elements
values = (1, 2)  # evaluates to tuple with two elements
values = (1)  # evaluates to int
values = (1,)  # evaluates to tuple with one element
values = 1, 2  # evaluates to tuple with two elements
`
)

export const explanation = (
  <>
    <Text as={'p'}>As far as the Python parser is concerned the parentheses are optional for non-empty tuples. According to <a href="https://docs.python.org/3/library/stdtypes.html#tuple" target="_blank">the documentation</a>: <i>it is actually the comma which makes a tuple, not the parentheses. The parentheses are optional, except in the empty tuple case.</i></Text>
    <Snippet value={exampleCode} />
    <Text as={'p'}>By missing the comma in <Code>values = (1)</Code> expect to get a <Code>TypeError</Code> when the code later attempts to perform tuple operations on a variable that evaluated to <Code>int</Code>.</Text>
  </>
)

export {category, impact}
