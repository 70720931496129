
import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'async.py'

export const exampleBefore = (
`async def task():
    download_files()
\t
async def download_files():
    pass`
)

export const exampleAfter = (
`async def task():
    await download_files()
\t
async def download_files():
    pass`
)

export const code = 'AwaitAsyncCall'

export const ogImage = `/og-image/${code}.png`

export const title = 'Missing await in coroutine call'

export const label = title

export const wordCode = 'await-async-call'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/asyncio-task.html#coroutines',
    text: 'Coroutines documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
		Calling a coroutine without the <Code>await</Code> statement just returns the coroutine without actually running the function.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>This can have the unexpected side-effect that your code is not executed at all. This demonstrates the importance of unit testing to highlight such issues.</Text>
  </>
)


export {category, impact}
