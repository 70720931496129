
import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'

import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'mail.py'

export const exampleBefore = (
`if value is 1:
    pass`
)

export const exampleAfter = (
`if value == 1:
    pass`
)

export const code = 'ComparePrimitivesByEqual'

export const ogImage = `/og-image/${code}.png`

export const title = 'Comparing primitives with identity checking'

export const label = title

export const wordCode = 'primative-identity-checking'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/reference/datamodel.html#object.__eq__',
    text: 'Python documentation on equality checking',
  },
  {
    href: 'https://docs.python.org/3/reference/expressions.html#is)',
    text: 'Python documentation on identity checking',
  },
  {
    href: 'https://docs.python.org/3/library/functions.html#id',
    text: 'Python documentation on id() function'
  },
  {
    href: 'https://docs.python.org/3/library/sys.html#sys.intern',
    text: 'Python documentation on sys.intern()'
  },
  {
    href: 'https://en.wikipedia.org/wiki/String_interning',
    text: 'Wikipedia page on interring'
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
        Primitive data types such as strings and integers should be compared using <Code>==</Code> and <Code>!=</Code> rather than <Code>is</Code> and <Code>is not</Code>.
    </Text>
  )
}

const codeExample = `
foo = 256
bar = 256
foo is bar  # outputs True

baz = 257
qux = 257
baz is qux  # outputs False
`

export const explanation = (
  <>
    <Text as={'p'}>The <Code>==</Code> operator checks equality, while <Code>is</Code> checks identity. Equality is for checking certain characteristics of the object have parity, while identity is for checking the objects are literally the same thing.</Text>
    <Text as={'p'}>The difference is <i>Guido van Rossum lookalikes are not Guido van Rossum</i>.</Text>
    <Text as={'p'}>Under the hood, the object's identity is determined using the <Code>id()</Code> function. <Code>id()</Code> returns an integer that is unique to the object for the lifetime of the object. For CPython this is the memory address of the object. Two objects cannot share the same memory location at the same time, after all.</Text>
    <Text as={'p'}>The object's equality is determined using the object's <Code>__eq__()</Code> function. This function's purpose is to return <i>do certain characteristics of the objects have parity</i>.</Text>
    <Text as={'p'}>Note though that Python performs interring for performance optimization. Interring ensures only one copy of each distinct value is stored in memory. For performance reasons Python inters integers between -5 to 256. This saves on memory usage for commonly used values, but it also allows bugs to creep into your code if you rely on identity checking for primitives:</Text>
    <Snippet value={codeExample}></Snippet>
    <Text as={'p'}>It's worth underlining that this behavior is a result of two Python features interacting: Python's identity checking feature with Python's memory usage optimization feature. <Code>foo</Code> is not really <Code>bar</Code>, but Python's optimization feature makes Python's identity checking feature think it is.</Text>
    <Text as={'p'}>Notice the behavior changes after 256. If you use identity checking on primitives and your unit tests only uses values below 256 then a hard-to-debug bug could occur in production. This shows you cannot rely on using identity checking primitives.</Text>
    <Text as={'p'}>Similarly, Python also automatically inters strings. But the behavior changes depending on the Python version. Before python 3.7 all strings of 20 characters or less was interred. Newer Python versions inter strings of 4096 characters or less.</Text>
  </>
)


export {category, impact}
