import React from 'react';

import { Box, Heading, Text, ButtonPrimary } from '@primer/components'

import WaveHero from '../../../components/WaveHero'


export default function(props) {
  return (
    <WaveHero>
      <Box className="container-lg pt-md-8 mb-10 pl-0 pr-0 pl-md-4 pr-md-4">
        <Box className="text-white m-md-0 text-center">
          <Heading as="h1" fontSize={58} paddingTop="15px" className="mb-4" lineHeight={"1em"}>Fix mistakes hidden in your Django codebase</Heading>
          <Text
            as="p"
            fontSize={4}
            className="text-white pl-2 pr-2 pl-md-0 pl-md-0 mb-2 container-md"
          >Trusted by <b>over 3000 teams</b> to improve their Django code. <br/>Securely check your codebase for free instantly.</Text>
          <ButtonPrimary
            as={'a'}
            variant="large"
            className="f3 p-3 mt-4 mb-6"
            href={'#a-quick-example'}
          >View demo
          </ButtonPrimary>
          <Text
            as="p"
            fontSize={4}
            className="text-white pl-2 pr-2 pl-md-0 pl-md-0 mb-2 container-md text-italic"
          >
          We're a proud corporate member of the <a href="https://www.djangoproject.com/foundation/corporate-members/" target="_blank" className="text-white text-underline">Django Software Foundation</a>.
          </Text>
        </Box>
      </Box>
    </WaveHero>
  )
}