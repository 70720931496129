import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_SECURITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'


export const code = 'C1001'

export const title = 'Django version is not maintained'

export const label = 'Unmaintained Django'

export const wordCode = 'django-version-support'  // this cannot be turned off really.

export const furtherReading = [
  {
    href: 'https://www.djangoproject.com/download/#supported-versions',
    text: 'Django documentation listing their supported versions',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Django version is not receiving bug fixes, and security fixes, and data-loss fixes.
    </Text>
  )
}


export const explanation = (
  <>
    <Text as={'p'}>Django releases are supported for a limited period of time. Some Django releases are <i>long-term support</i> (LTS) releases, which will get security and data loss fixes applied for typically three years.</Text>
    <Text as={'p'}>If the Django version a project uses is no longer supported then it will be vulnerable to bugs and hacks with no path to redemptions, so it is advisable to upgrade Django to a supported version.</Text>
  </>
)

export {category, impact}