import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'

export const exampleTitle = 'urls.py'

export const exampleBefore = (
`urlpatterns = [
    path('', Home.as_view(), name="index"),
    path('index/', Index.as_view(), name="index"),
]`
)

export const exampleAfter = (
`urlpatterns = [
    path('', Home.as_view(), name="index"),
    path('index/', Index.as_view(), name="index-list"),
]`
)

export const code = 'C6001'

export const title = 'URL name not unique'

export const label = title

export const wordCode = 'non-unique-url-name'

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/urls/',
    text: 'Django URLs documentation',
  },
]

export const djangoTag = "{% url 'url_name' %}"

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      URL names must be unique otherwise <code style={{fontSize: 'inherit'}}>reverse('url_name')</code> and <code style={{fontSize: 'inherit'}}>{ djangoTag }</code> will link to the "wrong" page half of the time.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>Django uses the URL name to generate the view's URL. Like a <code style={{fontSize: 'inherit'}}>dict</code>, only one entry can exist for a given "key".</Text>
  </>
)



export const ogImage = "/og-image/C6001.png"

export {category, impact}