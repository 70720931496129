import React from 'react'

import { Text } from '@primer/components'

import Snippet from '../../components/Snippet'
import Code from '../../components/Code'
import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = (
`def get_name(user):
    return "%s %s" % (user.first_name, user.last_name)`
)

export const exampleAfter = (
`def get_name(user):
    return f'{user.first_name} {user.last_name}'`
)

export const code = 'UseFString'

export const ogImage = `/og-image/${code}.png`

export const title = 'Use f-string instead of legacy string formatting'

export const label = 'Use f-string'

export const wordCode = 'use-f-string'

export const furtherReading = [
  {
    href: 'https://www.python.org/dev/peps/pep-0498/',
    text: 'Python documentation on f-string',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
        f-string is easier to read, write, and less computationally expensive than legacy string formatting.
    </Text>
  )
}


const codeExample = (
`value = f'{user.first_name} {user.last_name}'

value = "%s %s" % (user.first_name, user.last_name)

value = '{first_name} {last_name}'.format(
    first_name=user.first_name,
    last_name=user.last_name
)`
)

export const explanation = (
  <>
    <Text as={'p'}>f-strings provide a way to embed expressions inside string literals using minimal syntax. Through this f-strings are readable, more concise, and less prone to error than other ways of formatting.</Text>
    <Text as={'p'}>Legacy string formatting such as printf style and <Code>.format(...)</Code> are less readable than f-strings. Given that these are completely equivalent:</Text>
    <Snippet value={codeExample} />
    <Text as={'p'}>Why not choose the easiest to read, write, and quickest to execute (the first one)?</Text>
  </>
)


export {category, impact}