import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'

export const exampleTitle = 'views.py → admin.py'

export const exampleBefore = (
`from django.contrib import admin
from . import models
\t
admin.site.register(models.Author)`
)

export const exampleAfter = exampleBefore

export const code = 'C5000'

export const title = 'Admin class not in admin.py'

export const label = title

export const wordCode = 'misplaced-admin-class'

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/django-admin/',
    text: 'Django Admin documentation',
  },
]


export function Summary(props) {
  return (
    <>
      <Text as={'p'}>Predictable project structure and following common patterns simplifies maintenance of a codebase.</Text>
      <Text as={'p'}>Django developers come to expect Admin-related objects to be in admin.py. Failure to do this will result in more time spent looking for where code lives.</Text>
    </>
  )
}

export {category, impact}