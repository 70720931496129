import React from 'react'

import { Text } from '@primer/components'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'

import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'validation.py'

export const exampleBefore = (
`if isinstance(item, int) or isinstance(item, float):
    pass`
)

export const exampleAfter = (
`if isinstance(item, (int, float)):
    pass`
)

export const code = 'ChainedInstanceCheck'

export const ogImage = `/og-image/${code}.png`

export const title = 'Multiple isinstance calls'

export const label = title

export const wordCode = 'multiple-isinstance-calls'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/functions.html#isinstance',
    text: 'Python isinstance documentation',
  },
  {
    href: 'https://docs.python.org/3/library/stdtypes.html#types-union',
    text: 'Python Union Type documentation',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      <Code>isinstance</Code> can take multiple types, so there is no need to call them multiple times for each type.
    </Text>
  )
}

const pythonCodeExample = `
if isinstance(item, (int, float)):
    pass


if isinstance(item, int) or isinstance(item, float):
    pass
`

const codeExampleUnion = `
# python 3.10 and up
if isinstance(item, int | float):
    pass
`
export const explanation = (
  <>
    <Text as='p'><Code>isinstance</Code> return <Code>True</Code> if the first argument is an instance or a subclass of specified type/types.</Text>
    <Text as='p'>Given that these result in the same outcome:</Text>
    <Snippet value={pythonCodeExample}/> 
    <Text as={'p'}>Why not choose the easiest to read, write, and execute (the first one)?</Text>
    <Text as={'p'}>Note also that as of Python 3.10, <Code>isinstance</Code> also accepts Union Type:</Text>
    <Snippet value={codeExampleUnion} /> 
  </>
)


export {category, impact}