import React from 'react';

import { ProgressBar, Box, Text } from '@primer/components'


export default function(props) {
  return (
    <Box class="blankslate" style={{textAlign: 'center'}}>
      <Text fontSize={'42px'}><span  role="img" aria-label="fox">🦊</span></Text>
      <h3>Creating pull request</h3>
      <p>Hold tight. This won't take too long</p>
      <ProgressBar className="mt-4" progress={props.progress} />
    </Box>
  )
}