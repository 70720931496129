import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_LOW as impact } from '../../constants'

export const exampleTitle = 'views.py'

export const exampleBefore = (
`def my_view(request):
    url = reverse_lazy('foo')`
)

export const exampleAfter = (
`def my_view(request):
    url = reverse('foo')`
)

export const code = 'C6002'

export const title = 'Using reverse_lazy where reverse would be better'

export const label = 'Misusing reverse_lazy'

export const wordCode = 'reverse-lazy-misuse'

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/urlresolvers/#reverse-lazy',
    text: 'Django reverse_lazy documentation',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/topics/http/urls/',
    text: 'Django URLConf documentation',
  }
]



export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>Using <code style={{fontSize: 'inherit'}}>reverse(...)</code> is better than using <code style={{fontSize: 'inherit'}}>reverse_lazy(...)</code> when the url is being reversed after <code style={{fontSize: 'inherit'}}>URLConf</code> has been loaded.</Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>Django looks up the URL name as defined in <code style={{fontSize: 'inherit'}}>URLConf</code> to generate the URL. There are times when we want to use a URL before the <code style={{fontSize: 'inherit'}}>URLConf</code> has been loaded, such as:</Text>
    <Text as="ul" className="bullet-list-blue mt-4 mb-4">
      <Text as="li">Using the URL at module level, such as in a class-based view's <code style={{fontSize: 'inherit'}}>success_url</code></Text>
      <Text as="li">Using the URL in a view decorator such as <code style={{fontSize: 'inherit'}}>@permission_required('foo.bar', login_url=reverse_lazy(...))</code></Text>
      <Text as="li">Using the URL in a function default keyword argument</Text>
    </Text>
    <Text as={'p'} className="mt-2 mb-2">If the <code style={{fontSize: 'inherit'}}>URLConf</code> has been loaded there is no need to use <code style={{fontSize: 'inherit'}}>reverse_lazy(...)</code> - just use <code style={{fontSize: 'inherit'}}>reverse(...)</code>, becasue doing otherwise adds unecessary complexity.</Text>
  </>
)


export const ogImage = "/og-image/C6002.png"
export {category, impact}