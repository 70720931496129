import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'

export const exampleTitle = 'settings.py'

export const exampleBefore = (
`MIDDLEWARE =[
    "django.contrib.sessions.middleware.SessionMiddleware",
    "django.contrib.auth.middleware.AuthenticationMiddleware",
    "django.contrib.messages.middleware.MessageMiddleware",
    "django.middleware.common.CommonMiddleware",
]`
)

export const exampleAfter = exampleBefore

export const lineNumber = 5  // which line to show the comment on

export const code = 'C4004'

export const title = 'Middleware should be near the top'

export const label = 'Middleware near top'

export const wordCode = 'middleware-order-top'

export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/what-you-dont-know-about-middleware-ordering-could-hurt-your-website-22b92946bb19',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/middleware/#middleware-ordering',
    text: 'Django middleware order documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Incorrectly ordered middleware can stop the middleware working as intended.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>The order of middleware affections the outcome.</Text>
    <Text as={'p'}>Some middleware should be near the top of the middleware list to ensure it is one of the first to be called.</Text>
    <Text as={'p'}>For example CommonMiddleware, LocaleMiddleware, and SecurityMiddleware should be near the top.</Text>
  </>
)


export {category, impact}