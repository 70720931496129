import React from 'react'

import { Text } from '@primer/components'

import Snippet from '../../components/Snippet'
import Code from '../../components/Code'
import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = (
`from typing import Optional
\t
def foo_bar(value: Optional[str] = None):
    if value:
        ...`
)

export const exampleAfter = (
`from typing import Optional
\t
def foo_bar(value: Optional[str] = None):
    if value is not None:
        ...`
)

export const code = 'UseIsNoneOnOptional'

export const title = 'Explicitly compare Optional variables against None'

export const label ='Use Optional'

export const wordCode = 'compare-optional-against-none'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/typing.html#typing.Optional',
    text: 'Python documentation on Optional',
  },
  {
    href: 'https://www.python.org/dev/peps/pep-0020/',
    text: 'The Zen of Python',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Checking Optional variables against <Code>None</Code> would be more explicit.
    </Text>
  )
}

const codeExample = (
`from typing import Optional

def foo(value: Optional[str] = None):
    if value:
        ...
def bar(value: Optional[str] = None):
    if value is not None:
        ...
`
)


export const explanation = (
  <>
    <Text as={'p'}>A part of the Zen of Python is <i>implicit is better than implicit</i>. Compare these two functions:</Text>
    <Snippet value={codeExample} />
    <Text as={'p'}>Which more explicitly communicates the intent of the code to the developer reading it? Which is more permissive of unexpected falsey inputs were passed inadvertently passed to the function?</Text>
    <Text as={'p'}>Testing an object's truthiness via <Code>if value</Code> evaluates to <Code>True</Code> if <Code>value.__bool__()</Code> returns <Code>True</Code> or if <Code>value.__len__()</Code> returns an integer other than <Code>0</Code>, or if <Code>value</Code> is neither <Code>None</Code> or <Code>False</Code>. That's a lot of things a developer must to consider when building a mental model of the code.</Text>
    <Text as={'p'}>On the other hand, explicitly checking <Code>if value is not None</Code> removes those branches of thought entirely from the mental model a developer has to build when reading and understanding the code.</Text>
  </>
)


export {category, impact}