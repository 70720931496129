import React from 'react'

import { Text } from '@primer/components'
import { Link } from "react-router-dom";

import { CATEGORY_SECURITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'settings.py'

export const exampleBefore = `SECURE_HSTS_INCLUDE_SUBDOMAINS = False`

export const exampleAfter = `SECURE_HSTS_INCLUDE_SUBDOMAINS = True`

export const code = 'C4011'

export const title = 'Use HSTS for subdomainsd'

export const label = title

export const wordCode = 'missing-hsts-subdomain'

export const furtherReading = [
  {
    href: 'https://dev.to/djangodoctor/hacking-django-websites-man-in-the-middle-attack-3l4',
    text: 'Out blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/middleware/#http-strict-transport-security',
    text: 'Django HSTS documentation',
  },
  {
    href: 'https://en.wikipedia.org/wiki/Man-in-the-middle_attack',
    text: 'Wikipedia page for Man In The Middle attack'
  }
  
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Your website is vulnerable to Man In The Middle attacks on subdomains because the <code style={{fontSize: 'inherit'}}>SECURE_HSTS_INCLUDE_SUBDOMAINS</code> setting is missing - so a hacker can intercept and change requests performed over HTTP.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'} className="mb-3">If your website should be accessed via HTTPS then consider enabling HSTS and setting <code style={{fontSize: 'inherit'}}>SECURE_HSTS_INCLUDE_SUBDOMAINS</code> to protect subdomains. This will make the browser block HTTP requests to your website and instead use HTTPS.</Text>
    <Text as={'p'} className="mb-3">HSTS can be thought of like "client side HTTP to HTTPS redirect": the browser will turn HTTP to HTTPS requests.</Text>
    <Text as={'p'} className="mb-3">If your website is using HTTPS, then it is likely that your website redirects HTTP to HTTPS (if not, it should). However, a Man In The Middle attack can intercept the initial HTTP request and instead serve the user a malicious webpage that looks like your website. <code style={{fontSize: 'inherit'}}>SECURE_HSTS_INCLUDE_SUBDOMAINS</code> reduces the change of that because the brower will simply not do HTTP requests to your website.</Text>
    <Text as={'p'} className="mb-3">Note it's safe to set <code style={{fontSize: 'inherit'}}>SECURE_HSTS_INCLUDE_SUBDOMAINS</code> in your local dev env, as the header is only set when accessing the website via HTTPS, not HTTP.</Text>
  </>
)

export {category, impact}