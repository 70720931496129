import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import * as constants from './constants'

const IMPACTS = {
  [constants.IMPACT_HIGH]: 20,
  [constants.IMPACT_MEDIUM]: 5,
  [constants.IMPACT_LOW]: 1,
}

const MESSAGE_ORDER = [
  // big
  'E0001',
  'C9000',
  'C8000',
  'C8001',
  'C4006',
  'C4007',
  'C4008',
  'C4009',
  'C4010',
  'C4011',
  'C4017',
  'C4012',
  'C4013',
  'C4014',
  'C4015',
  'C4016',
  'C7001',
  'C4000',
  'C2006',
  'C2008',
  'C4003',
  'C2002',
  'C4004',
  'C4005',
  'C7000',
  'C6000',
  'C2011',
  'C4001',
  'C4002',

  // medium
  'C2004',
  'C2000',
  'C2003',
  'C2005',
  'C3002',
  'C5000',
  'C9001',
  'C9002',

  // meh
  'C2001',
  'C3000',
  'C3003',

  //dynamically added
  'C1001',
  'C1002',
]

export const getCheckProviders = function(messages) {
  const providers = []
  for (let message of messages) {
    if (message.message_type === 'meta' && message.body.meta_type === 'provider_name') {
      providers.push(message.body.provider)
    }
  }
  return providers
}

export const groupMessagesByFile = function(messages, sort, corpus) {
  let group = new Map()
  let messageId;
  for (let message of messages) {
    if (message.message_type === 'line_centric') {
      const path = message['path']
      if (!group.has(path)) {
        group.set(path, [])
      }
      group.get(path).push(message)
    }
  }
  const asArray = Array.from(group.entries())

  for (let [path, messages] of asArray) {
    messages.sort((a, b) => a.line < b.line ? -1 : 1)
  }
  if (!sort) {
    return asArray
  }
  return asArray.sort((a, b) => {
    // getting highest priority items in each file
    const x = sumArray(a[1].flatMap(item => item.message_ids).map(item => IMPACTS[corpus[item].impact]))
    const y = sumArray(b[1].flatMap(item => item.message_ids).map(item => IMPACTS[corpus[item].impact]))

    if (x > y) {
      return -1;
    }
    if (x < y) {
      return 1;
    }
    return 0;
  });
}

export const groupMessagesByMessageId = function(messages, sort) {
  if (sort === undefined) {
    sort = true
  }
  let group = new Map()
  let messageId;
  for (let message of messages) {
    // checking undefined for backwards compatibility
    if (message.message_type === undefined || message.message_type === 'message_centric') {
      messageId = message['message-id']
      if (!group.has(messageId)) {
        group.set(messageId, [])
      }
      group.get(messageId).push(message)
    }
  }
  const asArray = Array.from(group.entries())
  if (sort) {
    return asArray.sort((a, b) => {
      const x = MESSAGE_ORDER.indexOf(a[0])
      const y = MESSAGE_ORDER.indexOf(b[0])
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
  } else {
    return asArray
  }
}

export const countMessageGroups = function(messages, corpus) {
  const counts = {
    security: 0,
    performance: 0,
    maintainability: 0,
    bugs: 0,
    testing: 0,
    low: 0,
    medium: 0,
    high: 0,
    checks: {},
  }
  for (let message of messages) {
    if (!corpus[message['message-id']]) {
      continue  // remove this once all message codes are catered for
    }
    const messageId = message['message-id']

    const { category, impact } = corpus[messageId]
    if (counts[category] !== undefined) {
      counts[category] += 1
    }
    if (counts[impact] !== undefined) {
      counts[impact] += 1
    }
    if (counts.checks[messageId] !== undefined) {
      counts.checks[messageId] += 1
    } else {
      counts.checks[messageId] = 1
    }
  }
  return counts
}


export function boostrapThirdParties() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
  });

}

export function countLines(text) {
  return (text.match(/\n/g) || '').length + 1
}


function sumArray(value){
  return value.reduce((partialSum, a) => partialSum + a, 0)
}
