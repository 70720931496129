import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_SECURITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'


export const exampleTitle = 'settings.py'

export const exampleBefore = `SECURE_SSL_REDIRECT = False`

export const exampleAfter = (
`from ast import literal_eval
from os import getenv

# feature flagged so can turn off in local development
SECURE_SSL_REDIRECT = literal_eval(getenv("HTTPS_ONLY", "True"))`
)


export const code = 'C4013'

export const title = 'Use HTTPS'

export const label = title

export const wordCode = 'missing-secure-ssl-redirect'

export const furtherReading = [
  {
    href: 'https://dev.to/djangodoctor/hacking-django-websites-30f',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/3.1/ref/middleware/#module-django.middleware.security',
    text: 'Django security documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Your website is vulnerable because the <code style={{fontSize: 'inherit'}}>SECURE_SSL_REDIRECT</code> setting is not set - so a hacker can read, intercept, and change requests performed over HTTP.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'} className="mb-3"><code style={{fontSize: 'inherit'}}>SecurityMiddleware</code> redirects HTTP to HTTPS when <code style={{fontSize: 'inherit'}}>SECURE_SSL_REDIRECT = True</code> to prevent browsers from using the website on insecure HTTP connections.</Text>
    <Text as={'p'} className="mb-3">By setting <code style={{fontSize: 'inherit'}}>SECURE_SSL_REDIRECT = True</code>, the browser will be redirected to HTTPS if it performs a HTTP request.</Text>
  </>
)


export {category, impact}