import React, { useState } from 'react'

import { useFirebaseAuth } from '../hooks/useFirebaseAuth'
import { useOctokit } from '../hooks/useOctokit'
import { Button } from '@primer/components'

import FirebaseAuth from 'react-firebaseui/FirebaseAuth';


export default function GrantPrivateAccessButton(props) {
  const firebaseAuth = useFirebaseAuth()
  const [octokit, setAuth] = useOctokit()
  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      {
        provider: 'github.com',
        scopes: ['public_repo', 'repo', 'read:org'],
        fullLabel: props.label,
      }
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult, url) => {
        setAuth({
          accessToken: authResult.credential.accessToken,
          username: authResult.additionalUserInfo.username,
          permission: 'private'
        })
        props.onLogin && props.onLogin()
        return false
      }
    }
  }
  if (props.asButton) {
    return <Button className={props.className} as={FirebaseAuth} uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
  }
  return <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
}


GrantPrivateAccessButton.defaultProps = {
  label: 'sign in with GitHub',
  asButton: false,
}
