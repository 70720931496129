import React from 'react';

import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { useSubscribe } from '../hooks/useSubscribe'
import { useProduct } from '../hooks/useProduct'
import Subscribe from './Subscribe'


const colour = 'text-gray-dark'


const Component = function(props) {
  const [isSubscribed, handleSubscribed] = useSubscribe()
  const productDetails = useProduct()

  // width breakpoints to prevent covering the paginator on desktop
  return (
    <footer className="width-full" style={{background: 'white', borderTop: '1px solid #dedede'}}>
      <div style={{verticalAlign: 'middle'}}>
        <span className={`${colour} position-relative`}>© Higher Tier Systems</span>
        <Spacer />
        <Link className={colour} to='/privacy'>Privacy</Link>
        <Spacer />
        <Link className={colour} to='/terms'>Terms</Link>
        <Spacer />
        <Subscribe
          handleSubscribed={handleSubscribed}
          isSubscribed={isSubscribed}
        />
        <Spacer />
        <Link className={colour} to='/config'>Config</Link>
        <Spacer />
        <a className={colour} href={productDetails.gitHubMarketplaceLink}>GitHub</a>
        <Spacer />
        <a className={colour} href={'/features/bitbucket/price'}>Bitbucket</a>
      </div>
    </footer>
  )
}

const Spacer = () => <span className={`ml-md-3 mr-md-3 ml-1 mr-1 ${colour}`}>·</span>

export default withRouter(Component)