import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_SECURITY as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'


export const code = 'C1002'

export const title = 'Django bug fixes and additional features available'

export const label = "Django bug fixes available"

export const before = ``

export const after = ``

export const wordCode = 'django-minor-version'  // this cannot be turned off really.

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/dev/releases/',
    text: 'Django documentation listing their release notes',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      New version of Django is available, including bug fixes and new features.
    </Text>
  )
}


export const explanation = (
  <>
    <Text as={'p'}>New Django versions are frequently released that apply bug fixes, security fixes, and data loss fixes.</Text>
    <Text as={'p'}>If the Django version a project uses does not have the latest version then it will be more vulnerable to bugs and hacks, so it is advisable to upgrade Django to the latest version.</Text>
  </>
)

export {category, impact}