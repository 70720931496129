import React from 'react'

import { Text } from '@primer/components'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'

import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'catalogue.py'

export const exampleBefore = (
`with open('some/path.json') as f:
    content = json.loads(f.read())`
)

export const exampleAfter = (
`with open('some/path.json') as f:
    content = json.load(f)`
)

export const code = 'UseJsonLoad'

export const ogImage = `/og-image/${code}.png`

export const title = 'Use json.load to read a JSON file'

export const label = 'Use json.load'

export const wordCode = 'use-json-load'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/library/json.html#json.load',
    text: 'Python json.load documentation',
  },
  {
    href: 'https://docs.python.org/3/library/json.html#json.loads',
    text: 'Python json.loads documentation',
  },
  {
    href: 'https://github.com/python/cpython/blob/d7c567b08f9d7d6aef21b881340a2b72731129db/Lib/json/__init__.py#L293',
    text: 'CPython source code for json.load'
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      <Code>json.load(f)</Code> simplifies reading JSON from disk.
    </Text>
  )
}

const pythonCodeExample = (
`with open('some/path.json', 'r') as f:
    content = json.load(f)

with open('some/path.json', 'r') as f:
    content = json.loads(f.read())`
)

export const explanation = (
  <>
    <Text as='p'>If you ever wondered why <Code>json.loads</Code> ends with a "s" it's because it's specifically for working on a string, but there is another related method: <Code>json.load</Code></Text>
    <Text as='p'><Code>json.loads</Code> is for deserialising a <i>string</i> containing JSON, while <Code>json.load</Code> (no "s") is for deserialising a <i>file</i> containing JSON.</Text>
    <Text as='p'>The methods are very related. In fact, <Code>json.load</Code> is a <a href="https://github.com/python/cpython/blob/d7c567b08f9d7d6aef21b881340a2b72731129db/Lib/json/__init__.py#L293">wrapper around <Code>json.loads</Code></a>. It's a method provided out of the box by Python to simplify the task of reading JSON from file-like objects.</Text>
    <Text as="p">Python core developers are very careful about what features are included in the Python builtin modules, so it makes sense to utilise the convenience methods and not reinvent the wheel? For example, these result in the same outcome:</Text>
    <Snippet value={pythonCodeExample}/>
    <Text as={'p'}>So why not choose the easiest to read, write, and execute (the first one)?</Text>
  </>
)


export {category, impact}