import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_MEDIUM as impact } from '../../constants'


export const exampleTitle = 'models.py'

export const exampleBefore = (
`class CommentModel(models.Model):
    is_active = models.NullBooleanField()
    body = models.TextField()`
)

export const exampleAfter = (
`class CommentModel(models.Model):
    is_active = models.BooleanField(null=True)
    body = models.TextField()`
)

export const code = 'C2008'

export const title = 'Deprecated NullBooleanField'

export const label = title

export const wordCode = 'deprecated-nullboolean-field'

export const furtherReading = [
  {
    href: 'https://djangodoctor.medium.com/nullbooleanfield-is-dead-7d3fc286ed6e',
    text: 'Our blog post on the topic',
  },
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#booleanfield',
    text: 'Django BooleanField documentation',
  },
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/models/fields/#nullbooleanfield',
    text: 'Django NullBooleanField documentation',
  },
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      <code style={{fontSize: 'inherit'}}>NullBooleanField</code> is deprecated and will be removed a future version of Django.
    </Text>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>NullBooleanField is like BooleanField with null=True.</Text>
    <Text as={'p'}>Historically the only values BooleanField accepted was True and False. BooleanField didn't permit null=True. If null was a valid value then NullBooleanField was used instead.</Text>
  </>
)



export const ogImage = "/og-image/C2008.png"
export {category, impact}