import React from 'react'

import { Text } from '@primer/components'

import Snippet from '../../components/Snippet'
import Code from '../../components/Code'
import { CATEGORY_MAINTAINABILITY as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = `value = f'some-value'`

export const exampleAfter = `value = 'some-value'`

export const code = 'NoRedundantFString'

export const title = 'Redundant f-string'

export const label = title

export const wordCode = 'avoid-redundant-f-string'

export const furtherReading = [
  {
    href: 'https://www.python.org/dev/peps/pep-0498/',
    text: 'Python documentation on f-string',
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
        f-string is unnecessary here as there are no placeholders in the string.
    </Text>
  )
}


export const explanation = (
  <>
    <Text as={'p'}>f-strings provide a way to embed expressions inside string literals using minimal syntax. Through this f-strings are readable, more concise, and less prone to error than other ways of formatting.</Text>
    <Text as={'p'}>However, if the string does not contain any expressions or placeholders then there is no need to use an f-string. Just use a string.</Text>
  </>
)


export {category, impact}