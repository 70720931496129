import React, { useContext, } from "react";

import { Flex, Box, Heading, Button, ButtonGroup, ButtonPrimary, Tooltip, Text } from '@primer/components'
import { Link } from "react-router-dom";

import { useProduct } from '../hooks/useProduct'


const styleContainer = {
  'backgroundColor': 'rgb(27, 31, 35)',
  'color': 'white',
  'position': 'sticky',
  'top': '0px',
  'zIndex': 100,
}

export default function(props) {
  const productDetails = useProduct()

  return (
    <Box style={styleContainer} className="pl-md-1 pr-md-4">
      <Flex height="61px" className="container-xl">
        <div style={{color: "white"}} className="d-flex mr-4">
          <Link to="/">
            <img height="58px" width="58px" alt="Logo of a fox dressed as a Doctor" style={{marginTop: '2px'}} src={'/torso-compressed.png'} />
          </Link>
          <div className="text-right" style={{lineHeight: '1.1', marginTop: '6px', marginLeft: '-10px'}}>
            <Link to="/">
              <Heading fontSize={3} mt={'5px'} className="hide-sm text-white">{productDetails.name}</Heading>
            </Link>
            <div className="hide-sm mt-1">
              <svg className="octicon octicon-verified text-green mr-1" height="18" viewBox="0 0 16 16" version="1.1" width="12" aria-hidden="true"><path fillRule="evenodd" d="M9.585.52a2.678 2.678 0 00-3.17 0l-.928.68a1.178 1.178 0 01-.518.215L3.83 1.59a2.678 2.678 0 00-2.24 2.24l-.175 1.14a1.178 1.178 0 01-.215.518l-.68.928a2.678 2.678 0 000 3.17l.68.928c.113.153.186.33.215.518l.175 1.138a2.678 2.678 0 002.24 2.24l1.138.175c.187.029.365.102.518.215l.928.68a2.678 2.678 0 003.17 0l.928-.68a1.17 1.17 0 01.518-.215l1.138-.175a2.678 2.678 0 002.241-2.241l.175-1.138c.029-.187.102-.365.215-.518l.68-.928a2.678 2.678 0 000-3.17l-.68-.928a1.179 1.179 0 01-.215-.518L14.41 3.83a2.678 2.678 0 00-2.24-2.24l-1.138-.175a1.179 1.179 0 01-.518-.215L9.585.52zM7.303 1.728c.415-.305.98-.305 1.394 0l.928.68c.348.256.752.423 1.18.489l1.136.174c.51.078.909.478.987.987l.174 1.137c.066.427.233.831.489 1.18l.68.927c.305.415.305.98 0 1.394l-.68.928a2.678 2.678 0 00-.489 1.18l-.174 1.136a1.178 1.178 0 01-.987.987l-1.137.174a2.678 2.678 0 00-1.18.489l-.927.68c-.415.305-.98.305-1.394 0l-.928-.68a2.678 2.678 0 00-1.18-.489l-1.136-.174a1.178 1.178 0 01-.987-.987l-.174-1.137a2.678 2.678 0 00-.489-1.18l-.68-.927a1.178 1.178 0 010-1.394l.68-.928c.256-.348.423-.752.489-1.18l.174-1.136c.078-.51.478-.909.987-.987l1.137-.174a2.678 2.678 0 001.18-.489l.927-.68zM11.28 6.78a.75.75 0 00-1.06-1.06L7 8.94 5.78 7.72a.75.75 0 00-1.06 1.06l1.75 1.75a.75.75 0 001.06 0l3.75-3.75z"></path></svg>
              <Text as="a" fontSize={1} href={`${productDetails.gitHubMarketplaceLink}`} className="text-white text-bold">Verified by GitHub</Text>
            </div>
          </div>
        </div>
        <div className="text-bold text-center" style={{marginLeft: 'auto', justifyContent: 'center', display: 'flex', alignItems: 'center', lineHeight: '1.5em',}}>
          <Text as={Link} to={'/features/github'} fontSize={3} className="pr-4 d-inline-block text-white">GitHub integration</Text>
          <Text as={Link} style={{display: 'none'}} to={'/features/bitbucket'} fontSize={3} className="pr-4 d-inline-block text-white">Bitbucket integration</Text>
          <Text as={Link} to={'/features/python'} fontSize={3} className="pr-4 d-inline-block text-white">Python checks</Text>
          <Text as={Link} to={'/features/django'} fontSize={3} className="pr-4 d-inline-block text-white hide-sm hide-md hide-lg">Django checks</Text>
          <Text as={Link} to={'/code-review-doctor/a-quick-example/django-and-python'} fontSize={3} className="pr-4 d-inline-block text-white">Demo</Text>
          <ButtonPrimary
            as={Link}
            to={'/@me'}
            className="hide-sm hide-md hide-lg"
          >Check repository</ButtonPrimary>
        </div>
      </Flex>
      {props.children}
    </Box>
    )
  }
