import React, { Component, useContext } from 'react';

import {
  BorderBox,
  Box,
  ProgressBar,
  Text,
  BranchName,
  Flash,
  Heading,
} from '@primer/components'

import compareVersions from 'compare-versions'

import { useStore, getGitHubAuthToken, getGitHubUsername } from '../../Store'
import { useOctokit } from '../../hooks/useOctokit'
import { useProduct } from '../../hooks/useProduct'
import { CorpusContext } from '../../hooks/useCorpus'

import HeaderLevelTwoRepository from '../../components/HeaderLevelTwoRepository'
import FixItForMe from './FixItForMe'
import FixItForMeProgress from './FixItForMeProgress'
import FixItForMeLink from './FixItForMeLink'
import RateLimited from '../../components/RateLimited'
import Seo from '../../components/Seo'
import { groupMessagesByFile as groupMessages, countMessageGroups, getCheckProviders } from '../../helpers'
import { retrieveBranches, retrieveRepository} from '../../github'

import PullRequestTimeline from './PullRequestTimeline'
import BranchMenu from './BranchMenu'
import NotFound from './NotFound'

const S3Url = 'https://django-doctor-results.s3.eu-west-2.amazonaws.com' // legacy name from when this was called djangodoctor

const NO_RESULTS = 'no-results'
const RESULTS = 'results'
const PROGRESS = 'progress'
const NO_MATCHED_PROVIDERS = 'no-matched-providers'
const CLONE_DENIED = 'clone-denied'
const RETRIEVING_RESULTS = 'retrieving-results'
const api_gateway_endpoint_url = 'sv80ole44l.execute-api.us-east-1.amazonaws.com/Test'


const get = url => {
  return fetch(url, {
    method: 'get',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    }
  })
}

const setCachedCommitHash = function(owner, repository, branch, commitHash) {
  const key = getCachedCommitHashKey(owner, repository, branch)
  return localStorage.setItem(key, commitHash)
}


const getCachedCommitHash = function(owner, repository, branch) {
  const key = getCachedCommitHashKey(owner, repository, branch)
  return localStorage.getItem(key)
}


const getCachedIsInstalled = function(owner, repository) {
  const key = getCachedIsInstalledKey(owner, repository)
  return localStorage.getItem(key) === 'true'
}

const setCachedIsInstalled = function(owner, repository, data) {
  const key = getCachedIsInstalledKey(owner, repository)
  return localStorage.setItem(key, data)
}


const getS3ObjectKeyForBranch = (owner, repository, branch, productId) => { 
  return `${S3Url}/${owner}/${repository}/${branch}`
}
const getCachedCommitHashKey = (owner, repository, branch) => `${owner}-${repository}-${branch}:commitHash`
const getCachedIsInstalledKey = (owner, repository) => `${owner}-${repository}:installed`


class Repository extends Component {

  constructor(props) {
    super(props);

    this.owner = this.props.match.params.owner
    this.repository = this.props.match.params.repository

    this.progressTickFinishedWorkers = new Set()
    this.repositoryDetails = null

    let branch;
    if (this.props.match.params.branch) {
      branch = this.props.match.params.branch
    } else {
      branch = this.repositoryDetails === null ? 'master' : this.repositoryDetails.default_branch
    }

    this.state = {
      messages: [],
      messageCentricMessages: [],
      report: [],
      checkProviders:[],
      progressTickCount: 0,
      progressTickFinishedCount: 0,
      step: undefined,
      branch: branch,
      branches: [branch],
      commitHash: getCachedCommitHash(this.owner, this.repository, branch),
      isRepository404: false,
      isInstalled: getCachedIsInstalled(this.owner, this.repository) || false,
      transformationPullRequestUrl: undefined,
      isCreatingPullRequest: false,
      isCreatePullRequestPermissionDenied: false,
      djangoPyPiDetails: undefined,
      fixItForMe: {
        activeMessageIndex: 0,
        acceptedChanges: [],
        skippedChanges: [],
      }

    }
    this.renderResults = this.renderResults.bind(this)
    this.renderProgress = this.renderProgress.bind(this)
    this.runCheck = this.runCheck.bind(this)
    this.handleBranchChange = this.handleBranchChange.bind(this)
    this.retrieveResultsFromS3 = this.retrieveResultsFromS3.bind(this)
    this.retrieveBranches = this.retrieveBranches.bind(this)
    this.retrieveRepository = this.retrieveRepository.bind(this)
    this.retrieveResults = this.retrieveResults.bind(this)
    this.isS3ResultsFresh = this.isS3ResultsFresh.bind(this)
    this.handleWebsocketMessage = this.handleWebsocketMessage.bind(this)
    this.websocketConnect = this.websocketConnect.bind(this)
    this.handleRepository404 = this.handleRepository404.bind(this)
    this.handleCheckIsInstalled = this.handleCheckIsInstalled.bind(this)
    this.handleCreatePullrequest = this.handleCreatePullrequest.bind(this)
    this.getMetadata = this.getMetadata.bind(this)
    this.getPyPiDjangoDetails = this.getPyPiDjangoDetails.bind(this)
    this.getDjangoVersionDetails = this.getDjangoVersionDetails.bind(this)
    this.addDynamicMessages = this.addDynamicMessages.bind(this)
    this.groupMessages = this.groupMessages.bind(this)
    this.onGrantPrivateAccessCallback = this.onGrantPrivateAccessCallback.bind(this)
    this.entrypoint = this.entrypoint.bind(this)
    this.getPyPiDjangoDetails()
    this.handleCheckIsInstalled()
    this.entrypoint()
  }

  entrypoint() {
    this.retrieveRepository()
      .then(result => {
        // cant get results for the first time without first knowing the default branch
        this.retrieveBranches().then(this.retrieveResults)
      })
      .catch(error => {
        if (error.status === 404) {
          this.handleRepository404()
        }
      })

    // job flow is as such:
    // 1 [local] check if cached default branch set
    // 2 [remote] retrieve repo details if not set to get the default branch name
    // 5 [local] check if cached is newest
    // 6 [remote] retrieve from s3

    // 1. [remote] run job via websocket
  }

  groupMessages(messages, sort) {
    this.addDynamicMessages(messages)
    return groupMessages(messages, sort, this.props.corpus)
  }

  addDynamicMessages(messages) {
    const djangoVersionDetails = this.getDjangoVersionDetails()
    const metadata = this.getMetadata()
    if (metadata && !djangoVersionDetails.isSupported) {
      if (!messages.find(message => message['message-id'] === 'C1001')) {
        messages.push({
          'message-id': 'C1001',
          path: metadata.filetype,
          message_type: "message_centric",
        })
      }
    }
    if (metadata && djangoVersionDetails.isNewMinorVersionAvailable) {
      if (!messages.find(message => message['message-id'] === 'C1002')) {
        messages.push({
          'message-id': 'C1002',
          path: metadata.filetype,
          message_type: "message_centric",
        })
      }
    }
  }

  getPyPiDjangoDetails() {
    return fetch(`https://pypi.org/pypi/Django/json`)
      .then(response => response.json())
      .then(djangoPyPiDetails => this.setState({djangoPyPiDetails}))
      .catch(() => {})
  }

  getDjangoVersionDetails() {
    if (this.state.djangoPyPiDetails && this.state.djangoPyPiDetails.releases) {
      const supportedVersions = ['2.2', '3.1', '3.2', '4.0', '4.1', '4.2', '5']
      const metadata = this.getMetadata()
      if (metadata && metadata.matchingVersions) {
        const availableVersions = Object.keys(this.state.djangoPyPiDetails.releases).filter(item => {
          return !item.includes('a') && !item.includes('b') && !item.includes('rc') && !item.includes('c')
        }).sort(compareVersions)
        const newestMatchingVersion = metadata.matchingVersions[metadata.matchingVersions.length -1]
        const newestAvailableMajorVersion = availableVersions[availableVersions.length -1]
        const specifierResolves = metadata.matchingVersions.sort(compareVersions).reverse().find(item => availableVersions.includes(item))

        let newestMatchingVersionDetails
        let newestAvailableMinorVersion
        let specifierResolvesMinor
        if (specifierResolves) {
          specifierResolvesMinor = specifierResolves.split('.').slice(0,-1).join('.')
          newestMatchingVersionDetails = this.state.djangoPyPiDetails.releases[specifierResolves][0]
          const availableMinorVersions = availableVersions.filter(item => item.startsWith(specifierResolvesMinor))
          newestAvailableMinorVersion = availableMinorVersions[availableMinorVersions.length -1]
        }
        return {
          newestAvailableMinorVersion,
          newestMatchingVersion,
          newestAvailableMajorVersion,
          specifierResolves,
          newestMatchingVersionDetails,
          isNewMinorVersionAvailable: specifierResolves !== newestAvailableMinorVersion,
          isNewMajorVersionAvailable: specifierResolves !== newestAvailableMajorVersion,
          isSupported: supportedVersions.includes(specifierResolvesMinor),
          ...metadata,
        }
      }
    }
    return {}
  }

  handleCheckIsInstalled() {
    fetch(process.env.REACT_APP_CHECK_INSTALLATION_ENDPOINT, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({'owner': this.owner, 'repository': this.repository})
    }).then(result => {
      var that = this
      result.json().then(function(parsed) {
        setCachedIsInstalled(that.owner, that.repository, parsed.installed)
        that.setState({isInstalled: parsed.installed})
      })
    }).catch(result => {
      setCachedIsInstalled(this.owner, this.repository, false)
      this.setState({isInstalled: false})
    })
  }

  handleRepository404() {
    this.setState({isRepository404: true})
  }

  onGrantPrivateAccessCallback() {
    this.setState({isRepository404: false})
    this.entrypoint()
  }

  websocketConnect() {
    const promise = new Promise(function(resolve) {
      this.websocket = new WebSocket(`wss://${api_gateway_endpoint_url}`)
      this.websocket.onopen = event => resolve(this.websocket)
      this.websocket.onmessage = this.handleWebsocketMessage
    }.bind(this))
    return promise
  }

  handleWebsocketMessage(event) {
    if (!event.data) {
      return
    }
    const message = JSON.parse(event.data)
    if (message.meta.stage === 'task_start_success') {
      this.progressTickFinishedWorkers = new Set()
      this.setState({
        commitHash: message.data.commit_hash,
        // 3 ticks per worker: received job, cloned repo, finished job
        progressTickCount: message.data.worker_count * 3,
        progressTickFinishedCount: 0,
        checkProviders: [],
        report: [],
        messages: [],
        messageCentricMessages: [],
      })
    } else if (message.meta.stage === 'task_start_failed') {
      if (message.data.reason === NO_MATCHED_PROVIDERS) {
        this.setState({step: NO_MATCHED_PROVIDERS, messages: []})
      } else if (message.data.reason === 'CLONE_DENIED') {
        this.setState({step: CLONE_DENIED, messages: []})
      }
    } else if (message.meta.stage === 'worker_received_job') {
      this.progressTickFinishedWorkers.add(`${message.meta.worker}:received-job`)
      this.setState({progressTickFinishedCount: this.progressTickFinishedWorkers.size})
    } else if (message.meta.stage === 'worker_check_start') {
      this.progressTickFinishedWorkers.add(`${message.meta.worker}:cloned-repo`)
      this.setState({progressTickFinishedCount: this.progressTickFinishedWorkers.size})
    } else if (message.meta.stage === 'worker_success') {
      this.progressTickFinishedWorkers.add(`${message.meta.worker}:finished-job`)
      this.setState((state, props) => ({
        messages: [state.messages, ...message.data],
        report: [...state.report, ...groupMessages(message.data)],
        checkProviders: [...state.checkProviders, ...getCheckProviders(message.data)],
        messageCentricMessages: [...state.messageCentricMessages, ...getMessageCentricMessages(message.data)],
        progressTickFinishedCount: this.progressTickFinishedWorkers.size,
      }));
    } else if (message.meta.stage === 'worker_failed') {
      // 3 ticks per worker: received job, downloaded code, finished job
      this.progressTickFinishedWorkers.add(`${message.meta.worker}:finished-job`)
      this.setState((state, props) => ({
        progressTickFinishedCount: this.progressTickFinishedWorkers.size,
      }));

    } else if(message.meta.stage === 'last_worker_finished') {
      // save commit hash here rather than at start to avoid having the cache but not having the results
      setCachedCommitHash(this.owner, this.repository, this.state.branch, this.state.commitHash)
      this.setState({ step: RESULTS, progressTickFinishedCount: 0, progressTickCount: 0 })
      this.websocket.close()
    } else if(message.meta.stage === 'transformation_task_started') {
      this.setState({
        commitHash: message.data.commit_hash,
        // 2 ticks per worker: received job, finished job
        progressTickCount: message.data.file_count * 2,
        progressTickFinishedCount: 0,
      })
    } else if (message.meta.stage === 'transformation_files_started') {
      // TODO: fix
      this.setState({progressTickFinishedCount: this.progressTickFinishedWorkers.size + message.data.file_count})
    } else if (message.meta.stage === 'transformation_file_success') {
      this.setState({progressTickFinishedCount: this.progressTickFinishedWorkers.size + 1})
    } else if(message.meta.stage === 'push_failed') {
      this.setState({
        transformationPullRequestUrl: undefined,
        isCreatingPullRequest: false,
        isCreatePullRequestPermissionDenied: true,
      })
    } else if(message.meta.stage === 'transformation_complete') {
      this.setState({
        transformationPullRequestUrl: message.data.pull_request_url,
        isCreatingPullRequest: false,
        isCreatePullRequestPermissionDenied: false,
        progressTickFinishedCount: 0,
        progressTickCount: 0,
      })
    }

  }
  
  retrieveRepository() {
    return retrieveRepository(this.props.octokit, this.owner, this.repository)
    .then(({ data }) => {
      this.repositoryDetails = data
      if (!this.props.match.params.branch) {
        this.setState({branch: data['default_branch']})
      }
    })
  }

  retrieveBranches() {
    return retrieveBranches(this.props.octokit, this.owner, this.repository)
    .then(({ data }) => {
      this.setState({branches: data})
    })
  }

  retrieveResults() {
    this.retrieveResultsFromS3()
  }

  isS3ResultsFresh(commitHash) {
    for (let branch of this.state.branches){
      if (branch.name === this.state.branch && branch.commit.sha === commitHash) {
        return true
      }
    }
    return false
  }

  retrieveResultsFromS3() {
    const url = getS3ObjectKeyForBranch(this.owner, this.repository, this.state.branch, this.props.productDetails.id)
    this.setState({step: RETRIEVING_RESULTS})
    if (this.state.branch === 'python-and-django-issues') {
      this.setState({step: PROGRESS}, this.runCheck)
      return 
    }
    get(`${url}?rev=${this.state.commitHash}`).then(response => {

      if (response.status === 200) {
        // exposed because cors configuration on bucket allows via ExposeHeader
        const commitHash = response.headers.get('x-amz-meta-sha')
        const isResultsFresh = this.isS3ResultsFresh(commitHash)
        response.json().then(messages => {
          setCachedCommitHash(this.owner, this.repository, this.state.branch, commitHash)
          const aa = this.props
          this.setState({
            report: this.groupMessages(messages, true),
            checkProviders: getCheckProviders(messages),
            messages: messages,
            messageCentricMessages: getMessageCentricMessages(messages),
            step: isResultsFresh ? RESULTS : PROGRESS,  
            commitHash,
          }, isResultsFresh ? () => {} : this.runCheck)
        })
      } else if (response.status === 403 ) {
        this.setState({step: PROGRESS}, this.runCheck)
      }
    })
  }
  runCheck() {
    const data = {
      'meta': {stage: 'check-project'},
      'data': {
        'clone_url': this.repositoryDetails.clone_url,
        'is_private': this.repositoryDetails.private,
        'branch': this.state.branch,
        'api_gateway_endpoint_url': `https://${api_gateway_endpoint_url}`,
        'version': process.env.REACT_APP_BACKEND_TARGET_ALIAS,
        'access_token': this.props.accessToken,
      }
    }
    this.websocketConnect().then(websocket => websocket.send(JSON.stringify(data)))
  }

  handleBranchChange(branch) {
    this.setState(
      {
        checkProviders: [],
        report: [],
        messages: [],
        messageCentricMessages: [],
        branch,
        commitHash: getCachedCommitHash(this.owner, this.repository, branch),
      },
      function() {
        this.props.history.push(`/${this.owner}/${this.repository}/${this.state.branch}`)
        this.retrieveResults()
      }
    )
    document.body.click()
  }

  handleCreatePullrequest(whitelist) {
    this.setState({isCreatingPullRequest: true, isCreatePullRequestPermissionDenied: false})
    const data = {
      'meta': {stage: 'run_transformer'},
      'data': {
        'clone_url': this.repositoryDetails.clone_url,
        'is_private': this.repositoryDetails.private,
        'branch': this.state.branch,
        'api_gateway_endpoint_url': `https://${api_gateway_endpoint_url}`,
        'version': process.env.REACT_APP_BACKEND_TARGET_ALIAS,
        'access_token':this.props.accessToken,
        'username': this.props.username,
        'owner': this.owner,
        'repo': this.repository,
        'whitelist': whitelist,
      }
    }
    this.websocketConnect().then(websocket => websocket.send(JSON.stringify(data)))
  }

  getMetadata(messages) {
    for (let message of this.state.messages) {
      if (message['message-id'] === 'C1000') {
        return {
          specifier: message.body.specs,
          matchingVersions: message.body.matching_versions,
          filetype: message.path,
        }
      }
    }
  }

  renderResults() {
    const noIssuesDetected = this.state.step === RESULTS && this.state.messages.length === 0
    if (noIssuesDetected) {
      return <Text>No issues found.</Text>
    }
    if (this.state.report.length === 0) {
      return null
    }
    return (
      <PullRequestTimeline
        messages={this.state.messageCentricMessages}
        report={this.state.report}
        repoBlobLink={`https://github.com/${this.owner}/${this.repository}/blob/${this.state.branch}`}
      />
    )
  }

  renderProgress() {
    const progress = ((100 / this.state.progressTickCount) * this.progressTickFinishedWorkers.size) || 1
    return (
      <Box marginBottom="30px">
        <Text as="p">Checking <BranchName>{this.state.branch}</BranchName></Text>
        <ProgressBar progress={progress} />
      </Box>
    )
  }

  renderNoMatchingProviders() {
    return (
      <Box className="lead">
        <Text as="p">It looks like {this.repository} does not use languages or frameworks we support.</Text>
      </Box>
    )
  }

  renderCloneDenied() {
    return (
      <Box className="lead">
        <Text as="p">We could not clone the repository. Check you've given enough permissions to our <a href="https://github.com/marketplace/django-doctor/" target="_blank" rel="noopener noreferrer">GitHub app</a>.</Text>
      </Box>
    ) 
  }

  renderRetrievingResults() {
    return (
      <Box style={{textAlign: 'center'}}>
        <Text as="p">Retrieving results</Text>
      </Box>
    )
  }

  renderRepository404() {
    return <NotFound />
  }

  render() {
    let results;
    let container;

    container = 'container-xl'
    if (this.state.isRepository404) {
      results = this.renderRepository404()
    } else if (this.state.step === PROGRESS) {
      results = this.renderResults()
    } else if (this.state.step === NO_RESULTS) {
      results = this.renderResults()
    } else if (this.state.step === RESULTS) {
      results = this.renderResults()
    } else if (this.state.step === RETRIEVING_RESULTS) {
      results = this.renderRetrievingResults()
    } else if (this.state.step === NO_MATCHED_PROVIDERS) {
      results = this.renderNoMatchingProviders()
    } else if (this.state.step === CLONE_DENIED) {
      results = this.renderCloneDenied()
    }

    // todo: handle rate limit
    const isRateLimited = false;

    return (
      <Box>
        <Seo />
        <HeaderLevelTwoRepository
          owner={this.owner}
          repository={this.repository}
          adviceCount={this.state.messageCentricMessages.length}
          isInstalled={this.state.isInstalled}
        />
        <Box className={container}>
          <div className="mb-6 pt-3 pl-0 pr-0 pl-md-4 pr-md-4">
            <BranchMenu
              show={this.state.branches.length > 1}
              branch={this.state.branch}
              branches={this.state.branches}
              handleBranchChange={this.handleBranchChange}
            />
            { isRateLimited && <RateLimited /> }
            { (this.state.step === PROGRESS || this.state.step === NO_RESULTS) && this.renderProgress() }
            {results}
          </div>
        </Box>
      </Box>
    );
  }
}


// wrapper around class component as cannot use hooks in class components
const FunctionalRepositoryWrapper = props => {
  const { state } = useStore()
  const { corpus } = useContext(CorpusContext)
  const [octokit, setOctokitAuth] = useOctokit()
  const productDetails = useProduct()
  return (
    <Repository
      {...props}
      octokit={octokit}
      username={getGitHubUsername(state)}
      accessToken={getGitHubAuthToken(state)}
      corpus={corpus}
      productDetails={productDetails}
    />
  )

}

export function getMessageCentricMessages(messages) {
  return messages.filter(item => item.message_type === 'message_centric')
}

export default FunctionalRepositoryWrapper;
