import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_HIGH as impact } from '../../constants'

export const exampleTitle = 'settings.py'

export const exampleBefore = (
`TEMPLATES = [
    {
        ...
        'DIRS': ['\\home\\app\\core\\html'],
    },
]`
)

export const exampleAfter = exampleBefore

export const lineNumber = 4  // which line to show the comment on

export const code = 'C4002'

export const title = 'Back slashes in TEMPLATES settings'

export const label = title

export const wordCode = 'template-dir-backslash'

export const furtherReading = [
  {
    href: 'https://docs.djangoproject.com/en/dev/ref/settings/#templates',
    text: 'Django TEMPLATES documentation',
  },
]

export function Summary(props) {
  return (
    <>
      <Text as={'p'}>DIRS must be forward slashes, even in Windows. Forward slashes do not need escaping, and they are cross Operating System compatible.</Text>
    </>
  )
}

export const explanation = (
  <>
    <Text as={'p'}>The TEMPLATE setting is a list of the template engines used when finding template files and rendering them.</Text>
    <Text as={'p'}>The DIRS key within the TEMPLATE list denotes the directories where the engine should look for template source files.</Text>
  </>
)


export {category, impact}