import React from 'react';

import {
  Dropdown,
  Breadcrumb,
  Box,
  StyledOcticon,
  Heading,
  TabNav,
  CounterLabel,
  Text,
} from '@primer/components'
import { Repo } from '@primer/octicons-react'

import { Link } from "react-router-dom";

export default function RepositoryHeader(props) {
  return (
    <Box style={{borderBottom: '1px solid #dedede'}}>
      <Box className="container-xl pt-3 d-flex mb-3 pl-2 pr-2 pl-md-4 pr-md-4" style={{alignItems: 'center'}}>
        <StyledOcticon
          icon={Repo}
          size={20}
          marginRight={'12px'}
        />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`/${props.owner}`}>
              <Heading fontSize={3} fontWeight="normal">{props.owner}</Heading>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item selected>
            <Link to={`/${props.owner}/${props.repository}`}>
              <Heading fontSize={3}>{props.repository}</Heading>
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Box>
    </Box>
  
  )
}
