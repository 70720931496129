
import React from 'react'

import { Text } from '@primer/components'
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js'

import Code from '../../components/Code'
import Snippet from '../../components/Snippet'

import { CATEGORY_INTRODUCE_BUGS as category, IMPACT_LOW as impact } from '../../constants'


export const exampleTitle = 'helpers.py'

export const exampleBefore = (
`if value == False:
    pass`
)

export const exampleAfter = (
`if value is False:
    pass`
)

export const code = 'CompareSingletonPrimitivesByIs'

export const ogImage = `/og-image/${code}.png`

export const title = 'Comparing singleton primitives with equality checking'

export const label = 'Singleton primitives equality checking'

export const wordCode = 'singleton-equality-checking'

export const furtherReading = [
  {
    href: 'https://docs.python.org/3/reference/datamodel.html#object.__eq__',
    text: 'Python documentation on equality checking',
  },
  {
    href: 'https://docs.python.org/3/reference/expressions.html#is)',
    text: 'Python documentation on identity checking',
  },
  {
    href: 'https://docs.python.org/3/library/functions.html#id',
    text: 'Python documentation on id() function'
  },
  {
    href: 'https://docs.python.org/3/library/stdtypes.html#truth-value-testing',
    text: 'Python documentation on truth value testing'
  }
]

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
        Singleton data types such as booleans should be compared using <Code>is</Code> and <Code>is not</Code> rather than <Code>==</Code> and <Code>!=</Code>.
    </Text>
  )
}

const exampleCode = `
value = 1
value == True  # evaluates to True
value is True  # evaluates to False
`


export const explanation = (
  <>
    <Text as={'p'}>You can introduce bugs in your code by doing equality checking on <Code>True</Code>, <Code>False</Code> and <Code>None</Code> thanks to Python's permissive "truthy" and "falsey" checking:</Text>
    <Snippet value={exampleCode} />
    <Text as={'p'}>This perhaps unexpected behaviour is caused by under the hood Python calling <Code>bool(value)</Code>, which evaluates to <Code>True</Code> for any integer other than <Code>0</Code> (even negative numbers).</Text>
    <Text as={'p'}>The <Code>is</Code> statement checks identity, while the <Code>==</Code> operator checks equality. Equality is for checking certain characteristics of the object have parity, while identity is for checking the objects are literally the same thing.</Text>
    <Text as={'p'}>Under the hood, an object's equality is determined using the object's <Code>__eq__()</Code> function. This function's purpose is to return <i>do certain characteristics of the objects have parity</i>.</Text>
    <Text as={'p'}>An object's identity is determined using the <Code>id()</Code> function. <Code>id()</Code> returns an integer that is unique to the object for the lifetime of the object. For CPython this is the memory address of the object. Two objects cannot share the same memory location at the same time, after all.</Text>
    <Text as={'p'}><Code>True</Code>, <Code>False</Code>, and <Code>None</Code> are singletons. Using the singleton design pattern ensures that only one object of its kind exists. This is why <Code>bool(1)</Code>, <Code>bool(2)</Code>, <Code>bool(True)</Code>, <Code>bool('foo')</Code> all return the same object. Singletons are therefore perfect for using identity checking, and doing equality checking is slightly less performant and risk introducing bugs when compared with identity checking.</Text>
  </>
)


export {category, impact}
