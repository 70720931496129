import React from 'react';

import { Box } from '@primer/components'

import Wave from './wave'


export default function WaveHero(props) {
  return (
    <Box className="pb-8" style={{background: 'linear-gradient(to right, #363795, #005C97)', position: 'relative'}}>
      {props.children}
      <Wave/>
    </Box>
  );
}
