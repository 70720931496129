import styled from 'styled-components'
import React, {InputHTMLAttributes, ReactElement, useLayoutEffect} from 'react'

const StyledCheckbox = styled.input`
  cursor: pointer;
  ${props => props.disabled && `cursor: not-allowed;`}
`

export function useProvidedRefOrCreate(providedRef) {
  const createdRef = React.useRef(null)
  return providedRef ? providedRef : createdRef
}


/**
 * An accessible, native checkbox component
 */
const Checkbox = React.forwardRef(
  (
    {checked, indeterminate, disabled, required, validationStatus, ...rest}: CheckboxProps,
    ref
  ): ReactElement => {
    const checkboxRef = useProvidedRefOrCreate(ref)

    useLayoutEffect(() => {
      if (checkboxRef.current) {
        checkboxRef.current.indeterminate = indeterminate || false
      }
    }, [indeterminate, checked, checkboxRef])

    return (
      <StyledCheckbox
        type="checkbox"
        disabled={disabled}
        aria-disabled={disabled ? 'true' : 'false'}
        ref={ref || checkboxRef}
        checked={indeterminate ? false : checked}
        aria-checked={indeterminate ? 'mixed' : checked ? 'true' : 'false'}
        required={required}
        aria-required={required ? 'true' : 'false'}
        aria-invalid={validationStatus === 'error' ? 'true' : 'false'}
        {...rest}
      />
    )
  }
)

Checkbox.displayName = 'Checkbox'

export default Checkbox