import React from 'react'

import { Text } from '@primer/components'

import { CATEGORY_MAINTAINABILITY as category } from '../../constants'
import { IMPACT_LOW as impact } from '../../constants'


export const title = 'Huge models.py'

export const label = title

export const code = "C3000"

export const wordCode = 'tall-models'

export function Summary(props) {
  return (
    <Text as={'p'} className={props.className}>
      Taller <code style={{fontSize: 'inherit'}}>models.py</code> with many Models may be more difficult to maintain than shorter <code style={{fontSize: 'inherit'}}>models.py</code> with fewer Models.
    </Text>
  )
}


export const explanation = (
  <>
    <Text as={'p'}>There is a limit to how many items a developer can consider when forming a mental model of source code.</Text>
    <Text as={'p'}>It may be time to split an application if models.py is very long.</Text>
  </>
)


export {category, impact}