import React, { useContext, useEffect } from 'react';

import { Box, Flex, StyledOcticon, LabelGroup, BorderBox, Timeline, Avatar, Label } from '@primer/components'
import { GitCommit, Eye, Info } from '@primer/octicons-react'
import ReactDiffViewer from 'react-diff-viewer';
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import { forceCheck } from 'react-lazyload';

import { useProduct } from '../../hooks/useProduct'
import { CorpusContext, useCategory, useImpact  } from '../../hooks/useCorpus'
import useFilters from '../../hooks/useFilters'
import * as constants from '../../constants'

import Advice from '../../components/Advice'
import { countMessageGroups } from '../../helpers'
import IssueCategoryAnalysis from './IssueCategoryAnalysis'
import IssueImpactAnalysis from './IssueImpactAnalysis'
import DjangoBugs from './DjangoBugs'
import DjangoVersionAnalysis from './DjangoVersionAnalysis'
import MarketplaceAdvert from './MarketplaceAdvert'
import PullRequestFile from './PullRequestFile'
import Filters from './Filters'

const IMPACTS = ['high', 'medium', 'low']
const CATEGORIES = ["security", "performance", "maintainability", "testing", "bugs"]
const ALL_CATEGORIES = CATEGORIES.concat(IMPACTS)


function intersection(setA, setB) {
    let _intersection = new Set()
    for (let elem of setB) {
        if (setA.has(elem)) {
            _intersection.add(elem)
        }
    }
    return _intersection
}


function getImplicitFilters(filters) {
  const severity = new Set()
  const category = new Set()

  for (let code of IMPACTS) {
    if (filters[code]) {
      useImpact(code).map(item => item.code).forEach(severity.add, severity);
    }
  }

  for (let code of CATEGORIES) {
    if (filters[code]) {
      useCategory(code).map(item => item.code).forEach(category.add, category);
    }
  }

  if (severity.size > 0 && category.size > 0) {
    return intersection(severity, category)
  } else if (severity.size > 0) {
    return severity
  } else if (category.size > 0) {
    return category
  }
  return new Set()
}


export default function Wrapper({messages, report, repoBlobLink, minimalistAdvice, showFilter, scmKey}) {
    const { corpus } = useContext(CorpusContext)
    const { filters, updateFilters } = useFilters()
    const implicitFilters = Object.keys(filters).length > 0 ? getImplicitFilters(filters) : new Set()

    const canonicalFilters = new Set()

    if (Object.values(filters).filter(item => item === true).length > 0) {
      for (let code of implicitFilters) {
        canonicalFilters.add(code)
      }
    }

    for (let [code, value] of Object.entries(filters)) {
      if (value === true) {
        canonicalFilters.add(code)
      }
    }

    const messageGroupCounts = countMessageGroups(
      canonicalFilters.size === 0 ? messages : messages.filter(message=> canonicalFilters.has(message['message-id'])),
      corpus
    )

    const unfilteredMessageGroupCounts = countMessageGroups(messages, corpus)
    return (
      <Box className="d-md-flex">
        <Box className={showFilter ? "col-md-9 mr-md-4 border-md rounded-2" : "border-md rounded-2"}>
          <PullRequestTimeline
            report={report}
            filters={canonicalFilters}
            repoBlobLink={repoBlobLink}
            minimalistAdvice={minimalistAdvice}
            scmKey={scmKey}
          />
          <MarketplaceAdvert />
        </Box>
        {
          showFilter && (
            <Box className="col-md-3 mb-2 hide-sm">
              <Filters
                {...messageGroupCounts}
                onChange={updateFilters}
                implicitFilters={implicitFilters}
                explicitFilters={filters}
                unfilteredMessageGroupCounts={unfilteredMessageGroupCounts}
              />
            </Box>
          )
        }
      </Box>
    )
}


const PullRequestTimeline = function(props) {
  const { corpus } = useContext(CorpusContext)
  const productDetails = useProduct()

  useEffect(forceCheck, [props.filters])

  return (
    <Box style={{background: "white", borderRadius: 6}} className="pr-md-4 pl-md-2 ml-md-0 mr-md-0 ml-md-2 mr-md-2">
      <Timeline>
        <Timeline.Item className={"pb-0"}>
          <Timeline.Badge>
            <StyledOcticon icon={Eye} />
          </Timeline.Badge>
          <Timeline.Body>
            <Avatar src="/favicon.png" size={32} className="mr-2 text-grey" />
            <strong className="pr-2"><a href={productDetails.gitHubMarketplaceLink} rel="noopener noreferrer" target="_blank" style={{color: "black"}}>code-review-doctor</a></strong>
            <Label variant="small" sx={{border: "1px solid grey", color: "grey", backgroundColor: "white"}}>bot</Label>
            <span className="pl-2">would suggest these changes <a href={productDetails.gitHubMarketplaceLink} rel="noopener noreferrer" target="_blank">right inside your pull requests</a> on GitHub.</span>
          </Timeline.Body>
        </Timeline.Item>
        {
          props.report.map(([path, messages], i) => {

            const filteredMessages = messages.filter(
              message => props.filters.size === 0 || message.message_ids.some(code => props.filters.has(code))
            )
            if (filteredMessages.length === 0) {
              return null
            }

            const child = (
              <Timeline.Item className={"pb-0"} key={`timeline-${path}`}>
                <Timeline.Body className="ml-4">
                  <Box style={{borderBottomRightRadius: 0, borderTopLeftRadius: 6}}>
                    <PullRequestFile
                      path={path}
                      messages={filteredMessages}
                      repoBlobLink={props.repoBlobLink}
                      minimalistAdvice={props.minimalistAdvice}
                      scmKey={props.scmKey}
                    />
                  </Box>
                </Timeline.Body>
              </Timeline.Item>
            )
            return i > 5 ? <LazyLoad height="545px">{child}</LazyLoad> : child
          })
        }
      </Timeline>
    </Box>
  )
}


Wrapper.defaultProps = {
  minimalistAdvice: true,
  showFilter: true,
}